const Annexures = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "Previous Year",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-1",
      cells: [
        {
          content: "Assessment Year",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-2",
      cells: [
        {
          content: "Annexure A: Proprietor`s Capital Account",
          colspan: 3,
          class: "bold bgyellow",
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
      ],
    },
    {
      id: "row-4",
      cells: [
        {
          content: "Opening Balance",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-5",
      cells: [
        {
          content: "Add: Contribution during the year",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-6",
      cells: [
        {
          content: "Add: Profit/loss during the year",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-7",
      cells: [
        {
          content: "Total",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-8",
      cells: [
        {
          content: "Less: Withdrawal during the year",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-9",
      cells: [
        {
          content: "TOTAL PROPRIETOR`S CAPITAL",
          class: "bold-underline bgyellow ",
        },
        {
          content: "",

          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-10",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-11",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-12",
      cells: [
        {
          content: "Annexure B: Reserves and Surplus",
          class: "bold bgyellow",
        },
        { content: ".", class: "bgyellow" },
        { content: ".", class: "bgyellow" },
      ],
    },
    {
      id: "row-13",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
      ],
    },
    {
      id: "row-14",
      cells: [
        {
          content: "Any reserve other than bal of P&L",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-15",
      cells: [
        {
          content: "Opening Balance",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-16",
      cells: [
        {
          content: "Add: Addition /(withdrawal)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-17",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-18",
      cells: [
        {
          content: "TOTAL RESERVE AND SURPLUS",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-19",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-20",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-21",
      cells: [
        {
          content: "Annexure C: LONG TERM BORROWINGS",

          class: "bold bgyellow",
        },
        { content: ".", isUserProvided: false, class: "bgyellow" },
        { content: ".", isUserProvided: false, class: "bgyellow" },
      ],
    },
    {
      id: "row-22",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
      ],
    },
    {
      id: "row-23",
      cells: [
        {
          content: "SECURED LOAN",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-24",
      cells: [
        {
          content: "Term loans",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-25",
      cells: [
        {
          content: "a) From Bank",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-26",
      cells: [
        {
          content: "b) Other Parties ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-27",
      cells: [
        {
          content: "Total Long Term Borrowings",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-28",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-29",
      cells: [
        {
          content: "LOAN REPYABLE ON DEMAND",
          class: "bold bgyellow",
        },
        { content: ".", isUserProvided: false, class: "bgyellow" },
        { content: ".", isUserProvided: false, class: "bgyellow" },
      ],
    },
    {
      id: "row-30",
      cells: [
        {
          content: "a) From Bank",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-31",
      cells: [
        {
          content: "b) Other Parties",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-32",
      cells: [
        {
          content: "Total  Loan repayable on Demand",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-33",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-34",
      cells: [
        {
          content: "UNSECURED  LOAN",
          class: "bold-underline bgyellow",
        },
        { content: ".", isUserProvided: false },
        { content: ".", isUserProvided: false },
      ],
    },
    {
      id: "row-35",
      cells: [
        {
          content: "Term loans",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-36",
      cells: [
        {
          content: "a) From Bank",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-37",
      cells: [
        {
          content: "b) Other Parties ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-38",
      cells: [
        {
          content: "Total Long Term Borrowings",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-39",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-40",
      cells: [
        {
          content: "LOAN REPYABLE ON DEMAND",
          class: "bold bgyellow",
        },
        { content: ".", isUserProvided: false, class: "bgyellow" },
        { content: ".", isUserProvided: false, class: "bgyellow" },
      ],
    },
    {
      id: "row-41",
      cells: [
        {
          content: "a) From Bank",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-42",
      cells: [
        {
          content: "b) Other Parties ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-43",
      cells: [
        {
          content: "Total  Loan repayable on Demand",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-44",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-45",
      cells: [
        {
          content: "TOTAL LONG TERM BORROWINGS",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-46",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-47",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-48",
      cells: [
        {
          content: "Annexure D: LONG TERM PROVSIONS",

          class: "bold-underline bgyellow",
        },
        { content: ".", class: "bgyellow" },
        { content: ".", class: "bgyellow" },
      ],
    },
    {
      id: "row-49",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
      ],
    },
    {
      id: "row-50",
      cells: [
        {
          content: "Long Term Provisions",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-51",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-52",
      cells: [
        {
          content: "TOTAL LONG TERM PROVISIONS",
          class: "bold-underline bgyellow",
        },
        { content: ".", class: "bgyellow" },
        { content: ".", class: "bgyellow" },
      ],
    },
    {
      id: "row-53",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-54",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-55",
      cells: [
        {
          content: "Annexure E: SHORT TERM BORROWINGS",

          class: "bold-underline bgyellow",
        },
        { content: ".", class: "bgyellow" },
        { content: ".", class: "bgyellow" },
      ],
    },
    {
      id: "row-56",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
      ],
    },
    {
      id: "row-57",
      cells: [
        {
          content: "Short Term Borrowings",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-58",
      cells: [
        {
          content: "a) From Bank",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-59",
      cells: [
        {
          content: "b) Other Parties ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-60",
      cells: [
        {
          content: "TOTAL SHORT TERM BORROWINGS",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-61",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-62",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-63",
      cells: [
        {
          content: "Annexure F: TRADE PAYABLES",

          class: "bold-underline bgyellow",
        },
        { content: ".", class: "bgyellow" },
        { content: ".", class: "bgyellow" },
      ],
    },
    {
      id: "row-64",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
      ],
    },

    {
      id: "row-65",
      cells: [
        {
          content: "TRADE PAYABLES/CREDITORS",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-66",
      cells: [
        {
          content: "a) MSME Creditors",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-67",
      cells: [
        {
          content: "Total MSME Trade Payables",
          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-68",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-69",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-70",
      cells: [
        {
          content: "Annexure G: TRADE PAYABLES",

          class: "bold-underline bgyellow",
        },
        { content: ".", class: "bgyellow" },
        { content: ".", class: "bgyellow" },
      ],
    },
    {
      id: "row-71",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
      ],
    },
    {
      id: "row-72",
      cells: [
        {
          content: "TRADE PAYABLES/CREDITORS",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-73",
      cells: [
        {
          content: "a) OTHER THAN MSME Creditors",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-74",
      cells: [
        {
          content: "TOTAL MSME PAYABLES",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-75",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-76",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-77",
      cells: [
        {
          content: "Annexure H: OTHER CURRENT LIABILITIES",

          class: "bold-underline bgyellow",
        },
        { content: ".", class: "bgyellow" },
        { content: ".", class: "bgyellow" },
      ],
    },
    {
      id: "row-78",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
      ],
    },
    {
      id: "row-79",
      cells: [
        {
          content: "OTHER CURRENT LIABILITIES",
          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-80",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-81",
      cells: [
        {
          content: "TOTAL OTHER CURRENT LIABILITIES",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-82",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-83",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-84",
      cells: [
        {
          content: "Annexure I: SHORT TERM PROVISIONS",

          class: "bold-underline bgyellow",
        },
        { content: ".", class: "bgyellow" },
        { content: ".", class: "bgyellow" },
      ],
    },
    {
      id: "row-85",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
      ],
    },
    {
      id: "row-86",
      cells: [
        {
          content: "Short Term Provisions",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-87",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-88",
      cells: [
        {
          content: "TOTAL SHORT TERM PROVISIONS",

          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
  ],
};
export default Annexures;
