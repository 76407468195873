import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import CreateTable from "./CreateTable";
import { HiPlusCircle } from "react-icons/hi";
import Header1 from "../components/Header1";
import nodata from "../assets/noData.png";

const TableList = () => {
  const [tables, setTables] = useState([]);
  const [showCreateTablePopup, setShowCreateTablePopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTables, setFilteredTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tablesPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");

  const navigate = useNavigate();

  const fetchTables = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get("https://vertical.lblfm.com/api/tabledata")
      .then((response) => {
        const groupedTables = groupTablesByBaseName(response.data);

        // Convert groupedTables object into an array for sorting
        const sortedTables = Object.values(groupedTables).sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setTables(sortedTables);
        setFilteredTables(sortedTables);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError("An error occurred. Please try again.");
        console.error(
          "API Error:",
          error.response ? error.response.data : error.message
        );
      });
  }, [navigate]);

  useEffect(() => {
    fetchTables();
  }, [fetchTables]);

  useEffect(() => {
    const sortedFilteredTables = [...tables]
      .filter(
        (table) =>
          table.base_table_name &&
          (table.base_table_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
            (table.created_at &&
              new Date(table.created_at)
                .toLocaleDateString()
                .includes(searchTerm)))
      )
      .sort((a, b) => {
        const fieldA = a[sortField]
          ? a[sortField].toString().toLowerCase()
          : "";
        const fieldB = b[sortField]
          ? b[sortField].toString().toLowerCase()
          : "";

        return sortOrder === "asc"
          ? fieldA.localeCompare(fieldB)
          : fieldB.localeCompare(fieldA);
      });

    setFilteredTables(sortedFilteredTables);
  }, [searchTerm, tables, sortOrder, sortField]);

  const groupTablesByBaseName = (tables) => {
    const groupedTables = {};

    tables.forEach((table) => {
      const { baseTableName, _id, name, created_at } = table;

      // Check if baseTableName and _id are properly defined
      if (!baseTableName || !_id) {
        console.error(
          `Undefined baseTableName or ID for table: ${name}`,
          table
        );
        return; // Skip the current table if these fields are not available
      }

      // Initialize an array for the baseTableName if not already done
      if (!groupedTables[baseTableName]) {
        groupedTables[baseTableName] = {
          base_table_name: baseTableName,
          created_at: created_at,
          tables: [],
        };
      }

      // Add the current table to the correct base group
      groupedTables[baseTableName].tables.push({
        id: _id,
        name: name,
        rows: table.rows, // Add other necessary properties as needed
      });
    });

    return groupedTables;
  };

  const toggleCreateTablePopup = () => {
    setShowCreateTablePopup(!showCreateTablePopup);
  };

  const handleDeleteTable = (baseTableName) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    axios
      .delete(`https://vertical.lblfm.com/api/tabledata/base/${baseTableName}`)
      .then(() => {
        fetchTables();
      })
      .catch((error) => {
        console.error(
          "Error deleting tables:",
          error.response ? error.response.data : error.message
        );
        setError(
          "An error occurred while deleting the tables. Please try again."
        );
      });
  };

  const handleTableCreated = () => {
    fetchTables();
    setShowCreateTablePopup(false);
  };

  const indexOfLastTable = currentPage * tablesPerPage;
  const indexOfFirstTable = indexOfLastTable - tablesPerPage;
  const currentTables = filteredTables.slice(
    indexOfFirstTable,
    indexOfLastTable
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage((prev) => prev + 1);
  const prevPage = () => setCurrentPage((prev) => prev - 1);
  const handleSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <div className="table-list-container">
      <Header1 />
      <div className="sc">
        <button
          className="create-table-button"
          onClick={toggleCreateTablePopup}
        >
          Create New Table <HiPlusCircle />
        </button>
        <div className="search-container">
          <label htmlFor="search" className="search-label">
            Search by Date or Name:
          </label>
          <input
            id="search"
            type="text"
            placeholder="Enter name or date..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
      </div>

      {loading ? (
        <div className="loading-container">
          <p>Please wait, loading...</p>
        </div>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : filteredTables.length === 0 ? (
        <div className="noData">
          <p>No Records Found. Please create a Table!</p>
          <img src={nodata} alt="No Data Available" />
        </div>
      ) : (
        <>
          <table className="tableList">
            <thead>
              <tr>
                <th>S.NO</th>
                <th onClick={() => handleSort("base_table_name")}>
                  Table Name{" "}
                  {sortField === "base_table_name"
                    ? sortOrder === "asc"
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th onClick={() => handleSort("created_at")}>
                  Date Created{" "}
                  {sortField === "created_at"
                    ? sortOrder === "asc"
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentTables.map((table, index) => (
                <tr key={table.base_table_name} className="table-item">
                  <td>{indexOfFirstTable + index + 1}.</td>
                  <td>{table.base_table_name}</td>
                  <td>{new Date(table.created_at).toLocaleString()}</td>
                  <td className="table-item-actions">
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteTable(table.base_table_name)}
                    >
                      Delete
                    </button>
                    {table.tables && table.tables.length > 0 ? (
                      <Link
                        to={`/view-table/${table.tables[0].id}`} // Use the first table's ID
                        className="view-button"
                      >
                        View Table
                      </Link>
                    ) : (
                      <span>No Tables Available</span> // Handle the case where there are no tables
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination">
            <button onClick={prevPage} disabled={currentPage === 1}>
              Prev
            </button>
            {Array.from(
              { length: Math.ceil(filteredTables.length / tablesPerPage) },
              (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              )
            )}
            <button
              onClick={nextPage}
              disabled={
                currentPage === Math.ceil(filteredTables.length / tablesPerPage)
              }
            >
              Next
            </button>
          </div>
        </>
      )}

      {showCreateTablePopup && (
        <CreateTable
          onClose={toggleCreateTablePopup}
          handleTableCreated={handleTableCreated}
        />
      )}
    </div>
  );
};

export default TableList;
