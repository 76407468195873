const NoteBSData7 = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "M/S:-",
          colspan: 1,
          class: "no-border-r free",
        },
        {
          content: "",
          colspan: 5,
          isUserProvided: false,
          class: "no-border-r free",
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        {
          content:
            "Notes forming part of the Financial Statements  for the year ended, 31st March, 2024",
          colspan: 6,
          class: "no-border-r free bgblue center",
        },
      ],
    },
    {
      id: "row-2",
      cells: [
        {
          content: ".",
          colspan: 5,
          class: "no-border-r free hideen-border center trans-text ",
        },
        {
          content: "(Amount in Rs.)",
          colspan: 1,
          class: "hideen-border",
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        { content: "7", class: "bold topBd" },
        {
          content: "Other long-term liabilities",
          colspan: 3,
          class: "bold topBd",
        },
        { content: "", isUserProvided: false, class: "topButtonBorder" },
        { content: "", isUserProvided: false, class: "topButtonBorder" },
      ],
    },
    {
      id: "row-4",
      cells: [
        { content: "." },
        { content: "Advance from customers", colspan: 3 },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-5",
      cells: [
        { content: "." },
        { content: "Others (please specify)", colspan: 3 },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-6",
      cells: [
        { content: "." },
        {
          content: "Total Other long-term liabilities",
          colspan: 3,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "topButtonBorder" },
        { content: "", isUserProvided: false, class: "topButtonBorder" },
      ],
    },
    {
      id: "row-7",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
      ],
    },

    // new

    {
      id: "row-8",
      cells: [
        { content: "8", class: "bold" },
        { content: "Provisions", class: "bold" },
        {
          content: "Long term",
          colspan: 2,
          class: "bold bgblue center topBd ",
        },
        {
          content: "Short term",
          colspan: 2,
          isUserProvided: false,
          class: "bold bgblue center topBd ",
        },
      ],
    },

    // new
    {
      id: "row-9",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: ".", class: "bold trans-text" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
      ],
    },
    {
      id: "row-10",
      cells: [
        { content: "(a)", class: "bold" },
        { content: "Provision for employee benefits", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-11",
      cells: [
        { content: "." },
        { content: "Provision for gratuity" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-12",
      cells: [
        { content: "." },
        { content: "Provision for leave Encashment" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-13",
      cells: [
        { content: "." },
        { content: "." },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-14",
      cells: [
        { content: "(b)", class: "bold" },
        { content: "Other provisions", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-15",
      cells: [
        { content: "." },
        { content: "Provision for Income tax " },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-16",
      cells: [
        { content: "." },
        {
          content:
            "Other Provisions (Please Specify - eg/- Provision for warranties / Provision for Sales Return)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-17",
      cells: [
        { content: "." },
        { content: "Other (specify nature)" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-18",
      cells: [
        { content: ".", class: "trans-text" },
        { content: "Total Provisions", class: "bold" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
      ],
    },
    {
      id: "row-19",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
      ],
    },

    //

    {
      id: "row-20",
      cells: [
        { content: "9", class: "bold" },
        { content: "Trade payables", class: "bold", colspan: 3 },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
      ],
    },
    {
      id: "row-21",
      cells: [
        { content: "(a)", class: "bold" },
        {
          content:
            "Total outstanding dues of micro, small and medium enterprises",
          colspan: 3,
          isUserProvided: false,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-22",
      cells: [
        { content: "(b)", class: "bold" },
        {
          content:
            "Total outstanding dues of creditors other than micro, small and medium enterprises",
          colspan: 3,
          isUserProvided: false,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    // second stage stat hear

    {
      id: "row-23",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: "Total Trade payables",
          colspan: 3,
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
      ],
    },
    {
      id: "row-24",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content:
            "Disclosure relating to suppliers registered under MSMED Act based on the information available with the entity Company:",
          colspan: 5,
          isUserProvided: false,
          class: "bold",
        },
      ],
    },
    {
      id: "row-25",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: ".",
          colspan: 5,
          isUserProvided: false,
          class: "bold trans-text",
        },
      ],
    },
    {
      id: "row-26",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: ".",
          colspan: 5,
          isUserProvided: false,
          class: "bold trans-text",
        },
      ],
    },
    // remaning
    {
      id: "row-27",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: "Particulars",
          colspan: 3,
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
      ],
    },
    {
      id: "row-28",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content:
            "(a) Amount remaining unpaid to any supplier at the end of each accounting year:",
          colspan: 3,
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-29",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: "Principal",
          colspan: 3,
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-30",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: "Interest",
          colspan: 3,
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-31",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: "Total",
          colspan: 3,
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-32",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content:
            "(b) The amount of interest paid by the buyer in terms of section 16 of the MSMED Act, along with the amount of the payment made to the supplier beyond the appointed day during each accounting year.",
          colspan: 3,
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-33",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content:
            "(c) The amount of interest due and payable for the period of delay in making payment (which have been paid but beyond the appointed day during the year) but without adding the interest specified under the MSMED Act.",
          colspan: 3,
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-34",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content:
            "(d) The amount of interest accrued and remaining unpaid at the end of each accounting year.",
          colspan: 3,
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-35",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content:
            "(e) The amount of further interest remaining due and payable even in the succeeding years, until such date when the interest dues above are actually paid to the small enterprise, for the purpose of disallowance of a deductible expenditure under section 23 of the MSMED Act.",
          colspan: 3,
          isUserProvided: false,
          class: "btbd ",
        },
        { content: "", isUserProvided: false, class: "btbd " },
        { content: "", isUserProvided: false, class: "btbd " },
      ],
    },
    {
      id: "row-36",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: ".",
          colspan: 5,
          isUserProvided: false,
          class: "trans-text ",
        },
      ],
    },
    {
      id: "row-37",
      cells: [
        { content: "10", class: "" },
        {
          content: "Other current liabilities",
          colspan: 3,
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
      ],
    },
    {
      id: "row-38",
      cells: [
        { content: "(a)", class: "" },
        {
          content: "Audit fees payable",
          colspan: 3,
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-39",
      cells: [
        { content: "(b)", class: "" },
        {
          content: "ADVERTISEMENT EXP. PAYABLE",
          colspan: 3,
          isUserProvided: false,
          class: "",
        },
        { content: "", isUserProvided: false, class: "" },
        { content: "", isUserProvided: false, class: "" },
      ],
    },
    {
      id: "row-40",
      cells: [
        { content: "(c)", class: "" },
        {
          content: "FREIGHT PAYABLE",
          colspan: 3,
          isUserProvided: false,
          class: "",
        },
        { content: "", isUserProvided: false, class: "" },
        { content: "", isUserProvided: false, class: "" },
      ],
    },
    {
      id: "row-41",
      cells: [
        { content: "(d)", class: "" },
        {
          content: "SALARY PAYABLE",
          colspan: 3,
          isUserProvided: false,
          class: "",
        },
        { content: "", isUserProvided: false, class: "" },
        { content: "", isUserProvided: false, class: "" },
      ],
    },
    {
      id: "row-42",
      cells: [
        { content: "(e)", class: "" },
        {
          content: "WAGES PAYABLE",
          colspan: 3,
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        // { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-43",
      cells: [
        { content: "(f)", class: "" },
        {
          content: "Advance from Parties",
          colspan: 3,
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-44",
      cells: [
        { content: ".", class: "trans-text" },
        {
          content: "Total Other current liabilities",
          colspan: 3,
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
      ],
    },
  ],
};
export default NoteBSData7;
