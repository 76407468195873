const hbsData = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "NAME OF THE ASSESSEE",
          class: "no-border-r free",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        { content: "PAN", class: "no-border-r free" },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
      ],
    },
    {
      id: "row-2",
      cells: [
        { content: "REGD ADDRESS", class: "no-border-r free" },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        {
          content: "BALANCE SHEET",
          colspan: 2,
          class: "bold newHead",
        },
        {
          content: "Amount in (Rs)",
          colspan: 3,
          class: "bold",
        },
      ],
    },
    {
      id: "row-4",
      cells: [
        { content: "S. NO", class: "bold" },
        { content: "Particulars", class: "bold" },
        { content: "Note", class: "bold" },
        { content: "As on 31/03/2023", class: "bold" },
        { content: "For As on 31/03/2022", class: "bold" },
      ],
    },
    {
      id: "row-5",
      cells: [
        { content: "I", class: "bold" },
        { content: "EQUITY AND LIABILITIES", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-6",
      cells: [
        { content: "1", class: "bold" },
        { content: "Owners' Funds", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-7",
      cells: [
        { content: "(a)" },
        { content: "Owners' Capital Account" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-8",
      cells: [
        { content: "(b)" },
        { content: "Reserves and surplus" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-9",
      cells: [
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-10",
      cells: [
        { content: "2", class: "bold" },
        { content: "Non-current liabilities", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-11",
      cells: [
        { content: "(a)" },
        { content: "Long-term borrowings" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-12",
      cells: [
        { content: "(b)" },
        { content: "Deferred tax liabilities (Net)" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-13",
      cells: [
        { content: "(c)" },
        { content: "Other long-term liabilities " },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-14",
      cells: [
        { content: "(d)" },
        { content: "Long-term provisions" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-15",
      cells: [
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-16",
      cells: [
        { content: "3", class: "bold" },
        { content: "Current liabilities", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-17",
      cells: [
        { content: "(a)" },
        { content: "Short-term borrowings" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-18",
      cells: [
        { content: "(b)" },
        { content: "Trade payables" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-19",
      cells: [
        { content: "(i)" },
        {
          content:
            "Total outstanding dues of micro, small and medium enterprises",
        },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-20",
      cells: [
        { content: "(ii)" },
        {
          content:
            "Total outstanding dues of creditors other than micro, small and medium enterprises",
        },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-21",
      cells: [
        { content: "(c)" },
        { content: "Other current liabilities" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-22",
      cells: [
        { content: "(d)" },
        { content: "Short-term provisions" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-23",
      cells: [
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-24",
      cells: [
        { content: "", isUserProvided: false },
        { content: "Total", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-25",
      cells: [
        { content: "II", class: "bold" },
        { content: "ASSETS", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-26",
      cells: [
        { content: "1", class: "bold" },
        { content: "Non-current assets", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-27",
      cells: [
        { content: "(a)" },
        { content: "Property, Plant and Equipment and Intangible assets" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-28",
      cells: [
        { content: "(i)" },
        { content: "Property, Plant and Equipment" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-29",
      cells: [
        { content: "(ii)" },
        { content: "Intangible assets" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-30",
      cells: [
        { content: "(iii)" },
        { content: "Capital work in progress" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-31",
      cells: [
        { content: "(iv)" },
        { content: "Intangible asset under development" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-32",
      cells: [
        { content: "(b)" },
        { content: "Non-current investments" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-33",
      cells: [
        { content: "(c)" },
        { content: "Deferred tax assets (Net)" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-34",
      cells: [
        { content: "(d)" },
        { content: "Long Term Loans and Advances" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-35",
      cells: [
        { content: "(e)" },
        { content: "Other non-current assets" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-36",
      cells: [
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-37",
      cells: [
        { content: "2", class: "bold" },
        { content: "Current assets", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-38",
      cells: [
        { content: "(a)" },
        { content: "Current investments" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-39",
      cells: [
        { content: "(b)" },
        { content: "Inventories" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-40",
      cells: [
        { content: "(c)" },
        { content: "Trade receivables" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-41",
      cells: [
        { content: "(d)" },
        { content: "Cash and bank balances" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-42",
      cells: [
        { content: "(e)" },
        { content: "Short Term Loans and Advances " },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-43",
      cells: [
        { content: "(f)" },
        { content: "Other current assets" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-44",
      cells: [
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-45",
      cells: [
        { content: "", isUserProvided: false },
        { content: "Total", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-46",
      cells: [
        { content: "", isUserProvided: false },
        { content: "Summary of significant accounting policies" },

        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
      ],
    },
    {
      id: "row-47",
      cells: [
        {
          content:
            "The report has been made on the basis of information provided to us. ",
          colspan: 2,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "FOR", class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-48",
      cells: [
        {
          content: "FOR:",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-49",
      cells: [
        {
          content: "CHARTERED ACCOUNTANTS:",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-50",
      cells: [
        {
          content: "CA",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-51",
      cells: [
        { content: "Partner", colspan: 1, class: "no-border-r free" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "Partner", class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-52",
      cells: [
        {
          content: "M.NO:",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-53",
      cells: [
        {
          content: "UDIN:",
          colspan: 1,
          class: "no-border-r free",
          includeInput: true,
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-54",
      cells: [
        {
          content: "Date:",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "Partner", class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
  ],
};
export default hbsData;
