const multiCalculationTable = {
  calculations: [
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [1],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [1],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [1],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [2],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [2],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [3],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [3],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [3],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [4],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [4],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [4],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [5],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [5],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [5],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [6],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [6],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [6],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [7],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [7],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [7],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [8],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [8],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [8],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [9],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [9],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [9],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-24", "row-25"],
            sourceCols: [10],
          },
          {
            operation: "subtract",
            sourceRows: ["row-26"],
            sourceCols: [10],
          },
        ],
        targetRows: ["row-27"],
        targetCols: [10],
      },
    },
    //   for the current year stated
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [1],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [1],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [1],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [2],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [2],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [3],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [3],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [3],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [4],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [4],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [4],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [5],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [5],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [5],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [6],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [6],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [6],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [7],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [7],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [7],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [8],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [8],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [8],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [9],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [9],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [9],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-27", "row-28"],
            sourceCols: [10],
          },
          {
            operation: "subtract",
            sourceRows: ["row-29"],
            sourceCols: [10],
          },
        ],
        targetRows: ["row-30"],
        targetCols: [10],
      },
    },
    // ------------------------------------------------------------------------------------------------
    // Closing CWIP
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [1],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [1],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [1],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [2],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [2],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [3],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [3],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [3],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [4],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [4],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [4],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [5],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [5],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [5],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [6],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [6],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [6],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [7],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [7],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [7],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [8],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [8],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [8],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [9],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [9],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [9],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-40", "row-41"],
            sourceCols: [10],
          },
          {
            operation: "subtract",
            sourceRows: ["row-42"],
            sourceCols: [10],
          },
        ],
        targetRows: ["row-43"],
        targetCols: [10],
      },
    },
    //Annexure M: Non- Current Investments
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-74", "row-76", "row-78"],
            sourceCols: [1],
          },
          {
            operation: "subtract",
            sourceRows: ["row-79"],
            sourceCols: [1],
          },
        ],
        targetRows: ["row-80"],
        targetCols: [1],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-74", "row-76", "row-78"],
            sourceCols: [2],
          },
          {
            operation: "subtract",
            sourceRows: ["row-79"],
            sourceCols: [2],
          },
        ],
        targetRows: ["row-80"],
        targetCols: [2],
      },
    },
    // ------------------------------------------------------------------------------------------------
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-95", "row-97"],
            sourceCols: [1],
          },
          {
            operation: "subtract",
            sourceRows: ["row-98"],
            sourceCols: [1],
          },
        ],
        targetRows: ["row-99"],
        targetCols: [1],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-95", "row-97"],
            sourceCols: [2],
          },
          {
            operation: "subtract",
            sourceRows: ["row-98"],
            sourceCols: [2],
          },
        ],
        targetRows: ["row-99"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-134", "row-135", "row-136"],
            sourceCols: [1],
          },
          {
            operation: "subtract",
            sourceRows: ["row-137"],
            sourceCols: [1],
          },
        ],
        targetRows: ["row-138"],
        targetCols: [1],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-134", "row-135", "row-136"],
            sourceCols: [2],
          },
          {
            operation: "subtract",
            sourceRows: ["row-137"],
            sourceCols: [2],
          },
        ],
        targetRows: ["row-138"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-210", "row-211", "row-212"],
            sourceCols: [1],
          },
          {
            operation: "subtract",
            sourceRows: ["row-213"],
            sourceCols: [1],
          },
        ],
        targetRows: ["row-214"],
        targetCols: [1],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-210", "row-211", "row-212"],
            sourceCols: [2],
          },
          {
            operation: "subtract",
            sourceRows: ["row-213"],
            sourceCols: [2],
          },
        ],
        targetRows: ["row-214"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-220", "row-221", "row-222"],
            sourceCols: [1],
          },
          {
            operation: "subtract",
            sourceRows: ["row-223"],
            sourceCols: [1],
          },
        ],
        targetRows: ["row-224"],
        targetCols: [1],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        steps: [
          {
            operation: "sum",
            sourceRows: ["row-220", "row-221", "row-222"],
            sourceCols: [2],
          },
          {
            operation: "subtract",
            sourceRows: ["row-223"],
            sourceCols: [2],
          },
        ],
        targetRows: ["row-224"],
        targetCols: [2],
      },
    },

    // profitlos

    // {
    //   source: "vertical-profit-loss",
    //   mapping: {
    //     steps: [
    //       {
    //         operation: "subtract",
    //         sourceRows: ["row-31"],
    //         sourceCols: [2],
    //       },
    //       {
    //         operation: "sum",
    //         sourceRows: [
    //           "row-8",
    //           "row-9",
    //           "row-10",
    //           "row-11",
    //           "row-12",
    //           "row-13",
    //           "row-14",
    //           "row-15",
    //           "row-16",
    //           "row-17",
    //           "row-18",
    //           "row-19",
    //           "row-20",
    //           "row-21",
    //           "row-22",
    //           "row-23",
    //           "row-24",
    //           "row-25",
    //           "row-26",
    //           "row-27",
    //         ],
    //         sourceCols: [2],
    //       },
    //     ],
    //     targetRows: ["row-29"],
    //     targetCols: [2],
    //   },
    // },
    // {
    //   source: "vertical-profit-loss",
    //   mapping: {
    //     steps: [
    //       {
    //         operation: "sum",
    //         sourceRows: [
    //           "row-8",
    //           "row-9",
    //           "row-10",
    //           "row-11",
    //           "row-12",
    //           "row-13",
    //           "row-14",
    //           "row-15",
    //           "row-16",
    //           "row-17",
    //           "row-18",
    //           "row-19",
    //           "row-20",
    //           "row-21",
    //           "row-22",
    //           "row-23",
    //           "row-24",
    //           "row-25",
    //           "row-26",
    //           "row-27",
    //         ],
    //         sourceCols: [4],
    //       },
    //       {
    //         operation: "subtract",
    //         sourceRows: ["row-31"],
    //         sourceCols: [4],
    //       },
    //     ],
    //     targetRows: ["row-29"],
    //     targetCols: [4],
    //   },
    // },

    // second one

    // {
    //   source: "vertical-profit-loss",
    //   mapping: {
    //     steps: [
    //       {
    //         operation: "sum",
    //         sourceRows: ["row-65", "row-49", "row-45", "row-38"],
    //         sourceCols: [2],
    //       },
    //        {
    //         operation: "subtract",
    //         sourceRows: ["row-71"],
    //         sourceCols: [2],
    //       },
    //     ],

    //     targetRows: ["row-69"],
    //     targetCols: [2],
    //   },
    // },
  ],
};

export default multiCalculationTable;
