const bsData = {
  rows: [
    {
      id: "row-0",
      cells: [
        { content: "NAME OF THE ASSESSEE", class: "bold " },
        {
          content: "",
          colspan: 5,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        { content: "PAN", class: "bold" },
        {
          content: "",
          colspan: 5,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-2",
      cells: [
        { content: "ADDRESS", class: "bold" },
        {
          content: "",
          colspan: 5,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        { content: "Previous Year", class: "bold" },
        {
          content: "",
          colspan: 5,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-4",
      cells: [
        { content: "Assessment Year", class: "bold" },
        {
          content: "",
          colspan: 5,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-5",
      cells: [
        {
          content: "BALANCE SHEET",
          colspan: 6,
          class: "bold centerYelow",
        },
      ],
    },
    {
      id: "row-6",
      cells: [
        { content: "EQUITY AND LIABILITIES", class: "bold" },
        { content: "For the current year ended", class: "bold" },
        { content: "For Preceeding year ended", class: "bold" },
        { content: "ASSETS", class: "bold" },
        { content: "For the current year ended ", class: "bold" },
        { content: "For the preceding year ended ", class: "bold" },
      ],
    },
    {
      id: "row-7",
      cells: [
        {
          content: "1 OWNERS` FUND",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "1. NON CURRENT ASSETS", class: "bold-underline" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-8",
      cells: [
        {
          content: "(a)Owner`s Capital Account",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(a) PPE & Intangible Assets" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-9",
      cells: [
        {
          content: "(As per Annexure A)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(As per Annexure J & K)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-10",
      cells: [
        {
          content: "(b) Reserve and Surplus",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-11",
      cells: [
        {
          content: "(As per Annexure B)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "i. Property Plant and Equipments" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-12",
      cells: [
        {
          content: "2. NON CURRENT LIABILIITES",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "ii. Intangible Assets" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-13",
      cells: [
        {
          content: "(a)Long Term Borrowings",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "iii. Capital Work in progress" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-14",
      cells: [
        {
          content: "(As per Annexure C)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "A. On Tangible assets", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-15",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "B. On Intangible assets", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-16",
      cells: [
        {
          content: "(b)Deferred Tax Liabilities (Net)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "iv. Intangible assets under development" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-17",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "(As per Annexure L)",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-18",
      cells: [
        {
          content: "Other Long Term Liabilities",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(b) Non Current Investments" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-19",
      cells: [
        {
          content: "(a)Long Term Provisions",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "(As per Annexure M)",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-20",
      cells: [
        {
          content: "(As per Annexure D)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(c ) Deferred Tax Assets (net )" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-21",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-22",
      cells: [
        {
          content: "3. CURRENT LIABILITIES AND PROVISIONS",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-23",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(d) Long Term Loans and Advances" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-24",
      cells: [
        {
          content: "(a)Short Term Borrowings",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "(As per Annexure N)",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-25",
      cells: [
        {
          content: "(As per Annexure E)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-26",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-27",
      cells: [
        {
          content: "(b)Trade Payables",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(e ) Other non-current assets" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-28",
      cells: [
        {
          content: "i. Total outstanding dues of MSME",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "(As per Annexure O)",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-29",
      cells: [
        {
          content: "(As per Annexure F)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-30",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-31",
      cells: [
        {
          content: "II. Total outstanding dues other then MSME",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "2. CURRENT ASSETS", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-32",
      cells: [
        {
          content: "(As per Annexure G)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(a) Current Investments " },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-33",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "(As per Annexure P)",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-34",
      cells: [
        {
          content: "(c ) Other Current Liabilities",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(b) Inventories" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-43",
      cells: [
        {
          content: "",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(As per Annexure Q)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-35",
      cells: [
        {
          content: "(As per Annexure H)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(c ) Trade receivables" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-36",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "(As per Annexure R)",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-37",
      cells: [
        {
          content: "(d)Short Term Provisions",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "( d)Short Term Loans and Advances " },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-44",
      cells: [
        {
          content: "",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(As per Annexure S)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-38",
      cells: [
        {
          content: "(As per Annexure I)",
          className: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(e ) Cash and Bank Balances" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-45",
      cells: [
        {
          content: "",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(As per Annexure T)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-39",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(f) Other Current Assets" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-46",
      cells: [
        {
          content: "",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "(As per Annexure U)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-40",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-41",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
  ],
};
export default bsData;
