const bs11 = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "M/S:-",
          colspan: 1,
          class: "hideen-border free",
        },
        {
          content: "",
          colspan: 10,
          isUserProvided: false,
          class: "hideen-border free",
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        {
          content:
            "Notes forming part of the Financial Statements for the year ended 31st March, 2024",
          colspan: 11,
          class: "hideen-border free bgblue ",
        },
      ],
    },
    {
      id: "row-2",
      cells: [
        {
          content: ".",
          colspan: 11,
          class: "hideen-border free trans-text ",
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        {
          content:
            "11- Property, Plant and Equipment and Intangible Assets (owned assets)",
          colspan: 10,
          class: "hideen-border free",
        },
        {
          content: "(Amount in Rs.)",
          isUserProvided: false,
          class: "no-border hideen-border",
        },
      ],
    },
    {
      id: "row-4",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "TANGIBLE ASSETS",
          colspan: 10,
          class: "center",
        },
      ],
    },
    {
      id: "row-5",
      cells: [
        { content: "Particulars /Assets", class: "bold" },
        { content: "Shed", class: "bold" },
        { content: "Buildings", class: "bold" },
        { content: "Plant and Equipment", class: "bold" },
        { content: "Office equipment", class: "bold" },
        { content: "Furniture & Fixtures", class: "bold" },
        { content: "Vehicles", class: "bold" },
        { content: "Mobile", class: "bold" },
        { content: "Printer", class: "bold" },
        { content: "Computer", class: "bold" },
        { content: "Total", class: "bold" },
      ],
    },
    {
      id: "row-6",
      cells: [
        {
          content: ".",
          colspan: 11,
          class: "trans-text ",
        },
      ],
    },
    {
      id: "row-7",
      cells: [
        {
          content: "Gross Block",
          colspan: 11,
          class: "bold",
        },
      ],
    },

    {
      id: "row-8",
      cells: [
        { content: "Rate of Depreciation", class: "bold" },
        { content: "10%", class: "bold" },
        { content: "10%", class: "bold" },
        { content: "15%", class: "bold" },
        { content: "15%", class: "bold" },
        { content: "10%", class: "bold" },
        { content: "15%", class: "bold" },
        { content: "15%", class: "bold" },
        { content: "15%", class: "bold" },
        { content: "40%", class: "bold" },
        { content: ".", class: "trans-text " },
      ],
    },
    // new
    {
      id: "row-9",
      cells: [
        { content: "At 1 April 2023" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    // next
    {
      id: "row-10",
      cells: [
        { content: "Additions" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-11",
      cells: [
        { content: "Deductions/Adjustments" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-12",
      cells: [
        { content: "Total as at 31/03/2024" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-13",
      cells: [
        { content: "At 1 April 2022" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-14",
      cells: [
        { content: "Additions" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-15",
      cells: [
        { content: "Deductions/Adjustments" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-16",
      cells: [
        { content: "At 31 March 2023", class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-17",
      cells: [
        {
          content: "Depreciation/Adjustments",
          colspan: 11,
          class: "bold",
        },
      ],
    },
    {
      id: "row-18",
      cells: [
        { content: "At 1 April 2023" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-19",
      cells: [
        { content: "Additions" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-20",
      cells: [
        { content: "Deductions/Adjustments" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-21",
      cells: [
        { content: "At 1 April 2022" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-22",
      cells: [
        { content: "Additions" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-23",
      cells: [
        { content: "Deductions/Adjustments" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-24",
      cells: [
        { content: "At 31 March 2024", class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-25",
      cells: [
        { content: "At 31 March 2023", class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-26",
      cells: [
        {
          content: "Net Block",
          colspan: 11,
          class: "bold",
        },
      ],
    },
    {
      id: "row-27",
      cells: [
        { content: "At 31 March 2024", class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-28",
      cells: [
        { content: "At 31 March 2023", class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    // end of the new
    {
      id: "row-29",
      cells: [
        {
          content: ".",
          colspan: 11,
          class: "trans-text ",
        },
      ],
    },
    {
      id: "row-30",
      cells: [
        {
          content: "TANGIBLE ASSETS",
          colspan: 11,
          class: " hideen-border bold free",
        },
      ],
    },
    {
      id: "row-31",
      cells: [
        {
          content: ".",
          colspan: 11,
          class: "hideen-border trans-text ",
        },
      ],
    },
    {
      id: "row-32",
      cells: [
        {
          content: "Capital Work in Progress",
          class: "bold hideen-border free",
        },
        { content: ".", class: "hideen-border trans-text " },
        { content: "31-Mar-24", class: "bold hideen-border trans-text " },
        { content: "31-Mar-23", class: "bold hideen-border trans-text " },
        { content: ".", class: "hideen-border trans-text " },
        { content: ".", class: "hideen-border trans-text " },
        { content: ".", class: "hideen-border trans-text " },
        {
          content: "Intangible assets under development",
          colspan: 2,
          class: "bold hideen-border free",
        },
        { content: "31-Mar-24", class: "bold hideen-border free" },
        { content: "31-Mar-23", class: "bold hideen-border free" },
      ],
    },
    {
      id: "row-33",
      cells: [
        {
          content: ".",
          colspan: 11,
          class: "hideen-border trans-text",
        },
      ],
    },
    {
      id: "row-34",
      cells: [
        { content: "Opening Balance", class: "bold hideen-border free" },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text ",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold hideen-border free",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold hideen-border free",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text",
        },
        {
          content: "Opening Balance",
          colspan: 2,
          class: " hideen-border free",
        },
        { content: "", isUserProvided: false, class: "hideen-border free" },
        { content: "", isUserProvided: false, class: " hideen-border free" },
      ],
    },
    {
      id: "row-35",
      cells: [
        {
          content: "Add: Additions during the year",
          class: "hideen-border free",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text",
        },
        { content: "", isUserProvided: false, class: " hideen-border free" },
        { content: "", isUserProvided: false, class: " hideen-border free" },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text ",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text ",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text ",
        },
        {
          content: "Add: Additions during the year",
          colspan: 2,
          class: "hideen-border free",
        },
        { content: "", isUserProvided: false, class: "hideen-border free" },
        { content: "", isUserProvided: false, class: "hideen-border free" },
      ],
    },
    {
      id: "row-36",
      cells: [
        {
          content: "Less: Capitalized during the year",
          class: "hideen-border free",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text ",
        },
        { content: "", isUserProvided: false, class: "hideen-border free" },
        { content: "", isUserProvided: false, class: "hideen-border free" },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text ",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text ",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "hideen-border trans-text ",
        },
        {
          content: "Less: Capitalized during the year",
          colspan: 2,
          class: "hideen-border free",
        },
        { content: "", isUserProvided: false, class: "hideen-border free" },
        { content: "", isUserProvided: false, class: "hideen-border free" },
      ],
    },
    {
      id: "row-37",
      cells: [
        { content: "Closing Balance (B)", class: " hideen-border free" },
        { content: ".", class: "hideen-border trans-text " },
        { content: "", isUserProvided: false, class: "hideen-border free" },
        { content: "", isUserProvided: false, class: "hideen-border free" },
        { content: ".", class: "hideen-border trans-text " },
        { content: ".", class: "hideen-border trans-text " },
        { content: ".", class: "hideen-border trans-text " },
        {
          content: "Closing Balance (B)",
          colspan: 2,
          class: "hideen-border free",
        },
        { content: "", isUserProvided: false, class: "hideen-border free" },
        { content: "", isUserProvided: false, class: "hideen-border free" },
      ],
    },
    {
      id: "row-38",
      cells: [
        {
          content: ".",
          colspan: 11,
          class: "hideen-border free trans-text ",
        },
      ],
    },
    {
      id: "row-39",
      cells: [
        {
          content: ".",
          colspan: 11,
          class: "hideen-border free trans-text ",
        },
      ],
    },
  ],
};
export default bs11;
