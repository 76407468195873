// multiTableCopy configuration for mapping
const multiTableCopy = {
  targets: [
    {
      source: "vertical-profit-loss",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-0", "row-1", "row-2", "row-3", "row-4"],
          sourceCols: [1],
          targetRows: ["row-0", "row-1", "row-2", "row-3", "row-4"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-profit-loss",
      mappings: [
        {
          sourceRows: ["row-0", "row-2"],
          sourceCols: [1],
          targetRows: ["row-0", "row-1"],
          targetCols: [1],
        },
        //Revenue from operations
        {
          sourceRows: ["row-15"],
          sourceCols: [7],
          targetRows: ["row-6"],
          targetCols: [3],
        },
        {
          sourceRows: ["row-15"],
          sourceCols: [9],
          targetRows: ["row-6"],
          targetCols: [4],
        },
        //Other Income
        {
          sourceRows: ["row-41"],
          sourceCols: [6],
          targetRows: ["row-7"],
          targetCols: [3],
        },
        {
          sourceRows: ["row-41"],
          sourceCols: [8],
          targetRows: ["row-7"],
          targetCols: [4],
        },
        // cost of the goods
        {
          sourceRows: ["row-16"],
          sourceCols: [2],
          targetRows: ["row-10"],
          targetCols: [3],
        },
        {
          sourceRows: ["row-16"],
          sourceCols: [4],
          targetRows: ["row-10"],
          targetCols: [4],
        },
        // Employee benefits expense
        {
          sourceRows: ["row-38"],
          sourceCols: [2],
          targetRows: ["row-11"],
          targetCols: [3],
        },
        {
          sourceRows: ["row-38"],
          sourceCols: [4],
          targetRows: ["row-11"],
          targetCols: [4],
        },
        // Finance costs
        {
          sourceRows: ["row-45"],
          sourceCols: [2],
          targetRows: ["row-12"],
          targetCols: [3],
        },
        {
          sourceRows: ["row-45"],
          sourceCols: [4],
          targetRows: ["row-12"],
          targetCols: [4],
        },
        // Depreciation and amortization expense
        {
          sourceRows: ["row-49"],
          sourceCols: [2],
          targetRows: ["row-13"],
          targetCols: [3],
        },
        {
          sourceRows: ["row-49"],
          sourceCols: [4],
          targetRows: ["row-13"],
          targetCols: [4],
        },
        // Other expenses
        {
          sourceRows: ["row-65"],
          sourceCols: [2],
          targetRows: ["row-14"],
          targetCols: [3],
        },
        {
          sourceRows: ["row-65"],
          sourceCols: [4],
          targetRows: ["row-14"],
          targetCols: [4],
        },
        // Current Tax
        {
          sourceRows: ["row-82"],
          sourceCols: [4],
          targetRows: ["row-22"],
          targetCols: [4],
        },
        {
          sourceRows: ["row-82"],
          sourceCols: [2],
          targetRows: ["row-22"],
          targetCols: [3],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-0", "row-1"],
          sourceCols: [1],
          targetRows: ["row-0", "row-1"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "vertical-annexures-cap-liab",
      mappings: [
        {
          sourceRows: ["row-3", "row-4"],
          sourceCols: [1],
          targetRows: ["row-0", "row-1"],
          targetCols: [1],
        },
        {
          sourceRows: ["row-92"],
          sourceCols: [2],
          targetRows: ["row-6"],
          targetCols: [1],
        },
        {
          sourceRows: ["row-92"],
          sourceCols: [4],
          targetRows: ["row-6"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "vertical-annexures-assets",
      mappings: [
        {
          sourceRows: ["row-20"],
          sourceCols: [7],
          targetRows: ["row-200"],
          targetCols: [1],
        },
        {
          sourceRows: ["row-20"],
          sourceCols: [9],
          targetRows: ["row-200"],
          targetCols: [2],
        },
        {
          sourceRows: ["row-27"],
          sourceCols: [7],
          targetRows: ["row-201"],
          targetCols: [1],
        },
        {
          sourceRows: ["row-27"],
          sourceCols: [9],
          targetRows: ["row-201"],
          targetCols: [2],
        },
        {
          sourceRows: ["row-23"],
          sourceCols: [6],
          targetRows: ["row-202"],
          targetCols: [1],
        },
        {
          sourceRows: ["row-23"],
          sourceCols: [8],
          targetRows: ["row-202"],
          targetCols: [2],
        },
        {
          sourceRows: ["row-22"],
          sourceCols: [8],
          targetRows: ["row-203"],
          targetCols: [2],
        },
        {
          sourceRows: ["row-22"],
          sourceCols: [6],
          targetRows: ["row-203"],
          targetCols: [1],
        },
        {
          sourceRows: ["row-24"],
          sourceCols: [6],
          targetRows: ["row-204"],
          targetCols: [1],
        },
        {
          sourceRows: ["row-24"],
          sourceCols: [8],
          targetRows: ["row-204"],
          targetCols: [2],
        },
        {
          sourceRows: ["row-25"],
          sourceCols: [6],
          targetRows: ["row-205"],
          targetCols: [1],
        },
        {
          sourceRows: ["row-25"],
          sourceCols: [8],
          targetRows: ["row-205"],
          targetCols: [2],
        },
      ],
    },
    // form hear the annexures cap liab to balance sheet
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-9"],
          sourceCols: [1],
          targetRows: ["row-8"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-9"],
          sourceCols: [2],
          targetRows: ["row-8"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-18"],
          sourceCols: [1],
          targetRows: ["row-10"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-18"],
          sourceCols: [2],
          targetRows: ["row-10"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-45"],
          sourceCols: [1],
          targetRows: ["row-13"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-45"],
          sourceCols: [2],
          targetRows: ["row-13"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-52"],
          sourceCols: [1],
          targetRows: ["row-19"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-52"],
          sourceCols: [2],
          targetRows: ["row-19"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-60"],
          sourceCols: [1],
          targetRows: ["row-24"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-60"],
          sourceCols: [2],
          targetRows: ["row-24"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-67"],
          sourceCols: [1],
          targetRows: ["row-28"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-67"],
          sourceCols: [2],
          targetRows: ["row-28"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-74"],
          sourceCols: [1],
          targetRows: ["row-31"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-74"],
          sourceCols: [2],
          targetRows: ["row-31"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-81"],
          sourceCols: [1],
          targetRows: ["row-34"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-81"],
          sourceCols: [2],
          targetRows: ["row-34"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-88"],
          sourceCols: [1],
          targetRows: ["row-37"],
          targetCols: [1],
        },
      ],
    },
    {
      source: "vertical-annexures-cap-liab",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-88"],
          sourceCols: [2],
          targetRows: ["row-37"],
          targetCols: [2],
        },
      ],
    },
    // new vertical-annexures-assets to balance sheet
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-35"],
          sourceCols: [8],
          targetRows: ["row-11"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-36"],
          sourceCols: [8],
          targetRows: ["row-11"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-35"],
          sourceCols: [10],
          targetRows: ["row-12"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-36"],
          sourceCols: [10],
          targetRows: ["row-12"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-149"],
          sourceCols: [1],
          targetRows: ["row-23"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-149"],
          sourceCols: [2],
          targetRows: ["row-23"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-130"],
          sourceCols: [1],
          targetRows: ["row-18"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-130"],
          sourceCols: [2],
          targetRows: ["row-18"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-267"],
          sourceCols: [1],
          targetRows: ["row-39"],
          targetCols: [4],
        },
      ],
    },

    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-267"],
          sourceCols: [2],
          targetRows: ["row-39"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-254"],
          sourceCols: [1],
          targetRows: ["row-38"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-254"],
          sourceCols: [2],
          targetRows: ["row-38"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-234"],
          sourceCols: [1],
          targetRows: ["row-37"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-234"],
          sourceCols: [2],
          targetRows: ["row-37"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-214"],
          sourceCols: [1],
          targetRows: ["row-35"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-214"],
          sourceCols: [2],
          targetRows: ["row-35"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-207"],
          sourceCols: [1],
          targetRows: ["row-34"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-207"],
          sourceCols: [2],
          targetRows: ["row-34"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-196"],
          sourceCols: [1],
          targetRows: ["row-32"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-196"],
          sourceCols: [2],
          targetRows: ["row-32"],
          targetCols: [5],
        },
      ],
    },

    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-163"],
          sourceCols: [1],
          targetRows: ["row-27"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-163"],
          sourceCols: [2],
          targetRows: ["row-27"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-66"],
          sourceCols: [1],
          targetRows: ["row-16"],
          targetCols: [4],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-66"],
          sourceCols: [2],
          targetRows: ["row-16"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-40"],
          sourceCols: [8],
          targetRows: ["row-14"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-40"],
          sourceCols: [10],
          targetRows: ["row-15"],
          targetCols: [5],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-43"],
          sourceCols: [8],
          targetRows: ["row-14"],
          targetCols: [4],
        },
      ],
    },

    {
      source: "vertical-annexures-assets",
      target: "vertical-Balance-Sheet",
      mappings: [
        {
          sourceRows: ["row-43"],
          sourceCols: [10],
          targetRows: ["row-15"],
          targetCols: [4],
        },
      ],
    },
    // profit losses staetd
    {
      source: "vertical-annexures-assets",
      target: "vertical-profit-loss",
      mappings: [
        {
          sourceRows: ["row-27"],
          sourceCols: [8],
          targetRows: ["row-48"],
          targetCols: [3],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-profit-loss",
      mappings: [
        {
          sourceRows: ["row-27"],
          sourceCols: [10],
          targetRows: ["row-49"],
          targetCols: [3],
        },
      ],
    },
    {
      source: "vertical-annexures-assets",
      target: "vertical-profit-loss",
      mappings: [
        {
          sourceRows: ["row-30"],
          sourceCols: [10],
          targetRows: ["row-49"],
          targetCols: [1],
        },
      ],
    },

    {
      source: "vertical-annexures-assets",
      target: "vertical-profit-loss",
      mappings: [
        {
          sourceRows: ["row-30"],
          sourceCols: [8],
          targetRows: ["row-48"],
          targetCols: [1],
        },
      ],
    },
    // from hear the linking of the verical to horizent stated prootif and loss to the pl19-20
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-8"],
          sourceCols: [6],
          targetRows: ["row-5"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-8"],
          sourceCols: [8],
          targetRows: ["row-5"],
          targetCols: [3],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-9"],
          sourceCols: [6],
          targetRows: ["row-6"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-9"],
          sourceCols: [8],
          targetRows: ["row-6"],
          targetCols: [3],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-10"],
          sourceCols: [6],
          targetRows: ["row-7"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-10"],
          sourceCols: [8],
          targetRows: ["row-7"],
          targetCols: [3],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-11"],
          sourceCols: [6],
          targetRows: ["row-8"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-11"],
          sourceCols: [8],
          targetRows: ["row-8"],
          targetCols: [3],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-13"],
          sourceCols: [6],
          targetRows: ["row-9"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-13"],
          sourceCols: [8],
          targetRows: ["row-9"],
          targetCols: [3],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-14"],
          sourceCols: [6],
          targetRows: ["row-10"],
          targetCols: [2],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-14"],
          sourceCols: [8],
          targetRows: ["row-10"],
          targetCols: [3],
        },
      ],
    },

    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-36"],
          sourceCols: [6],
          targetRows: ["row-14"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-36"],
          sourceCols: [8],
          targetRows: ["row-14"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-37"],
          sourceCols: [6],
          targetRows: ["row-15"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-37"],
          sourceCols: [8],
          targetRows: ["row-15"],
          targetCols: [3],
        },
      ],
    },

    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-38"],
          sourceCols: [6],
          targetRows: ["row-16"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-38"],
          sourceCols: [8],
          targetRows: ["row-16"],
          targetCols: [3],
        },
      ],
    },

    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-39"],
          sourceCols: [6],
          targetRows: ["row-17"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-39"],
          sourceCols: [8],
          targetRows: ["row-17"],
          targetCols: [3],
        },
      ],
    },

    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-40"],
          sourceCols: [6],
          targetRows: ["row-18"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-40"],
          sourceCols: [8],
          targetRows: ["row-18"],
          targetCols: [3],
        },
      ],
    },

    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-41"],
          sourceCols: [6],
          targetRows: ["row-23"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-41"],
          sourceCols: [8],
          targetRows: ["row-23"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-18"],
          sourceCols: [6],
          targetRows: ["row-25"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-18"],
          sourceCols: [8],
          targetRows: ["row-25"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-19"],
          sourceCols: [6],
          targetRows: ["row-31"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-19"],
          sourceCols: [8],
          targetRows: ["row-31"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-22"],
          sourceCols: [6],
          targetRows: ["row-36"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-22"],
          sourceCols: [8],
          targetRows: ["row-36"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-8"],
          sourceCols: [1],
          targetRows: ["row-23"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-8"],
          sourceCols: [3],
          targetRows: ["row-23"],
          targetCols: [3],
        },
      ],
    },

    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-11"],
          sourceCols: [1],
          targetRows: ["row-24"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-11"],
          sourceCols: [2],
          targetRows: ["row-24"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-36"],
          sourceCols: [6],
          targetRows: ["row-14"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-36"],
          sourceCols: [8],
          targetRows: ["row-14"],
          targetCols: [3],
        },
      ],
    },
    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-24"],
          sourceCols: [1],
          targetRows: ["row-40"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-24"],
          sourceCols: [3],
          targetRows: ["row-40"],
          targetCols: [3],
        },
      ],
    },
    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-25"],
          sourceCols: [1],
          targetRows: ["row-41"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-25"],
          sourceCols: [3],
          targetRows: ["row-41"],
          targetCols: [3],
        },
      ],
    },
    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-26"],
          sourceCols: [1],
          targetRows: ["row-42"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-26"],
          sourceCols: [3],
          targetRows: ["row-42"],
          targetCols: [3],
        },
      ],
    },
    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-27"],
          sourceCols: [1],
          targetRows: ["row-43"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-27"],
          sourceCols: [3],
          targetRows: ["row-43"],
          targetCols: [3],
        },
      ],
    },

    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-105"],
          sourceCols: [1],
          targetRows: ["row-53"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-105"],
          sourceCols: [3],
          targetRows: ["row-14"],
          targetCols: [3],
        },
      ],
    },

    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-20"],
          sourceCols: [1],
          targetRows: ["row-54"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-20"],
          sourceCols: [3],
          targetRows: ["row-54"],
          targetCols: [3],
        },
      ],
    },
    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-23"],
          sourceCols: [1],
          targetRows: ["row-55"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-23"],
          sourceCols: [3],
          targetRows: ["row-55"],
          targetCols: [3],
        },
      ],
    },
    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-22"],
          sourceCols: [6],
          targetRows: ["row-58"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-22"],
          sourceCols: [8],
          targetRows: ["row-58"],
          targetCols: [3],
        },
      ],
    },

    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-27"],
          sourceCols: [6],
          targetRows: ["row-59"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-27"],
          sourceCols: [8],
          targetRows: ["row-59"],
          targetCols: [3],
        },
      ],
    },

    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-23"],
          sourceCols: [6],
          targetRows: ["row-60"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-23"],
          sourceCols: [8],
          targetRows: ["row-60"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-34"],
          sourceCols: [1],
          targetRows: ["row-67"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-34"],
          sourceCols: [3],
          targetRows: ["row-67"],
          targetCols: [3],
        },
      ],
    },

    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-35"],
          sourceCols: [1],
          targetRows: ["row-68"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-35"],
          sourceCols: [3],
          targetRows: ["row-68"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-36"],
          sourceCols: [1],
          targetRows: ["row-69"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-36"],
          sourceCols: [3],
          targetRows: ["row-69"],
          targetCols: [3],
        },
      ],
    },

    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-37"],
          sourceCols: [1],
          targetRows: ["row-70"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-37"],
          sourceCols: [3],
          targetRows: ["row-70"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-38"],
          sourceCols: [1],
          targetRows: ["row-71"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-38"],
          sourceCols: [3],
          targetRows: ["row-71"],
          targetCols: [3],
        },
      ],
    },

    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-43"],
          sourceCols: [1],
          targetRows: ["row-78"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-43"],
          sourceCols: [3],
          targetRows: ["row-78"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-44"],
          sourceCols: [1],
          targetRows: ["row-79"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-44"],
          sourceCols: [3],
          targetRows: ["row-79"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-45"],
          sourceCols: [1],
          targetRows: ["row-80"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-45"],
          sourceCols: [3],
          targetRows: ["row-80"],
          targetCols: [3],
        },
      ],
    },
    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-48"],
          sourceCols: [1],
          targetRows: ["row-84"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-48"],
          sourceCols: [3],
          targetRows: ["row-84"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-49"],
          sourceCols: [1],
          targetRows: ["row-85"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-49"],
          sourceCols: [3],
          targetRows: ["row-85"],
          targetCols: [3],
        },
      ],
    },
    //

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-53"],
          sourceCols: [1],
          targetRows: ["row-89"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-53"],
          sourceCols: [3],
          targetRows: ["row-89"],
          targetCols: [3],
        },
      ],
    },
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-54"],
          sourceCols: [1],
          targetRows: ["row-90"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-54"],
          sourceCols: [3],
          targetRows: ["row-90"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-55"],
          sourceCols: [1],
          targetRows: ["row-91"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-55"],
          sourceCols: [3],
          targetRows: ["row-91"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-56"],
          sourceCols: [1],
          targetRows: ["row-92"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-56"],
          sourceCols: [3],
          targetRows: ["row-92"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-57"],
          sourceCols: [1],
          targetRows: ["row-93"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-57"],
          sourceCols: [3],
          targetRows: ["row-93"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-58"],
          sourceCols: [1],
          targetRows: ["row-94"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-58"],
          sourceCols: [3],
          targetRows: ["row-94"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-59"],
          sourceCols: [1],
          targetRows: ["row-95"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-59"],
          sourceCols: [3],
          targetRows: ["row-95"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-60"],
          sourceCols: [1],
          targetRows: ["row-96"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-60"],
          sourceCols: [3],
          targetRows: ["row-96"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-61"],
          sourceCols: [1],
          targetRows: ["row-97"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-61"],
          sourceCols: [3],
          targetRows: ["row-97"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-62"],
          sourceCols: [1],
          targetRows: ["row-98"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-62"],
          sourceCols: [3],
          targetRows: ["row-98"],
          targetCols: [3],
        },
      ],
    },
    //

    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-63"],
          sourceCols: [1],
          targetRows: ["row-99"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-63"],
          sourceCols: [3],
          targetRows: ["row-99"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-64"],
          sourceCols: [1],
          targetRows: ["row-100"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-64"],
          sourceCols: [3],
          targetRows: ["row-100"],
          targetCols: [3],
        },
      ],
    },
    //
    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-65"],
          sourceCols: [1],
          targetRows: ["row-101"],
          targetCols: [2],
        },
      ],
    },

    {
      source: "vertical-profit-loss",
      target: "horizontal-PL-19_20",
      mappings: [
        {
          sourceRows: ["row-65"],
          sourceCols: [3],
          targetRows: ["row-101"],
          targetCols: [3],
        },
      ],
    },
    //
  ],
};

export default multiTableCopy;
