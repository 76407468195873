const calTableCopy = {
  calcutation: [
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-8", "row-9"],
        sourceCols: [1],
        targetRows: ["row-9"],
        targetCols: [2],
        operation: "sum",
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-11", "row-101"],
        sourceCols: [1],
        targetRows: ["row-101"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-11", "row-101"],
        sourceCols: [3],
        targetRows: ["row-101"],
        targetCols: [4],
        operation: "sum",
      },
    },
    //

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-24", "row-25", "row-26", "row-27"],
        sourceCols: [1],
        targetRows: ["row-27"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-82", "row-83", "row-84", "row-85"],
        sourceCols: [1],
        targetRows: ["row-85"],
        targetCols: [2],
        operation: "sum",
      },
    },
    // Profit/(loss) for the period from
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-76", "row-78"],
        sourceCols: [2],
        targetRows: ["row-79"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-76", "row-78"],
        sourceCols: [4],
        targetRows: ["row-79"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    // Profit/(loss) for the period from continue
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-79", "row-85"],
        sourceCols: [2],
        targetRows: ["row-87"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-79", "row-85"],
        sourceCols: [4],
        targetRows: ["row-87"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-89", "row-90"],
        sourceCols: [1],
        targetRows: ["row-90"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-89", "row-90"],
        sourceCols: [3],
        targetRows: ["row-90"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    // Profit/(Loss) for the year
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-87", "row-90"],
        sourceCols: [2],
        targetRows: ["row-92"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-87", "row-90"],
        sourceCols: [4],
        targetRows: ["row-92"],
        targetCols: [4],
        operation: "sum",
      },
    },
    // from hear the sum of the second row will stated
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-8",
          "row-9",
          "row-10",
          "row-11",
          "row-12",
          "row-13",
          "row-14",
          "row-15",
          "row-16",
          "row-17",
          "row-18",
          "row-19",
          "row-20",
          "row-21",
          "row-22",
          "row-23",
          "row-24",
          "row-25",
          "row-26",
          "row-27",
        ],
        sourceCols: [2],
        targetRows: ["row-28"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-31", "row-28"],
        sourceCols: [2],
        targetRows: ["row-29"],
        targetCols: [2],
        operation: "subtract",
      },
    },

    // for the thired row stated hear

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-8", "row-9"], // Rows to calculate from
        sourceCols: [3], // Columns to calculate from
        targetRows: ["row-9"], // Target row to store results
        targetCols: [4], // Target column for results
        operation: "sum", // Operation to perform: sum, mul, subtract, divide, percent
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-24", "row-25", "row-26", "row-27"],
        sourceCols: [3],
        targetRows: ["row-27"],
        targetCols: [4],
        operation: "sum",
      },
    },
    // To Employees Expenses
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-34", "row-35", "row-36", "row-37", "row-38"],
        sourceCols: [3],
        targetRows: ["row-38"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-34", "row-35", "row-36", "row-37", "row-38"],
        sourceCols: [1],
        targetRows: ["row-38"],
        targetCols: [2],
        operation: "sum",
      },
    },
    // To Finance Cost
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-41", "row-42", "row-43", "row-44", "row-45"],
        sourceCols: [3],
        targetRows: ["row-45"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-41", "row-42", "row-43", "row-44", "row-45"],
        sourceCols: [1],
        targetRows: ["row-45"],
        targetCols: [2],
        operation: "sum",
      },
    },
    // To Depreciation
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-48", "row-49"],
        sourceCols: [3],
        targetRows: ["row-49"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-48", "row-49"],
        sourceCols: [1],
        targetRows: ["row-49"],
        targetCols: [2],
        operation: "sum",
      },
    },
    // To Other Expenses
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-53",
          "row-54",
          "row-55",
          "row-56",
          "row-57",
          "row-58",
          "row-59",
          "row-60",
          "row-61",
          "row-62",
          "row-63",
          "row-64",
          "row-65",
        ],
        sourceCols: [3],
        targetRows: ["row-65"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-53",
          "row-54",
          "row-55",
          "row-56",
          "row-57",
          "row-58",
          "row-59",
          "row-60",
          "row-61",
          "row-62",
          "row-63",
          "row-64",
          "row-65",
        ],
        sourceCols: [1],
        targetRows: ["row-65"],
        targetCols: [2],
        operation: "sum",
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-82", "row-83", "row-84", "row-85"],
        sourceCols: [3],
        targetRows: ["row-85"],
        targetCols: [4],
        operation: "sum",
      },
    },
    // from hear the sum of the second row will stated
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-8",
          "row-9",
          "row-10",
          "row-11",
          "row-12",
          "row-13",
          "row-14",
          "row-15",
          "row-16",
          "row-17",
          "row-18",
          "row-19",
          "row-20",
          "row-21",
          "row-22",
          "row-23",
          "row-24",
          "row-25",
          "row-26",
          "row-27",
        ],
        sourceCols: [4],
        targetRows: ["row-28"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-31", "row-28"],
        sourceCols: [4],
        targetRows: ["row-29"],
        targetCols: [4],
        operation: "subtract",
      },
    },

    // from hear the sum of the sixth row will stated
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-102", "row-103", "row-104", "row-105", "row-13"],
        sourceCols: [1],
        targetRows: ["row-13"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-102", "row-103", "row-104", "row-105", "row-13"],
        sourceCols: [3],
        targetRows: ["row-13"],
        targetCols: [4],
        operation: "sum",
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-8",
          "row-9",
          "row-10",
          "row-11",
          "row-102",
          "row-103",
          "row-104",
          "row-105",
        ],
        sourceCols: [6],
        targetRows: ["row-13"],
        targetCols: [6],
        operation: "sum",
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-13", "row-14"],
        sourceCols: [6],
        targetRows: ["row-15"],
        targetCols: [6],
        operation: "subtract",
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-18", "row-19", "row-20"],
        sourceCols: [6],
        targetRows: ["row-20"],
        targetCols: [7],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-22", "row-23", "row-24", "row-25", "row-106"],
        sourceCols: [6],
        targetRows: ["row-25"],
        targetCols: [7],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-27"],
        sourceCols: [6],
        targetRows: ["row-27"],
        targetCols: [7],
        operation: "sum",
      },
    },
    // now for hear the row 8, 9 will started
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-8",
          "row-9",
          "row-10",
          "row-11",
          "row-102",
          "row-103",
          "row-104",
          "row-105",
        ],
        sourceCols: [8],
        targetRows: ["row-13"],
        targetCols: [8],
        operation: "sum",
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-13", "row-14"],
        sourceCols: [8],
        targetRows: ["row-15"],
        targetCols: [8],
        operation: "subtract",
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-18", "row-19", "row-20"],
        sourceCols: [8],
        targetRows: ["row-20"],
        targetCols: [9],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-22", "row-23", "row-24", "row-25", "row-106"],
        sourceCols: [8],
        targetRows: ["row-25"],
        targetCols: [9],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-27"],
        sourceCols: [8],
        targetRows: ["row-27"],
        targetCols: [9],
        operation: "sum",
      },
    },
    // seventh row total add
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-15",
          "row-16",
          "row-17",
          "row-18",
          "row-19",
          "row-20",
          "row-21",
          "row-22",
          "row-23",
          "row-24",
          "row-25",
          "row-26",
          "row-27",
          "row-28",
          "row-29",
        ],
        sourceCols: [7],
        targetRows: ["row-31"],
        targetCols: [7],
        operation: "sum",
      },
    },
    //9 col
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-15",
          "row-16",
          "row-17",
          "row-18",
          "row-19",
          "row-20",
          "row-21",
          "row-22",
          "row-23",
          "row-24",
          "row-25",
          "row-26",
          "row-27",
          "row-28",
          "row-29",
        ],
        sourceCols: [9],
        targetRows: ["row-31"],
        targetCols: [9],
        operation: "sum",
      },
    },

    // from hear the balance sheet will stated
    {
      source: "vertical-Balance-Sheet",
      mapping: {
        sourceRows: [
          "row-8",
          "row-9",
          "row-10",
          "row-11",
          "row-12",
          "row-13",
          "row-14",
          "row-15",
          "row-16",
          "row-17",
          "row-18",
          "row-19",
          "row-20",
          "row-21",
          "row-22",
          "row-23",
          "row-24",
          "row-25",
          "row-26",
          "row-27",
          "row-28",
          "row-29",
          "row-30",
          "row-31",
          "row-32",
          "row-33",
          "row-34",
          "row-35",
          "row-36",
          "row-37",
          "row-38",
          "row-39",
          "row-40",
        ],
        sourceCols: [1],
        targetRows: ["row-41"],
        targetCols: [1],
        operation: "sum",
      },
    },
    //
    {
      source: "vertical-Balance-Sheet",
      mapping: {
        sourceRows: [
          "row-8",
          "row-9",
          "row-10",
          "row-11",
          "row-12",
          "row-13",
          "row-14",
          "row-15",
          "row-16",
          "row-17",
          "row-18",
          "row-19",
          "row-20",
          "row-21",
          "row-22",
          "row-23",
          "row-24",
          "row-25",
          "row-26",
          "row-27",
          "row-28",
          "row-29",
          "row-30",
          "row-31",
          "row-32",
          "row-33",
          "row-34",
          "row-35",
          "row-36",
          "row-37",
          "row-38",
          "row-39",
          "row-40",
        ],
        sourceCols: [2],
        targetRows: ["row-41"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-Balance-Sheet",
      mapping: {
        sourceRows: [
          "row-8",
          "row-9",
          "row-10",
          "row-11",
          "row-12",
          "row-13",
          "row-14",
          "row-15",
          "row-16",
          "row-17",
          "row-18",
          "row-19",
          "row-20",
          "row-21",
          "row-22",
          "row-23",
          "row-24",
          "row-25",
          "row-26",
          "row-27",
          "row-28",
          "row-29",
          "row-30",
          "row-31",
          "row-32",
          "row-33",
          "row-34",
          "row-35",
          "row-36",
          "row-37",
          "row-38",
          "row-39",
          "row-40",
        ],
        sourceCols: [4],
        targetRows: ["row-41"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-Balance-Sheet",
      mapping: {
        sourceRows: [
          "row-8",
          "row-9",
          "row-10",
          "row-11",
          "row-12",
          "row-13",
          "row-14",
          "row-15",
          "row-16",
          "row-17",
          "row-18",
          "row-19",
          "row-20",
          "row-21",
          "row-22",
          "row-23",
          "row-24",
          "row-25",
          "row-26",
          "row-27",
          "row-28",
          "row-29",
          "row-30",
          "row-31",
          "row-32",
          "row-33",
          "row-34",
          "row-35",
          "row-36",
          "row-37",
          "row-38",
          "row-39",
          "row-40",
        ],
        sourceCols: [5],
        targetRows: ["row-41"],
        targetCols: [5],
        operation: "sum",
      },
    },

    // from hear the vertical-annexures-cap-liab will stated
    // total
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-27", "row-32", "row-38", "row-43"],
        sourceCols: [1],
        targetRows: ["row-45"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-27", "row-32", "row-38", "row-43"],
        sourceCols: [2],
        targetRows: ["row-45"],
        targetCols: [2],
        operation: "sum",
      },
    },
    // Annexure A: Proprietor`s Capital Account
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-4", "row-5", "row-6"],
        sourceCols: [1],
        targetRows: ["row-7"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-7", "row-8"],
        sourceCols: [1],
        targetRows: ["row-9"],
        targetCols: [1],
        operation: "subtract",
      },
    },
    //
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-4", "row-5", "row-6"],
        sourceCols: [2],
        targetRows: ["row-7"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-7", "row-8"],
        sourceCols: [2],
        targetRows: ["row-9"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    // Annexure B: Reserves and Surplus
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-14", "row-15", "row-16"],
        sourceCols: [1],
        targetRows: ["row-18"],
        targetCols: [1],
        operation: "sum",
      },
    },
    // Annexure B: Reserves and Surplus
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-14", "row-15", "row-16"],
        sourceCols: [2],
        targetRows: ["row-18"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-24", "row-25", "row-26"],
        sourceCols: [1],
        targetRows: ["row-27"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-24", "row-25", "row-26"],
        sourceCols: [2],
        targetRows: ["row-27"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-30", "row-31"],
        sourceCols: [2],
        targetRows: ["row-32"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-30", "row-31"],
        sourceCols: [1],
        targetRows: ["row-32"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-35", "row-36", "row-37"],
        sourceCols: [1],
        targetRows: ["row-38"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-35", "row-36", "row-37"],
        sourceCols: [2],
        targetRows: ["row-38"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-41", "row-42"],
        sourceCols: [2],
        targetRows: ["row-43"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-41", "row-42"],
        sourceCols: [1],
        targetRows: ["row-43"],
        targetCols: [1],
        operation: "sum",
      },
    },

    // Annexure D: LONG TERM PROVSIONS
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-50"],
        sourceCols: [1],
        targetRows: ["row-52"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-50"],
        sourceCols: [2],
        targetRows: ["row-52"],
        targetCols: [2],
        operation: "sum",
      },
    },

    // Annexure E: SHORT TERM BORROWINGS
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-57", "row-58", "row-59"],
        sourceCols: [2],
        targetRows: ["row-60"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-57", "row-58", "row-59"],
        sourceCols: [1],
        targetRows: ["row-60"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-65", "row-66"],
        sourceCols: [1],
        targetRows: ["row-67"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-65", "row-66"],
        sourceCols: [2],
        targetRows: ["row-67"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-72", "row-73"],
        sourceCols: [2],
        targetRows: ["row-74"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-72", "row-73"],
        sourceCols: [1],
        targetRows: ["row-74"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-79"],
        sourceCols: [1],
        targetRows: ["row-81"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-79"],
        sourceCols: [2],
        targetRows: ["row-81"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-86"],
        sourceCols: [2],
        targetRows: ["row-88"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-cap-liab",
      mapping: {
        sourceRows: ["row-86"],
        sourceCols: [1],
        targetRows: ["row-88"],
        targetCols: [1],
        operation: "sum",
      },
    },
    // Profit/loss before extraordinary items
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-76", "row-74"],
        sourceCols: [2],
        targetRows: ["row-75"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-76", "row-74"],
        sourceCols: [4],
        targetRows: ["row-75"],
        targetCols: [4],
        operation: "subtract",
      },
    },

    // other added
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-34", "row-41"],
        sourceCols: [7],
        targetRows: ["row-71"],
        targetCols: [7],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-34", "row-41"],
        sourceCols: [9],
        targetRows: ["row-71"],
        targetCols: [9],
        operation: "sum",
      },
    },
    //To Profit and loss before exceptional & 	Extraordinary items and tax
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-65", "row-49", "row-45", "row-38"],
        sourceCols: [2],
        targetRows: ["row-67"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-71", "row-67"],
        sourceCols: [2],
        targetRows: ["row-69"],
        targetCols: [2],
        operation: "subtract",
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-65", "row-49", "row-45", "row-38"],
        sourceCols: [4],
        targetRows: ["row-67"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-71", "row-67"],
        sourceCols: [4],
        targetRows: ["row-69"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    // By Gross Profit
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-35",
          "row-36",
          "row-37",
          "row-38",
          "row-39",
          "row-40",
          "row-41",
        ],
        sourceCols: [6],
        targetRows: ["row-41"],
        targetCols: [7],
        operation: "sum",
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: [
          "row-35",
          "row-36",
          "row-37",
          "row-38",
          "row-39",
          "row-40",
          "row-41",
        ],
        sourceCols: [8],
        targetRows: ["row-41"],
        targetCols: [9],
        operation: "sum",
      },
    },

    // form hear the calculation of the horizontal-profit-loss will stated
    // Total Income (I+II)
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-6", "row-7"],
        sourceCols: [3],
        targetRows: ["row-8"],
        targetCols: [3],
        operation: "sum",
      },
    },
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-6", "row-7"],
        sourceCols: [4],
        targetRows: ["row-8"],
        targetCols: [4],
        operation: "sum",
      },
    },
    // Total expenses-----------------------------------------------------------------------------------    -----------
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-10", "row-11", "row-12", "row-13", "row-14"],
        sourceCols: [3],
        targetRows: ["row-15"],
        targetCols: [3],
        operation: "sum",
      },
    },
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-10", "row-11", "row-12", "row-13", "row-14"],
        sourceCols: [4],
        targetRows: ["row-15"],
        targetCols: [4],
        operation: "sum",
      },
    },
    // 	Profit/(loss) before exceptional and extraordinary items and tax (III- IV)
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-8", "row-15"],
        sourceCols: [3],
        targetRows: ["row-16"],
        targetCols: [3],
        operation: "subtract",
      },
    },
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-8", "row-15"],
        sourceCols: [4],
        targetRows: ["row-16"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    // 	Profit/(loss) before extraordinary items and tax (V-VI)
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-16", "row-17"],
        sourceCols: [4],
        targetRows: ["row-18"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-16", "row-17"],
        sourceCols: [3],
        targetRows: ["row-18"],
        targetCols: [3],
        operation: "subtract",
      },
    },
    // 	Profit before tax (VII-VIII)
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [3],
        targetRows: ["row-20"],
        targetCols: [3],
        operation: "subtract",
      },
    },
    {
      source: "horizontal-profit-loss",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [4],
        targetRows: ["row-20"],
        targetCols: [4],
        operation: "subtract",
      },
    },

    // ---------------------- form hear the calculation of the vertical-annexures-assets will start ----------------------------------------------------------------
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-5"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-5"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-7"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-8"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-8"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-10"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-11"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-11"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-15"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-16"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-16"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-18"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-19"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-19"],
        targetCols: [8],
        operation: "sum",
      },
    },

    // FROMHEAR THE NEW ONE WILL STARTED
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-24"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-24"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-25"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-25"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-26"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-26"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-28"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-28"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-29"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-29"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-40"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-40"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-41"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-41"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-42"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7],
        targetRows: ["row-42"],
        targetCols: [8],
        operation: "sum",
      },
    },
    // for the software
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-24"],
        sourceCols: [9],
        targetRows: ["row-24"],
        targetCols: [10],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-25"],
        sourceCols: [9],
        targetRows: ["row-25"],
        targetCols: [10],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-26"],
        sourceCols: [9],
        targetRows: ["row-26"],
        targetCols: [10],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-28"],
        sourceCols: [9],
        targetRows: ["row-28"],
        targetCols: [10],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-29"],
        sourceCols: [9],
        targetRows: ["row-29"],
        targetCols: [10],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-40"],
        sourceCols: [9],
        targetRows: ["row-40"],
        targetCols: [10],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-41"],
        sourceCols: [9],
        targetRows: ["row-41"],
        targetCols: [10],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-42"],
        sourceCols: [9],
        targetRows: ["row-42"],
        targetCols: [10],
        operation: "sum",
      },
    },
    // end
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [1],
        targetRows: ["row-9"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [2],
        targetRows: ["row-9"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [3],
        targetRows: ["row-9"],
        targetCols: [3],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [4],
        targetRows: ["row-9"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [5],
        targetRows: ["row-9"],
        targetCols: [5],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [6],
        targetRows: ["row-9"],
        targetCols: [6],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [7],
        targetRows: ["row-9"],
        targetCols: [7],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [8],
        targetRows: ["row-9"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [9],
        targetRows: ["row-9"],
        targetCols: [9],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-6", "row-7", "row-8"],
        sourceCols: [10],
        targetRows: ["row-9"],
        targetCols: [10],
        operation: "sum",
      },
    },
    // 10 +11=12
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [1],
        targetRows: ["row-12"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [2],
        targetRows: ["row-12"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [3],
        targetRows: ["row-12"],
        targetCols: [3],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [4],
        targetRows: ["row-12"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [5],
        targetRows: ["row-12"],
        targetCols: [5],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [6],
        targetRows: ["row-12"],
        targetCols: [6],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [7],
        targetRows: ["row-12"],
        targetCols: [7],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [8],
        targetRows: ["row-12"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [9],
        targetRows: ["row-12"],
        targetCols: [9],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-11"],
        sourceCols: [10],
        targetRows: ["row-12"],
        targetCols: [10],
        operation: "sum",
      },
    },
    // clossing assets total
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [1],
        targetRows: ["row-13"],
        targetCols: [1],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [2],
        targetRows: ["row-13"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [3],
        targetRows: ["row-13"],
        targetCols: [3],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [4],
        targetRows: ["row-13"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [5],
        targetRows: ["row-13"],
        targetCols: [5],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [6],
        targetRows: ["row-13"],
        targetCols: [6],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [7],
        targetRows: ["row-13"],
        targetCols: [7],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [8],
        targetRows: ["row-13"],
        targetCols: [8],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [9],
        targetRows: ["row-13"],
        targetCols: [9],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-9", "row-12"],
        sourceCols: [10],
        targetRows: ["row-13"],
        targetCols: [10],
        operation: "subtract",
      },
    },
    // seccond part
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [1],
        targetRows: ["row-17"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [2],
        targetRows: ["row-17"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [3],
        targetRows: ["row-17"],
        targetCols: [3],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [4],
        targetRows: ["row-17"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [5],
        targetRows: ["row-17"],
        targetCols: [5],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [6],
        targetRows: ["row-17"],
        targetCols: [6],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [7],
        targetRows: ["row-17"],
        targetCols: [7],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [8],
        targetRows: ["row-17"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [9],
        targetRows: ["row-17"],
        targetCols: [9],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-15", "row-16"],
        sourceCols: [10],
        targetRows: ["row-17"],
        targetCols: [10],
        operation: "sum",
      },
    },

    // from hare new start ------------------------------------------------------------------------------------------------
    // 18+19
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [1],
        targetRows: ["row-20"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [2],
        targetRows: ["row-20"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [3],
        targetRows: ["row-20"],
        targetCols: [3],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [4],
        targetRows: ["row-20"],
        targetCols: [4],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [5],
        targetRows: ["row-20"],
        targetCols: [5],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [6],
        targetRows: ["row-20"],
        targetCols: [6],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [7],
        targetRows: ["row-20"],
        targetCols: [7],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [8],
        targetRows: ["row-20"],
        targetCols: [8],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [9],
        targetRows: ["row-20"],
        targetCols: [9],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-19"],
        sourceCols: [10],
        targetRows: ["row-20"],
        targetCols: [10],
        operation: "sum",
      },
    },
    // 17-20
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [1],
        targetRows: ["row-21"],
        targetCols: [1],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [2],
        targetRows: ["row-21"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [3],
        targetRows: ["row-21"],
        targetCols: [3],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [4],
        targetRows: ["row-21"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [5],
        targetRows: ["row-21"],
        targetCols: [5],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [6],
        targetRows: ["row-21"],
        targetCols: [6],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [7],
        targetRows: ["row-21"],
        targetCols: [7],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [8],
        targetRows: ["row-21"],
        targetCols: [8],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [9],
        targetRows: ["row-21"],
        targetCols: [9],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-17", "row-20"],
        sourceCols: [10],
        targetRows: ["row-21"],
        targetCols: [10],
        operation: "subtract",
      },
    },

    // ------------------------------------------------------------------------------------------------
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [1],
        targetRows: ["row-35"],
        targetCols: [1],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [2],
        targetRows: ["row-35"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [3],
        targetRows: ["row-35"],
        targetCols: [3],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [4],
        targetRows: ["row-35"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [5],
        targetRows: ["row-35"],
        targetCols: [5],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [6],
        targetRows: ["row-35"],
        targetCols: [6],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [7],
        targetRows: ["row-35"],
        targetCols: [7],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [8],
        targetRows: ["row-35"],
        targetCols: [8],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [9],
        targetRows: ["row-35"],
        targetCols: [9],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13", "row-27"],
        sourceCols: [10],
        targetRows: ["row-35"],
        targetCols: [10],
        operation: "subtract",
      },
    },
    //
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [1],
        targetRows: ["row-36"],
        targetCols: [1],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [2],
        targetRows: ["row-36"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [3],
        targetRows: ["row-36"],
        targetCols: [3],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [4],
        targetRows: ["row-36"],
        targetCols: [4],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [5],
        targetRows: ["row-36"],
        targetCols: [5],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [6],
        targetRows: ["row-36"],
        targetCols: [6],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [7],
        targetRows: ["row-36"],
        targetCols: [7],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [8],
        targetRows: ["row-36"],
        targetCols: [8],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [9],
        targetRows: ["row-36"],
        targetCols: [9],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-21", "row-30"],
        sourceCols: [10],
        targetRows: ["row-36"],
        targetCols: [10],
        operation: "subtract",
      },
    },
    // new start ------------------------------------------------------------------------------------------------
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-122", "row-128"],
        sourceCols: [1],
        targetRows: ["row-129"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-122", "row-128"],
        sourceCols: [2],
        targetRows: ["row-129"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-129", "row-115"],
        sourceCols: [1],
        targetRows: ["row-130"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-129", "row-115"],
        sourceCols: [2],
        targetRows: ["row-130"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-63", "row-64"],
        sourceCols: [1],
        targetRows: ["row-66"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-63", "row-64"],
        sourceCols: [2],
        targetRows: ["row-66"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-81", "row-82"],
        sourceCols: [1],
        targetRows: ["row-83"],
        targetCols: [1],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-81", "row-82"],
        sourceCols: [2],
        targetRows: ["row-83"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-80",
          "row-83",
          "row-84",
          "row-86",
          "row-87",
          "row-88",
          "row-89",
        ],
        sourceCols: [1],
        targetRows: ["row-92"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-80",
          "row-83",
          "row-84",
          "row-86",
          "row-87",
          "row-88",
          "row-89",
        ],
        sourceCols: [2],
        targetRows: ["row-92"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-100", "row-101"],
        sourceCols: [1],
        targetRows: ["row-102"],
        targetCols: [1],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-100", "row-101"],
        sourceCols: [2],
        targetRows: ["row-102"],
        targetCols: [2],
        operation: "subtract",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-99",
          "row-102",
          "row-103",
          "row-106",
          "row-107",
          "row-108",
          "row-109",
          "row-110",
        ],
        sourceCols: [1],
        targetRows: ["row-112"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-99",
          "row-102",
          "row-103",
          "row-106",
          "row-107",
          "row-108",
          "row-109",
          "row-110",
        ],
        sourceCols: [2],
        targetRows: ["row-112"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-92", "row-112"],
        sourceCols: [1],
        targetRows: ["row-115"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-92", "row-112"],
        sourceCols: [2],
        targetRows: ["row-115"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-118", "row-119", "row-120", "row-121"],
        sourceCols: [1],
        targetRows: ["row-122"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-118", "row-119", "row-120", "row-121"],
        sourceCols: [2],
        targetRows: ["row-122"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-124", "row-125", "row-126", "row-127"],
        sourceCols: [1],
        targetRows: ["row-128"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-124", "row-125", "row-126", "row-127"],
        sourceCols: [2],
        targetRows: ["row-128"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-142", "row-143", "row-144", "row-145", "row-146"],
        sourceCols: [1],
        targetRows: ["row-147"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-142", "row-143", "row-144", "row-145", "row-146"],
        sourceCols: [2],
        targetRows: ["row-147"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-138", "row-141", "row-147"],
        sourceCols: [1],
        targetRows: ["row-149"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-138", "row-141", "row-147"],
        sourceCols: [2],
        targetRows: ["row-149"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-157", "row-158", "row-159", "row-160", "row-161"],
        sourceCols: [1],
        targetRows: ["row-163"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-157", "row-158", "row-159", "row-160", "row-161"],
        sourceCols: [2],
        targetRows: ["row-163"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-172",
          "row-173",
          "row-174",
          "row-175",
          "row-176",
          "row-177",
          "row-178",
          "row-179",
        ],
        sourceCols: [1],
        targetRows: ["row-181"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-172",
          "row-173",
          "row-174",
          "row-175",
          "row-176",
          "row-177",
          "row-178",
          "row-179",
        ],
        sourceCols: [2],
        targetRows: ["row-181"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-185",
          "row-186",
          "row-187",
          "row-188",
          "row-189",
          "row-190",
        ],
        sourceCols: [1],
        targetRows: ["row-194"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-185",
          "row-186",
          "row-187",
          "row-188",
          "row-189",
          "row-190",
        ],
        sourceCols: [2],
        targetRows: ["row-194"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-181", "row-194"],
        sourceCols: [1],
        targetRows: ["row-196"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-181", "row-194"],
        sourceCols: [2],
        targetRows: ["row-196"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-199",
          "row-200",
          "row-201",
          "row-202",
          "row-203",
          "row-204",
          "row-205",
          "row-206",
        ],
        sourceCols: [1],
        targetRows: ["row-207"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: [
          "row-199",
          "row-200",
          "row-201",
          "row-202",
          "row-203",
          "row-204",
          "row-205",
          "row-206",
        ],
        sourceCols: [2],
        targetRows: ["row-207"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-228", "row-229", "row-230", "row-231", "row-232"],
        sourceCols: [1],
        targetRows: ["row-233"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-228", "row-229", "row-230", "row-231", "row-232"],
        sourceCols: [2],
        targetRows: ["row-233"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-226"],
        sourceCols: [1],
        targetRows: ["row-227"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-226"],
        sourceCols: [2],
        targetRows: ["row-227"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-224", "row-227", "row-233"],
        sourceCols: [1],
        targetRows: ["row-234"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-224", "row-227", "row-233"],
        sourceCols: [2],
        targetRows: ["row-234"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-240", "row-241", "row-242", "row-243", "row-244"],
        sourceCols: [1],
        targetRows: ["row-245"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-240", "row-241", "row-242", "row-243", "row-244"],
        sourceCols: [2],
        targetRows: ["row-245"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-249", "row-250", "row-251"],
        sourceCols: [1],
        targetRows: ["row-252"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-249", "row-250", "row-251"],
        sourceCols: [2],
        targetRows: ["row-252"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-245", "row-252"],
        sourceCols: [1],
        targetRows: ["row-254"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-245", "row-252"],
        sourceCols: [2],
        targetRows: ["row-254"],
        targetCols: [2],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-262", "row-263", "row-264", "row-265", "row-266"],
        sourceCols: [1],
        targetRows: ["row-267"],
        targetCols: [1],
        operation: "sum",
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-262", "row-263", "row-264", "row-265", "row-266"],
        sourceCols: [2],
        targetRows: ["row-267"],
        targetCols: [2],
        operation: "sum",
      },
    },

    //
  ],
};

export default calTableCopy;
