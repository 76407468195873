import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaUserCircle } from "react-icons/fa";
import "./Components.css";

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios
        .get("https://vertical.lblfm.com/api/userdetails") // Adjust the API endpoint as necessary
        .then((response) => {
          setUserDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
          navigate("/login"); // Redirect to login on error
        });
    } else {
      navigate("/login"); // Redirect to login if no token
    }
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  if (loading) {
    return null; // Don't render anything while loading
  }

  return (
    <header className="header1">
      <div className="logo-container">
        <img src="" alt="Logo" className="logo" />
      </div>
      <div className="user-container">
        <FaUserCircle
          className="user-icon"
          onClick={() => setShowDropdown(!showDropdown)}
        />
        {showDropdown && (
          <div className="dropdown-menu" ref={dropdownRef}>
            {userDetails ? (
              <>
                <p>{userDetails.username}</p>
                <p>{userDetails.email}</p>
                {/* <button onClick={() => navigate("/change-password")}>
                  Change Password
                </button> */}
                <button onClick={handleLogout}>Logout</button>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
