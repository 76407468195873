// src/Popup.js
import React from "react";
import "./Popup.css";
import { IoMdCloseCircle } from "react-icons/io";

const Popup = ({ show, handleClose, children }) => {
  return (
    <div className={`popup ${show ? "show" : ""}`}>
      <div className="popup-content">
        <button className="close-btn" onClick={handleClose}>
          <IoMdCloseCircle />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
