const NoteData1 = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "M/S:-",
          colspan: 1,
          class: "no-border-r free",
        },
        {
          content: "",
          colspan: 9,
          isUserProvided: false,
          class: "no-border-r free",
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        {
          content:
            "Notes forming part of the Financial Statements  for the year ended, 31st March, 2024",
          colspan: 10,
          class: "no-border-r free bgblue bold",
        },
      ],
    },
    {
      id: "row-2",
      cells: [
        {
          content: "Note - 1 Background of the owner/proprietor",
          colspan: 10,
          class: "no-border-r free",
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        {
          content: "Note - 2 Significant Accounting Policies",
          colspan: 10,
          class: "no-border-r free",
        },
      ],
    },
    {
      id: "row-4",
      cells: [
        {
          content:
            "The accounts has been prepared on the basis of accrual accounting policy. ",
          colspan: 10,
          class: "no-border-r free",
        },
      ],
    },
    {
      id: "row-5",
      cells: [
        {
          content: "Note - 3 Partners' Capital Account",
          colspan: 10,
          class: "no-border-r free",
        },
      ],
    },
    {
      id: "row-6",
      cells: [
        { content: "S. NO", class: "bold" },
        { content: "Name of  Partners", class: "bold" },
        { content: "Share of profit/ (loss)(%)", class: "bold" },
        { content: "As at 1st April 2022(Opening Balance)", class: "bold" },
        {
          content: "Capital Introduced/contributed during the year",
          class: "bold",
        },
        { content: "Partner`s Salary", class: "bold" },
        { content: "Interest for the year (capital)", class: "bold" },
        { content: "Withdrawals during the year", class: "bold" },
        { content: "Share of Profit / Loss for the year", class: "bold" },
        { content: "As at 31st March 2023 (Closing Balance)", class: "bold" },
      ],
    },

    // test

    {
      id: "row-7",
      cells: [
        { content: "1", class: "bold" },
        { content: "Partner 1", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-8",
      cells: [
        { content: "2", class: "bold" },
        { content: "Partner 2", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-9",
      cells: [
        {
          content: ".",
          colspan: 3,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },

    {
      id: "row-10",
      cells: [
        {
          content: "Previous Year (PY)",
          colspan: 3,
          class: "no-border-r free ",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },

    {
      id: "row-11",
      cells: [
        {
          content: ".",
          colspan: 10,
          class: "no-border-r free border-cstransparent",
        },
      ],
    },
    {
      id: "row-12",
      cells: [
        {
          content: ".",
          colspan: 10,
          class: "no-border-r free border-transparent",
        },
      ],
    },

    // second form data

    {
      id: "row-13",
      cells: [
        { content: "S. NO", class: "bold" },
        { content: "Name of  Owner", class: "bold" },
        { content: "Share of profit/ (loss)(%)", class: "bold" },
        { content: "As at 1st April 2023(Opening Balance)", class: "bold" },
        {
          content: "Capital Introduced/contributed during the year",
          class: "bold",
        },
        {
          content: "Salary /Remuneration",
          class: "bold",
        },
        { content: "Interest for the year (capital)", class: "bold" },
        { content: "Withdrawals during the year", class: "bold" },
        { content: "Share of Profit / Loss for the year", class: "bold" },
        { content: "As at 31st March 2023 (Closing Balance)", class: "bold" },
      ],
    },

    // test

    {
      id: "row-14",
      cells: [
        { content: "1", class: "bold" },
        { content: "Partner 1", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "",
          isUserProvided: false,
        },

        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-15",
      cells: [
        { content: "2", class: "bold" },
        { content: "Partner 2", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },

        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-16",
      cells: [
        {
          content: ".",
          colspan: 3,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },

    {
      id: "row-17",
      cells: [
        {
          content: "Previous Year (PY)",
          colspan: 3,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
  ],
};
export default NoteData1;
