const plData12 = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "NAME OF ASSESSEE:",
          colspan: 2,
          class: "no-border-r free",
        },
        {
          content: "",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free",
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        {
          content: "Notes forming part of the Financial Statements",
          colspan: 4,
          class: "no-border-r free bgblue center",
        },
      ],
    },
    {
      id: "row-2",
      cells: [
        {
          content: ".",
          colspan: 4,
          class: "no-border-r free center",
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        {
          content: ".",
          colspan: 3,
          class: "no-border-r free center trans-text",
        },
        {
          content: "(Amount in Rs.)",
          isUserProvided: false,
          class: "no-border",
        },
      ],
    },
    // from hear the new one will start
    {
      id: "row-4",
      cells: [
        {
          content: "19",
          class: "bold topBd ",
        },
        {
          content: "Revenue from operations",
          class: "topBd ",
        },
        {
          content: "For the Current year  end",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "For the Preceding year end",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    // new
    {
      id: "row-5",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Sale of products",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-6",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "Sale of services",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-7",
      cells: [
        {
          content: "(c)",
          class: "",
        },
        {
          content: "Grants or donations received ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-8",
      cells: [
        {
          content: "(d)",
          class: "",
        },
        {
          content: "Other operating revenue",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-9",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Revenue from operations (Gross)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-10",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Less: Any taxes,GST, duty etc",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-11",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Revenue from operations (Net)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-12",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-13",
      cells: [
        {
          content: "20",
          class: "bold",
        },
        {
          content: "Other income",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-14",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "SB Interest",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-15",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "FD Interest",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-16",
      cells: [
        {
          content: "(c)",
          class: "",
        },
        {
          content: "Commission recived",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-17",
      cells: [
        {
          content: "(d)",
          class: "",
        },
        {
          content: "Incentive recived",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-18",
      cells: [
        {
          content: "(e)",
          class: "",
        },
        {
          content: "Discount recived",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-123",
      cells: [
        {
          content: "(e)",
          class: "",
        },
        {
          content: "By Other Income (If any)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-19",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total other income",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-20",
      cells: [
        {
          content: "21",
          class: "bold",
        },
        {
          content: "Cost of goods sold (Delete whatever is not applicable)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-21",
      cells: [
        {
          content: "A",
          class: "",
        },
        {
          content: "Cost of raw material consumed",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-22",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Raw Material consumed",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-23",
      cells: [
        {
          content: "(i)",
          class: "",
        },
        {
          content: "Inventory of Raw Material at the beginning of the year",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-24",
      cells: [
        {
          content: "(ii)",
          class: "",
        },
        {
          content: "Add : Purchases during the year",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-25",
      cells: [
        {
          content: "(iii)",
          class: "",
        },
        {
          content: "Less: Inventory at the end of the year",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-26",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Cost of raw material consumed  (I)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder ",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder ",
        },
      ],
    },
    {
      id: "row-27",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-28",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content:
            "Packing material consumed (if considered as part of raw material)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-29",
      cells: [
        {
          content: "(i)",
          class: "",
        },
        {
          content: "Inventory at the beginning of the year",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-30",
      cells: [
        {
          content: "(ii)",
          class: "",
        },
        {
          content: "Add : Purchases during the year",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-31",
      cells: [
        {
          content: "(iii)",
          class: "",
        },
        {
          content: "Less: Inventory at the end of the year",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-32",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Cost of packing material consumed  (II)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-33",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Other materials (purchased intermediates and components)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-34",
      cells: [
        {
          content: "(i)",
          class: "",
        },
        {
          content: "Inventory at the beginning of the year",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-35",
      cells: [
        {
          content: "(ii)",
          class: "",
        },
        {
          content: "Add : Purchases during the year",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-36",
      cells: [
        {
          content: "(iii)",
          class: "",
        },
        {
          content: "Less: Inventory at the end of the year",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-37",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Cost of other material consumed  (III)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-38",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total raw material consumed (A)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-39",
      cells: [
        {
          content: "B",
          class: "bold",
        },
        {
          content: "Direct Expenses",
          class: "",
        },
        {
          content: "For the year ended",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "for the year ended",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-40",
      cells: [
        {
          content: "(i)",
          class: "",
        },
        {
          content: "Freight",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-41",
      cells: [
        {
          content: "(ii)",
          class: "",
        },
        {
          content: "Carriage Inwards",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-42",
      cells: [
        {
          content: "(iii)",
          class: "",
        },
        {
          content: "Transportation",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-43",
      cells: [
        {
          content: "(iv)",
          class: "",
        },
        {
          content: "other",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-46",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-47",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-48",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total (B)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-49",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-50",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-51",
      cells: [
        {
          content: "c",
          class: "bold",
        },
        {
          content:
            "Changes in inventories of finished goods, work in progress and stock-in trade",
          class: "bold",
        },
        {
          content: "for the year end",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "for the year end",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-52",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Inventories at the beginning of the year:",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-53",
      cells: [
        {
          content: "(i)",
          class: "",
        },
        {
          content: "Stock-in-trade",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-54",
      cells: [
        {
          content: "(ii)",
          class: "",
        },
        {
          content: "Work in progress ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-55",
      cells: [
        {
          content: "(iii)",
          class: "",
        },
        {
          content: "Finished goods",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-56",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "(I)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topBd ",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topBd",
        },
      ],
    },
    {
      id: "row-57",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Inventories at the end of the year:",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-58",
      cells: [
        {
          content: "(i)",
          class: "",
        },
        {
          content: "Stock-in-trade",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-59",
      cells: [
        {
          content: "(ii)",
          class: "",
        },
        {
          content: "Work in progress ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-60",
      cells: [
        {
          content: "(iii)",
          class: "",
        },
        {
          content: "Finished goods",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-61",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "(II)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-62",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "(Increase)/decrease in   stock-in-trade (C)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-63",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total (A+B+C)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-64",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-65",
      cells: [
        {
          content: "22",
          class: "bold",
        },
        {
          content: "Employee benefits expense",
          class: "bold",
        },
        {
          content: "For the year ended",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: "For the year ended",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-66",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "(Including contract labour)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-67",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Salaries, wages, bonus and other allowances",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-68",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "Bouns on Salary",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-69",
      cells: [
        {
          content: "(c)",
          class: "",
        },
        {
          content: "Gratuity expenses",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-70",
      cells: [
        {
          content: "(d)",
          class: "",
        },
        {
          content: "Staff welfare expenses",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-71",
      cells: [
        {
          content: "(e)",
          class: "",
        },
        {
          content: "EPF/ESI expenses",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    // {
    //   id: "row-72",
    //   cells: [
    //     {
    //       content: "(f)",
    //       class: "",
    //     },
    //     {
    //       content: "other expense",
    //       class: "",
    //     },
    //     {
    //       content: "",
    //       isUserProvided: false,
    //       class: "",
    //     },
    //     {
    //       content: "",
    //       isUserProvided: false,
    //       class: "",
    //     },
    //   ],
    // },
    {
      id: "row-73",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total Employee benefits expense",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-74",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-75",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-76",
      cells: [
        {
          content: "23",
          class: "bold",
        },
        {
          content: "Final cost",
          class: "bold",
        },
        {
          content: "For the year ended",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "For the year ended",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-77",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Interest expense",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-78",
      cells: [
        {
          content: "(i)",
          class: "",
        },
        {
          content: "On Bank loan ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-79",
      cells: [
        {
          content: "(ii)",
          class: "",
        },
        {
          content: "On assets on finance lease",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-80",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "Other borrowing costs",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-81",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total Finance cost",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-82",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-83",
      cells: [
        {
          content: "24",
          class: "bold",
        },
        {
          content: "Depreciation and amortization expense",
          class: "bold",
        },
        {
          content: "For the year ended",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "For the year ended",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-84",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "on tangible assets (Refer note 11)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-85",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "on intangible assets (Refer note 11)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-86",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total Depreciation and amortization expense",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-87",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-88",
      cells: [
        {
          content: "25",
          class: "bold",
        },
        {
          content: "Other Expenses",
          class: "bold",
        },
        {
          content: "For the year ended",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "For the year ended",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-89",
      cells: [
        {
          content: "a",
          class: "",
        },
        {
          content: "Incentives",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-90",
      cells: [
        {
          content: "b",
          class: "",
        },
        {
          content: "Tour and Travel",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-91",
      cells: [
        {
          content: "c",
          class: "",
        },
        {
          content: "Audit fees",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-92",
      cells: [
        {
          content: "d",
          class: "",
        },
        {
          content: "Bank Charges",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-93",
      cells: [
        {
          content: "e",
          class: "",
        },
        {
          content: "Gst Interest",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-94",
      cells: [
        {
          content: "f",
          class: "",
        },
        {
          content: "Office Expenses",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-95",
      cells: [
        {
          content: "g",
          class: "",
        },
        {
          content: "Store renovation Expenses",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-96",
      cells: [
        {
          content: "h",
          class: "",
        },
        {
          content: "Bad debts",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-97",
      cells: [
        {
          content: "i",
          class: "",
        },
        {
          content: "Rent",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-98",
      cells: [
        {
          content: "j",
          class: "",
        },
        {
          content: "Electricity Exp",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-99",
      cells: [
        {
          content: "k",
          class: "",
        },
        {
          content: "Carriages Outward",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-100",
      cells: [
        {
          content: "l",
          class: "",
        },
        {
          content: "Puja Expenses",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-101",
      cells: [
        {
          content: "m",
          class: "",
        },
        {
          content: "Other indirect expenses",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },

    {
      id: "row-102",
      cells: [
        {
          content: ".",
          colspan: 4,
          class: "trans-text topBd ",
        },
      ],
    },
  ],
  // new
};
export default plData12;
