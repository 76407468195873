const horizentalProfitLossData = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "NAME OF THE ASSESSEE",
          class: "no-border-r free",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        { content: "PAN", class: "no-border-r free" },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
      ],
    },
    {
      id: "row-2",
      cells: [
        { content: "REGD ADDRESS", class: "no-border-r free" },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        { content: "UNIT ADDRESS", class: "no-border-r free" },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
        {
          content: "",
          isUserProvided: false,
          class: "no-border",
        },
      ],
    },
    {
      id: "row-4",
      cells: [
        {
          content: "STATEMENT OF PROFIT AND LOSS ACCOUNT",
          colspan: 2,
          class: "bold newHead",
        },
        {
          content: "Amount in (Rs)",
          colspan: 3,
          class: "bold",
        },
      ],
    },
    {
      id: "row-5",
      cells: [
        { content: "S. NO", class: "bold" },
        { content: "Particulars", class: "bold" },
        { content: "Note", class: "bold" },
        { content: "Current Period", class: "bold" },
        { content: "Preceding Period", class: "bold" },
      ],
    },

    {
      id: "row-6",
      cells: [
        { content: "I", class: "bold" },
        { content: "Revenue from operations", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-7",
      cells: [
        { content: "II", class: "bold" },
        { content: "Other Income", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-8",
      cells: [
        { content: "III", class: "bold" },
        { content: "Total Income (I+II)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-9",
      cells: [
        { content: "IV", class: "bold" },
        { content: "Expenses:", class: "bold" },
        { content: "." },
        { content: "." },
        { content: "." },
      ],
    },
    {
      id: "row-10",
      cells: [
        { content: "(a)" },
        { content: "Cost of goods sold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-11",
      cells: [
        { content: "(b)" },
        { content: "Employee benefits expense" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-12",
      cells: [
        { content: "(c)" },
        { content: "Finance costs" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-13",
      cells: [
        { content: "(d)" },
        { content: "Depreciation and amortization expense" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-14",
      cells: [
        { content: "(e)" },
        { content: "Other expenses" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-15",
      cells: [
        { content: "." },
        { content: "Total expenses", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    // from hear stated new one v
    {
      id: "row-16",
      cells: [
        { content: "V", class: "bold" },
        {
          content:
            "Profit/(loss) before exceptional and extraordinary items and tax (III- IV)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-17",
      cells: [
        { content: "VI" },
        {
          content:
            "Exceptional items (specify nature & provide note/delete if none)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-18",
      cells: [
        { content: "VII" },
        {
          content: "Profit/(loss) before extraordinary items and tax (V-VI)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-19",
      cells: [
        { content: "VIII" },
        {
          content:
            "Extraordinary Items (specify nature & provide note/delete if none)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-20",
      cells: [
        { content: "IX" },
        {
          content: "Profit before tax (VII-VIII)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-21",
      cells: [
        { content: "X" },
        { content: "Tax expense:", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-22",
      cells: [
        { content: "(a)" },
        { content: "Current tax" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-23",
      cells: [
        { content: "(b)" },
        { content: "Excess/ Short provision of tax relating to earlier years" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-24",
      cells: [
        { content: "(c)" },
        { content: "Deferred tax charge/ (benefit)" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-25",
      cells: [
        { content: "." },
        { content: "." },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-26",
      cells: [
        { content: "." },
        { content: "." },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    // hellos

    {
      id: "row-27",
      cells: [
        { content: "XI", class: "bold" },
        {
          content:
            "Profit/(Loss) for the period from continuing operations (IX-X)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    //    XII stated
    {
      id: "row-28",
      cells: [
        { content: "XII" },
        { content: "Profit/(loss) from discontinuing operations" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-29",
      cells: [
        { content: "XIII" },
        {
          content: "Tax expense of discontinuing operations",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-30",
      cells: [
        { content: "XIV", class: "bold" },
        {
          content:
            "Profit/(loss) from discontinuing operations (after tax) (XII-XIII)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-31",
      cells: [
        { content: "XV", class: "bold" },
        {
          content: "Profit/(Loss) for the year (XI+XIV)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-32",
      cells: [
        { content: "", isUserProvided: false },
        {
          content:
            "The accompanying notes are an integral part of the  financial statements",
          class: "bold",
        },
        { content: "." },
        { content: "." },
        { content: "." },
      ],
    },
    {
      id: "row-33",
      cells: [
        { content: "." },
        {
          content: ".",
        },
        { content: "." },
        { content: "." },
        { content: "." },
      ],
    },
    {
      id: "row-34",
      cells: [
        {
          content: "FOR:",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-35",
      cells: [
        {
          content: "CHARTERED ACCOUNTANTS",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },

    {
      id: "row-36",
      cells: [
        {
          content: "CA",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-37",
      cells: [
        { content: "Partner", colspan: 1, class: "no-border-r free" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "Partner", class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-38",
      cells: [
        {
          content: "M.NO:",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-39",
      cells: [
        {
          content: "FRN:",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-40",
      cells: [
        {
          content: "UDIN:",
          colspan: 1,
          class: "no-border-r free",
          includeInput: true,
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
    {
      id: "row-41",
      cells: [
        {
          content: "Date:",
          colspan: 1,
          class: "no-border-r free",
        },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border" },
        { content: "Partner", class: "no-border" },
        { content: "", isUserProvided: false, class: "no-border-l" },
      ],
    },
  ],
};
export default horizentalProfitLossData;
