import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import axios from "axios";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltH, faArrowsAltV } from "@fortawesome/free-solid-svg-icons";
import { FaUndo, FaPlus, FaMinus, FaTrashAlt, FaSave } from "react-icons/fa";
import multiTableCopy from "../CelCopyData/multiTableCopy";
import singleTableCopy from "../CelCopyData/singleTableCopy";
import calTableCopy from "../CelCopyData/calTableCopy";
import Calculator from "../components/Calculator";
import complexCalTable from "../CelCopyData/complexCalTable";
import multiCalculationTable from "../CelCopyData/multiCalculationTable";
import Popup from "../components/Popup";
import * as XLSX from "xlsx";
import { FaFileDownload, FaFilePdf } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";

const MyTable = () => {
  // Store dropdown selections in localStorage with table ID prefix
  const [dropdownSelections, setDropdownSelections] = useState(() => {
    const savedSelections = localStorage.getItem("dropdownSelections");
    return savedSelections ? JSON.parse(savedSelections) : {};
  });

  const [tableData, setTableData] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedCell, setSelectedCell] = useState({
    rowIndex: 0,
    cellIndex: 0,
  });
  const [contextMenu, setContextMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  const [undoStack, setUndoStack] = useState([]);
  const { id } = useParams();
  const [relatedTables, setRelatedTables] = useState([]);
  const [viewMode, setViewMode] = useState("horizontal");
  const [showDropdown, setShowDropdown] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [baseTableName, setBaseTableName] = useState(null);
  const [lastCopiedValue, setLastCopiedValue] = useState(null); // Track the last copied value
  const [showRowNumbers, setShowRowNumbers] = useState(false); // State to toggle row numbers
  const handleMoveToFolderList = () => {
    navigate("/tables");
  };

  const handleSave = () => {
    saveTableData();
    setShowSuccess(true); // Show success message
    setTimeout(() => setShowSuccess(false), 3000); // Hide after 3 seconds
  };

  const handleDownloadPDF = () => {
    if (!tableData || !tableData.rows) return;

    const doc = new jsPDF("l", "mm", "a4");

    // Prepare table content without header row
    const tableContent = tableData.rows.slice(1).map((row) => {
      return row.cells.map((cell) => cell.content || "");
    });

    // Calculate optimal column widths based on content
    const maxColumns = Math.max(...tableContent.map((row) => row.length));
    const pageWidth = doc.internal.pageSize.width - 20; // Leave 10mm margin on each side
    const minColWidth = 20; // Minimum column width in mm

    // Calculate column widths based on content
    const columnWidths = Array(maxColumns)
      .fill(0)
      .map((_, colIndex) => {
        // Get max content length for this column
        const maxContentLength = Math.max(
          ...tableContent.map((row) => String(row[colIndex] || "").length)
        );
        // Convert length to mm (approximate - assuming 2mm per character)
        return Math.max(minColWidth, Math.min(maxContentLength * 2, 40)); // Cap at 40mm
      });

    // Adjust widths to fit page
    const totalWidth = columnWidths.reduce((sum, width) => sum + width, 0);
    if (totalWidth > pageWidth) {
      const scale = pageWidth / totalWidth;
      columnWidths.forEach((_, i) => {
        columnWidths[i] *= scale;
      });
    }

    // Calculate available height for table content
    const pageHeight = doc.internal.pageSize.height;
    const headerHeight = 15; // Height reserved for header
    const footerHeight = 15; // Height reserved for footer
    const availableHeight = pageHeight - headerHeight - footerHeight;

    // Calculate rows that will fit on each page
    const rowHeight = 8; // Approximate height per row in mm
    const maxRowsPerPage = Math.floor(availableHeight / rowHeight);

    // Split content into pages, ensuring no gaps between rows
    const pages = [];
    let currentPage = [];
    let currentHeight = 0;

    tableContent.forEach((row) => {
      if (
        currentHeight + rowHeight > availableHeight &&
        currentPage.length > 0
      ) {
        pages.push(currentPage);
        currentPage = [row];
        currentHeight = rowHeight;
      } else {
        currentPage.push(row);
        currentHeight += rowHeight;
      }
    });

    if (currentPage.length > 0) {
      pages.push(currentPage);
    }

    pages.forEach((pageContent, pageIndex) => {
      if (pageIndex > 0) {
        doc.addPage();
      }

      // Add minimal header with table name and date
      const timestamp = new Date().toLocaleDateString();
      doc.setFontSize(12);
      doc.setFont(undefined, "bold");
      doc.text(tableData.name || "Table Data", 15, 10);
      doc.text(timestamp, doc.internal.pageSize.width - 30, 10);

      // Generate table
      doc.autoTable({
        body: pageContent,
        startY: headerHeight,
        styles: {
          fontSize: 10,
          fontStyle: "bold",
          cellPadding: 3,
          lineColor: [200, 200, 200],
          lineWidth: 0.1,
          overflow: "linebreak",
          cellWidth: "wrap",
          minCellHeight: 8,
          halign: "left",
        },
        columnStyles: columnWidths.reduce((acc, width, index) => {
          acc[index] = {
            cellWidth: width,
            halign: "left",
            valign: "middle",
          };
          return acc;
        }, {}),
        theme: "grid",
        margin: {
          top: headerHeight,
          left: 10,
          right: 10,
          bottom: footerHeight,
        },
        didDrawPage: (data) => {
          const pageSize = doc.internal.pageSize;
          doc.setFontSize(10);
          doc.setTextColor(100, 100, 100);
          doc.text(
            `Page ${pageIndex + 1} of ${pages.length}`,
            pageSize.width / 2,
            pageSize.height - 10,
            { align: "center" }
          );
        },
        didDrawCell: (data) => {
          if (data.cell.text.length > 0) {
            const fontSize = data.cell.styles.fontSize;
            const cellWidth = data.cell.styles.cellWidth;
            const textWidth =
              doc.getStringUnitWidth(data.cell.text[0]) * fontSize;

            if (textWidth > cellWidth) {
              data.cell.styles.fontSize = Math.floor(
                fontSize * (cellWidth / textWidth)
              );
            }
          }
        },
      });
    });

    // Save with timestamp
    const fileName = `${tableData.name || "export"}_${
      new Date().toISOString().split("T")[0]
    }.pdf`;
    doc.save(fileName);
  };

  const handleDownload = () => {
    if (!tableData || !tableData.rows) return;

    // Convert table data to Excel format
    const ws = XLSX.utils.aoa_to_sheet(
      tableData.rows.map((row) => row.cells.map((cell) => cell.content || ""))
    );

    // Add styling to the worksheet
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let R = range.s.r; R <= range.e.r; R++) {
      for (let C = range.s.c; C <= range.e.c; C++) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);

        if (!ws[cell_ref]) continue;

        // Add cell styling
        ws[cell_ref].s = {
          font: {
            name: "Arial",
            sz: 12,
            color: { rgb: "000000" },
          },
          alignment: {
            vertical: "center",
            horizontal: "left",
            wrapText: true, // Enable text wrapping
          },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };

        // Style header row
        if (R === 0) {
          ws[cell_ref].s.fill = {
            fgColor: { rgb: "FFB402" },
            type: "pattern",
            patternType: "solid",
          };
          ws[cell_ref].s.font.bold = true;
        }
      }
    }

    // Set dynamic column widths based on content
    const colWidths = [];
    for (let C = range.s.c; C <= range.e.c; C++) {
      let maxWidth = 10; // Minimum width
      for (let R = range.s.r; R <= range.e.r; R++) {
        const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });
        if (ws[cell_ref] && ws[cell_ref].v) {
          const contentLength = String(ws[cell_ref].v).length;
          maxWidth = Math.max(maxWidth, Math.min(contentLength, 40)); // Cap at 40 characters
        }
      }
      colWidths.push({ wch: maxWidth });
    }
    ws["!cols"] = colWidths;

    // Create workbook and append worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Table Data");

    // Generate filename from table name with timestamp
    const timestamp = new Date().toISOString().split("T")[0];
    const fileName = `${tableData.name || "table-data"}_${timestamp}.xlsx`;

    // Save file
    XLSX.writeFile(wb, fileName);
  };

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const saveTableData = useCallback(
    (updatedData = tableData, tableId = id) => {
      if (!updatedData) return;
      axios
        .put(`https://vertical.lblfm.com/api/tabledata/${tableId}`, updatedData)
        .then((response) => console.log("Table data saved successfully"))
        .catch((error) => console.error("Error saving table data:", error));
    },
    [id, tableData]
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, [navigate]);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setLoadingError(null);

      try {
        const response = await axios.get(
          `https://vertical.lblfm.com/api/tabledata/${id}`
        );
        setTableData(response.data);

        // Load saved dropdown selections for this table
        const savedSelections = localStorage.getItem(
          `dropdownSelections_${id}`
        );
        if (savedSelections) {
          setDropdownSelections(JSON.parse(savedSelections));
        }
      } catch (error) {
        console.error("API Error:", error);
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            setLoadingError("Unauthorized access. Please log in again.");
            navigate("/login");
          } else {
            setLoadingError(
              `API Error: ${error.response.data.message || "An error occurred"}`
            );
          }
        } else {
          setLoadingError("Error occurred while setting up the request.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, navigate]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (tableData && tableData.rows) {
        const { key } = event;
        let { rowIndex, cellIndex } = selectedCell;
        const maxRowIndex = tableData.rows.length - 1;
        const maxCellIndex = tableData.rows[rowIndex]?.cells.length - 1 || 0;

        switch (key) {
          case "ArrowUp":
            rowIndex = rowIndex > 0 ? rowIndex - 1 : rowIndex;
            break;
          case "ArrowDown":
            rowIndex = rowIndex < maxRowIndex ? rowIndex + 1 : rowIndex;
            break;
          case "ArrowLeft":
            cellIndex = cellIndex > 0 ? cellIndex - 1 : cellIndex;
            break;
          case "ArrowRight":
            cellIndex = cellIndex < maxCellIndex ? cellIndex + 1 : cellIndex;
            break;
          case "Enter":
            rowIndex = rowIndex < maxRowIndex ? rowIndex + 1 : rowIndex;
            break;
          default:
            return;
        }

        setSelectedCell({ rowIndex, cellIndex });
        setTimeout(() => {
          const inputElement = document.querySelector(
            `[data-row="${rowIndex}"][data-cell="${cellIndex}"] input`
          );
          if (inputElement) {
            inputElement.focus();
          }
        }, 0);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedCell, tableData]);

  const formatValue = (value) => {
    if (!isNaN(value) && value.trim() !== "") {
      return Number(value.replace(/,/g, "")).toLocaleString();
    }
    return value;
  };
  const handleInputChange = (rowIndex, cellIndex, value) => {
    if (!tableData || !tableData.rows[rowIndex]) return;
    const currentCellContent =
      tableData.rows[rowIndex].cells[cellIndex].content;

    const startsWithNumbers = /^\d{2}/.test(value);
    if (startsWithNumbers && value.match(/[A-Za-z]/)) {
      return;
    }

    const formattedValue = formatValue(value);

    // Only update if value actually changed
    if (currentCellContent !== formattedValue) {
      setTableData((prevTableData) => {
        const newRows = [...prevTableData.rows];
        newRows[rowIndex].cells[cellIndex] = {
          ...newRows[rowIndex].cells[cellIndex],
          content: formattedValue,
          isUserProvided: true,
        };
        return { ...prevTableData, rows: newRows };
      });

      if (formattedValue !== lastCopiedValue) {
        setLastCopiedValue(formattedValue);
        copyValueBetweenTables();
      }

      // Only save when data actually changes
      saveTableData();
    }
  };

  const handleClick = (rowIndex, cellIndex) => {
    setSelectedCell({ rowIndex, cellIndex });
    setTimeout(() => {
      document
        .querySelector(
          `[data-row="${rowIndex}"][data-cell="${cellIndex}"] input`
        )
        ?.focus();
    }, 0);
  };

  const createCell = (tag, cellData, rowIndex, cellIndex) => {
    const CellTag = tag;
    const isSelected =
      selectedCell.rowIndex === rowIndex &&
      selectedCell.cellIndex === cellIndex;
    const isEditable =
      (cellData.content === "0" ||
        cellData.content === "" ||
        cellData.isUserProvided) &&
      !cellData.isCopied; // Ensure copied cells are not editable

    return isEditable ? (
      <CellTag
        key={`cell-${rowIndex}-${cellIndex}`}
        colSpan={cellData.colspan}
        rowSpan={cellData.rowspan}
        className={`${cellData.class} ${isSelected ? "selected-cell" : ""}`}
        data-row={rowIndex}
        data-cell={cellIndex}
        onClick={() => handleClick(rowIndex, cellIndex)}
      >
        <input
          type="text"
          placeholder={cellData.content === "0" ? "0" : ""}
          value={cellData.content === "0" ? "" : cellData.content}
          onFocus={(e) => e.target.select()}
          onChange={(e) =>
            handleInputChange(rowIndex, cellIndex, e.target.value)
          }
          autoFocus={isSelected}
          tabIndex={0}
          readOnly={cellData.isCopied} // Make the cell read-only if it is copied
        />
      </CellTag>
    ) : (
      <CellTag
        key={`cell-${rowIndex}-${cellIndex}`}
        colSpan={cellData.colspan}
        rowSpan={cellData.rowspan}
        className={`${cellData.class} ${isSelected ? "selected-cell" : ""}`}
        dangerouslySetInnerHTML={
          cellData.content ? { __html: cellData.content } : null
        }
        data-row={rowIndex}
        data-cell={cellIndex}
        onClick={() => handleClick(rowIndex, cellIndex)}
        tabIndex={0}
      />
    );
  };
  // hear the drop down will start
  const handleDropdownChange = (rowIndex, cellIndex, value) => {
    // Update state with table ID prefix
    const newSelections = {
      ...dropdownSelections,
      [`${rowIndex}-${cellIndex}`]: value,
    };
    setDropdownSelections(newSelections);

    // Save selections with table ID prefix
    localStorage.setItem(
      `dropdownSelections_${id}`,
      JSON.stringify(newSelections)
    );

    // Update table data for display in Row-2 only if user explicitly changes selection
    setTableData((prevTableData) => {
      const newRows = [...prevTableData.rows];
      newRows[rowIndex + 1].cells[cellIndex] = {
        ...newRows[rowIndex + 1].cells[cellIndex],
        content: value,
      };
      return { ...prevTableData, rows: newRows };
    });
  };

  // Example for creating cells with dropdowns
  const createDropdownCell = (cellData, rowIndex, cellIndex) => (
    <td key={`cell-${rowIndex}-${cellIndex}`} className={cellData.class}>
      <select
        value={
          dropdownSelections[`${rowIndex}-${cellIndex}`] || cellData.content
        }
        onChange={(e) =>
          handleDropdownChange(rowIndex, cellIndex, e.target.value)
        }
      >
        {cellData.options.map((option, idx) => (
          <option key={idx} value={option}>
            {option}
          </option>
        ))}
      </select>
    </td>
  );
  const createRow = (rowData, rowIndex) => {
    return (
      <tr
        key={`row-${rowIndex}`}
        className={`text-center bordered-row ${
          contextMenu?.rowIndex === rowIndex ? "highlighted-row" : ""
        }`}
        onContextMenu={(e) => handleContextMenu(e, rowIndex)}
      >
        {rowData.cells.map((cellData, cellIndex) => (
          <React.Fragment key={`fragment-${rowIndex}-${cellIndex}`}>
            {cellData.type === "dropdown"
              ? createDropdownCell(cellData, rowIndex, cellIndex)
              : createCell("td", cellData, rowIndex, cellIndex)}
          </React.Fragment>
        ))}
        {showRowNumbers && <td className="row-number">{rowData.id}</td>}
        {/* Show row ID instead of index */}
      </tr>
    );
  };

  const handleContextMenu = (event, rowIndex) => {
    event.preventDefault();
    const cell = event.target.closest("td, th");
    if (!cell) return;
    const rect = cell.getBoundingClientRect();

    setContextMenu({
      rowIndex,
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
  };

  const handleContextMenuAction = (action) => {
    const { rowIndex } = contextMenu;
    const newRows = [...tableData.rows];

    setUndoStack((prevUndoStack) => [
      ...prevUndoStack,
      JSON.stringify(tableData.rows),
    ]);

    let dataChanged = false;

    if (action === "addAbove") {
      newRows.splice(rowIndex, 0, {
        cells: newRows[rowIndex].cells.map((cell) => ({ content: "" })),
        id: `row-${newRows.length + 1}`,
      });
      dataChanged = true;
    } else if (action === "addBelow") {
      newRows.splice(rowIndex + 1, 0, {
        cells: newRows[rowIndex].cells.map((cell) => ({ content: "" })),
        id: `row-${newRows.length + 1}`,
      });
      dataChanged = true;
    } else if (action === "removeAbove") {
      if (rowIndex > 0) {
        newRows.splice(rowIndex - 1, 1);
        dataChanged = true;
      }
    } else if (action === "removeBelow") {
      if (rowIndex < newRows.length - 1) {
        newRows.splice(rowIndex + 1, 1);
        dataChanged = true;
      }
    }

    if (dataChanged) {
      setTableData({ ...tableData, rows: newRows });
      saveTableData();
    }

    setContextMenu(null);
  };

  const handleUndo = () => {
    setUndoStack((prevUndoStack) => {
      if (prevUndoStack.length > 0) {
        const lastState = prevUndoStack[prevUndoStack.length - 1];
        const newRows = JSON.parse(lastState);

        setTableData((prevTableData) => ({
          ...prevTableData,
          rows: newRows,
        }));

        // Only save if rows actually changed
        if (JSON.stringify(tableData.rows) !== lastState) {
          saveTableData();
        }

        return prevUndoStack.slice(0, -1);
      }
      return prevUndoStack;
    });
  };
  const handleAddRow = (rowIndex) => {
    setUndoStack((prevUndoStack) => [
      ...prevUndoStack,
      JSON.stringify(tableData.rows),
    ]);

    setTableData((prevTableData) => {
      const newRows = [...prevTableData.rows];
      newRows.splice(rowIndex + 1, 0, {
        cells: newRows[rowIndex].cells.map((cell) => ({
          content: "",
        })),
        id: `${rowIndex}.1`,
      });

      // Save after adding row
      setTimeout(() => saveTableData(), 0);

      return { ...prevTableData, rows: newRows };
    });
  };

  const handleRemoveRow = (rowIndex) => {
    setUndoStack((prevUndoStack) => [
      ...prevUndoStack,
      JSON.stringify(tableData.rows),
    ]);

    setTableData((prevTableData) => {
      const newRows = prevTableData.rows.filter(
        (_, index) => index !== rowIndex
      );

      // Save after removing row
      setTimeout(() => saveTableData(), 0);

      return { ...prevTableData, rows: newRows };
    });
  };

  const removeContextMenu = () => {
    setContextMenu(null);
  };

  useEffect(() => {
    const fetchTable = async () => {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      try {
        const tableResponse = await axios.get(
          `https://vertical.lblfm.com/api/tabledata/${id}`
        );
        setBaseTableName(tableResponse.data.baseTableName);
        const relatedResponse = await axios.get(
          `https://vertical.lblfm.com/api/tabledata`
        );
        const related = relatedResponse.data.filter(
          (t) => t.baseTableName === tableResponse.data.baseTableName
        );
        setRelatedTables(related);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };
    fetchTable();
  }, [id]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        try {
          const response = await axios.get(
            "https://vertical.lblfm.com/api/userdetails"
          );
          setUserDetails(response.data);
        } catch (error) {
          console.error("Error fetching user details:", error);
          navigate("/login");
        } finally {
          setLoadingUser(false);
        }
      } else {
        navigate("/login");
      }
    };
    fetchUserDetails();
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    const savedViewMode = localStorage.getItem("viewMode");
    if (savedViewMode) {
      setViewMode(savedViewMode);
    }
  }, []);

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    localStorage.setItem("viewMode", mode);
  };

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const filteredTables = relatedTables.filter((t) => {
    const name = t.name ? t.name.toLowerCase() : "";
    const isViewModeMatch = name.includes(viewMode);
    const isSpecificTable = name !== `create table-${viewMode}-`;
    return isViewModeMatch && isSpecificTable;
  });

  const getDisplayName = (name) => {
    const parts = name ? name.split("-") : [];
    return parts.slice(-2).join(" ");
  };

  const getTableId = (name) => {
    const parts = name ? name.split("-") : [];
    return parts.slice(1).join("-");
  };

  const toggleRowNumbers = () => {
    setShowRowNumbers((prev) => !prev);
  };
  // perform calculation ------------------------------------------------------------------------------------------------
  const performCalculation = useCallback(() => {
    if (tableData && tableData.name) {
      let newTableData = { ...tableData, rows: [...tableData.rows] };
      let shouldUpdate = false;

      calTableCopy.calcutation.forEach((calculation) => {
        const { source, mapping } = calculation;

        if (tableData.name.startsWith(`${baseTableName}-${source}`)) {
          const { sourceRows, sourceCols, targetRows, targetCols, operation } =
            mapping;

          if (!sourceRows || !sourceCols || !targetRows || !targetCols) {
            return;
          }

          const values = sourceRows.map((rowId) => {
            const row = newTableData.rows.find((r) => r.id === rowId);
            if (!row) return 0;

            if (!row.cells) row.cells = [];
            while (row.cells.length <= Math.max(...sourceCols)) {
              row.cells.push({ content: "0" });
            }

            return sourceCols.reduce((sum, colIndex) => {
              const cellValue = parseFloat(
                row.cells[colIndex]?.content?.replace(/,/g, "") || "0"
              );
              return sum + cellValue;
            }, 0);
          });

          let result = 0;
          switch (operation) {
            case "sum":
              result = values.reduce((acc, curr) => acc + curr, 0);
              break;
            case "mul":
              result = values.reduce((acc, curr) => acc * curr, 1);
              break;
            case "subtract":
              result = values.reduce((acc, curr) => acc - curr);
              break;
            case "divide":
              result = values.reduce((acc, curr) => acc / curr);
              break;
            case "percent":
              result = (values[0] / values[1]) * 100;
              break;
            default:
              return;
          }

          targetRows.forEach((targetRowId) => {
            const targetRow = newTableData.rows.find(
              (r) => r.id === targetRowId
            );
            if (targetRow) {
              if (!targetRow.cells) targetRow.cells = [];
              while (targetRow.cells.length <= Math.max(...targetCols)) {
                targetRow.cells.push({ content: "0" });
              }

              targetCols.forEach((targetColIndex) => {
                const oldContent = targetRow.cells[targetColIndex]?.content;
                const newContent = result.toString();

                if (oldContent !== newContent) {
                  targetRow.cells[targetColIndex] = { content: newContent };
                  shouldUpdate = true;
                }
              });
            }
          });
        }
      });

      if (shouldUpdate) {
        setTableData(newTableData);
        saveTableData();
      }
    }
  }, [tableData, baseTableName, saveTableData]); // Added saveTableData to dependencies
  // from hear the multi table calculation will start ------------------------------------------------------------------------------------------------
  const multiCalculation = useCallback(() => {
    if (tableData && tableData.name) {
      let newTableData = { ...tableData, rows: [...tableData.rows] };
      let shouldUpdate = false;

      multiCalculationTable.calculations.forEach((calculation) => {
        const { source, mapping } = calculation;

        if (tableData.name.startsWith(`${baseTableName}-${source}`)) {
          const { steps, targetRows, targetCols } = mapping;

          if (!steps || !targetRows || !targetCols) {
            return;
          }

          // Initialize result based on the first operation in steps (either sum or product)
          let result = steps[0].operation === "mul" ? 1 : 0;

          // Process each step in sequence
          steps.forEach((step) => {
            const { operation, sourceRows, sourceCols } = step;

            // Retrieve values from source rows and columns for this step
            const stepValues = sourceRows.map((rowId) => {
              const row = newTableData.rows.find((r) => r.id === rowId);
              if (!row) return 0;

              if (!row.cells) row.cells = [];
              while (row.cells.length <= Math.max(...(sourceCols || [0]))) {
                row.cells.push({ content: "0" });
              }

              return (sourceCols || [0]).reduce((sum, colIndex) => {
                const cellValue = parseFloat(
                  row.cells[colIndex]?.content?.replace(/,/g, "") || "0"
                );
                return sum + cellValue;
              }, 0);
            });

            // Apply the operation based on the step type
            switch (operation) {
              case "sum":
                result += stepValues.reduce((acc, val) => acc + val, 0);
                break;
              case "subtract":
                result -= stepValues.reduce((acc, val) => acc + val, 0);
                break;
              case "mul":
                result *= stepValues.reduce((acc, val) => acc * val, 1);
                break;
              case "divide":
                const divisor = stepValues.reduce((acc, val) => acc * val, 1);
                if (divisor !== 0) {
                  result /= divisor;
                }
                break;
              default:
                return;
            }
          });

          // Set the result in target cells
          targetRows.forEach((targetRowId) => {
            const targetRow = newTableData.rows.find(
              (r) => r.id === targetRowId
            );
            if (targetRow) {
              if (!targetRow.cells) targetRow.cells = [];
              while (targetRow.cells.length <= Math.max(...targetCols)) {
                targetRow.cells.push({ content: "0" });
              }

              targetCols.forEach((targetColIndex) => {
                const oldContent = targetRow.cells[targetColIndex]?.content;
                const newContent = result.toString();

                if (oldContent !== newContent) {
                  targetRow.cells[targetColIndex] = { content: newContent };
                  shouldUpdate = true;
                }
              });
            }
          });
        }
      });

      if (shouldUpdate) {
        setTableData(newTableData);
        saveTableData();
      }
    }
  }, [tableData, baseTableName, saveTableData]);

  // end of multi table calculation ------------------------------------------------------------------------------------------------

  //  complex calculation adition ------------------------------------------------------------------------------------------------
  // Utility functions for calculation safety
  // Utility functions for calculation safety
  const safeParseFloat = (value) => {
    if (typeof value === "number") return value;
    const cleaned = String(value).replace(/,/g, "").trim();
    const parsed = parseFloat(cleaned);
    return isNaN(parsed) ? 0 : parsed;
  };

  const toPercentageDecimal = (value) => safeParseFloat(value) / 100;

  const formatNumber = (num) => {
    if (typeof num !== "number" || isNaN(num)) return "0";
    return num.toString();
  };

  // Complex calculation hook
  const useComplexCalculations = ({ tableData, baseTableName, onSave }) => {
    // Memoized function to get cell value
    const getCellValue = useMemo(() => {
      return (rows, rowId, colIndex) => {
        const row = rows.find((r) => r.id === rowId);
        if (!row?.cells?.[colIndex]) return 0;
        return safeParseFloat(row.cells[colIndex].content);
      };
    }, []);

    // Calculate result based on dynamic formula with optional rows
    const calculateResult = useCallback(
      (rows, sourceValues, optionalRows = []) => {
        try {
          const [baseRow, percentageRow, finalRow, ...additionalRows] =
            sourceValues;
          const percentageDecimal = toPercentageDecimal(percentageRow);
          let result = 0;

          // Calculate for all optional rows first (if any)
          additionalRows.forEach((rowValue) => {
            result += rowValue * percentageDecimal;
          });

          // Add base calculation (row6 * row2%)
          result += baseRow * percentageDecimal;

          // Add final part (row8 * (row2/2)%)
          const halfPercentageDecimal = toPercentageDecimal(percentageRow / 2);
          result += finalRow * halfPercentageDecimal;

          return {
            success: true,
            result: result,
            intermediateResults: {
              baseCalculation: baseRow * percentageDecimal,
              optionalCalculations: additionalRows.map(
                (row) => row * percentageDecimal
              ),
              finalCalculation: finalRow * halfPercentageDecimal,
            },
          };
        } catch (error) {
          return {
            success: false,
            result: 0,
          };
        }
      },
      []
    );

    // Main calculation handler
    const performComplexCalculation = useCallback(() => {
      if (!tableData?.name || !tableData.rows) {
        return;
      }

      // Filter and iterate through all relevant configurations for this table
      const relevantConfigs = complexCalTable.calculation.filter((c) =>
        tableData.name.startsWith(`${baseTableName}-${c.source}`)
      );

      if (relevantConfigs.length === 0) {
        return;
      }

      // Loop through each config and perform calculations
      relevantConfigs.forEach((configEntry) => {
        const config = {
          sourceRowIds: configEntry.mapping.sourceRows,
          sourceColIndex: configEntry.mapping.sourceCols[0],
          targetRowId: configEntry.mapping.targetRows[0],
          targetColIndex: configEntry.mapping.targetCols[0],
        };

        // Get source values
        const sourceValues = config.sourceRowIds.map((rowId) =>
          getCellValue(tableData.rows, rowId, config.sourceColIndex)
        );

        // Perform calculation
        const { success, result } = calculateResult(
          tableData.rows,
          sourceValues
        );

        if (!success) {
          return;
        }

        // Update target cell if value has changed
        const newTableData = { ...tableData, rows: [...tableData.rows] };
        const targetRow = newTableData.rows.find(
          (r) => r.id === config.targetRowId
        );

        if (targetRow) {
          const formattedResult = formatNumber(result);
          const currentValue =
            targetRow.cells?.[config.targetColIndex]?.content;

          if (currentValue !== formattedResult) {
            if (!targetRow.cells) targetRow.cells = [];
            targetRow.cells[config.targetColIndex] = {
              content: formattedResult,
            };
            onSave(newTableData);
          }
        }
      });
    }, [tableData, baseTableName, getCellValue, calculateResult, onSave]);

    return {
      performComplexCalculation,
    };
  };

  // Copy values from one mapping to another
  const copyValuesFromMapping = useCallback(() => {
    if (tableData && tableData.name) {
      let shouldUpdate = false;

      singleTableCopy.targets.forEach((target) => {
        if (tableData.name.startsWith(`${baseTableName}-${target.source}`)) {
          const mapping = target.mapping;

          mapping.sourceRows.forEach((sourceRow, rowIndex) => {
            const sourceRowData = tableData.rows.find(
              (row) => row.id === sourceRow
            );
            const targetRowData = tableData.rows.find(
              (row) => row.id === mapping.targetRows[rowIndex]
            );

            if (sourceRowData && targetRowData) {
              if (!sourceRowData.cells) sourceRowData.cells = [];
              if (!targetRowData.cells) targetRowData.cells = [];

              while (
                sourceRowData.cells.length <= Math.max(...mapping.sourceCols)
              ) {
                sourceRowData.cells.push({ content: "" });
              }
              while (
                targetRowData.cells.length <= Math.max(...mapping.targetCols)
              ) {
                targetRowData.cells.push({ content: "" });
              }

              mapping.sourceCols.forEach((sourceCol, colIndex) => {
                const targetCol = mapping.targetCols[colIndex];
                const sourceCell = sourceRowData.cells[sourceCol];
                const targetCell = targetRowData.cells[targetCol];

                if (
                  sourceCell &&
                  targetCell &&
                  sourceCell.content !== targetCell.content
                ) {
                  targetCell.content = sourceCell.content;
                  targetCell.isCopied = true; // Mark the cell as copied
                  targetCell.isEditable = false; // Make the cell non-editable
                  shouldUpdate = true;
                }
              });
            }
          });
        }
      });

      if (shouldUpdate) {
        setTableData({ ...tableData });
        saveTableData();
      }
    }
  }, [tableData, baseTableName, saveTableData]); // Added saveTableData to dependencies
  // end of copy values from one mapping to another ------------------------------------------------------------------------------------------------
  const copyContentSafely = (sourceContent, targetContent) => {
    if (sourceContent && typeof sourceContent === "string") {
      return sourceContent.trim();
    }
    return targetContent || "";
  };

  const copyValueBetweenTables = useCallback(() => {
    if (!tableData || !baseTableName) return;

    // Function to find matching target configurations for a given source table
    const findMatchingTargets = (sourceTableName) => {
      return multiTableCopy.targets.filter((target) => {
        const sourcePattern = `${baseTableName}-${target.source}`;
        return sourceTableName.startsWith(sourcePattern);
      });
    };

    // Check if current table is a source table for any mapping
    const matchingTargets = findMatchingTargets(tableData.name);

    if (matchingTargets.length === 0) return;

    setTimeout(() => {
      const updatedTables = relatedTables.map((targetTable) => {
        let tableChanged = false;
        const newTable = { ...targetTable };

        // Process each matching target configuration
        matchingTargets.forEach((targetConfig) => {
          const targetPattern = `${baseTableName}-${targetConfig.target}`;

          if (targetTable.name.startsWith(targetPattern)) {
            targetConfig.mappings.forEach((mapping) => {
              if (
                Array.isArray(mapping.sourceRows) &&
                Array.isArray(mapping.sourceCols) &&
                Array.isArray(mapping.targetRows) &&
                Array.isArray(mapping.targetCols) &&
                mapping.sourceRows.length === mapping.targetRows.length &&
                mapping.sourceCols.length === mapping.targetCols.length
              ) {
                mapping.sourceRows.forEach((sourceRowId, rowIndex) => {
                  const targetRowId = mapping.targetRows[rowIndex];
                  const sourceRow = tableData.rows.find(
                    (row) => row.id === sourceRowId
                  );
                  const targetRow = newTable.rows.find(
                    (row) => row.id === targetRowId
                  );

                  if (sourceRow && targetRow) {
                    // Initialize cells arrays if they don't exist
                    sourceRow.cells = sourceRow.cells || [];
                    targetRow.cells = targetRow.cells || [];

                    // Ensure arrays are long enough
                    const maxSourceCol = Math.max(...mapping.sourceCols);
                    const maxTargetCol = Math.max(...mapping.targetCols);

                    while (sourceRow.cells.length <= maxSourceCol) {
                      sourceRow.cells.push({ content: "" });
                    }
                    while (targetRow.cells.length <= maxTargetCol) {
                      targetRow.cells.push({ content: "" });
                    }

                    // Copy values for each column mapping
                    mapping.sourceCols.forEach((sourceColIndex, colIndex) => {
                      const targetColIndex = mapping.targetCols[colIndex];
                      const sourceContent =
                        sourceRow.cells[sourceColIndex]?.content;
                      const targetContent =
                        targetRow.cells[targetColIndex]?.content;
                      const newContent = copyContentSafely(
                        sourceContent,
                        targetContent
                      );

                      if (targetContent !== newContent) {
                        if (!targetRow.cells[targetColIndex]) {
                          targetRow.cells[targetColIndex] = {};
                        }

                        targetRow.cells[targetColIndex] = {
                          ...targetRow.cells[targetColIndex],
                          content: newContent,
                          isCopied: true,
                          isEditable: false,
                          sourceTable: tableData.name,
                          sourceRow: sourceRowId,
                          sourceCol: sourceColIndex,
                        };

                        tableChanged = true;
                      }
                    });
                  }
                });
              }
            });
          }
        });

        if (tableChanged) {
          saveTableData(newTable, targetTable._id);
          return newTable;
        }
        return targetTable;
      });

      setRelatedTables(updatedTables);
    }, 400);
  }, [tableData, baseTableName, relatedTables, saveTableData]);
  // Use the complex calculations hook
  const { performComplexCalculation } = useComplexCalculations({
    tableData,
    baseTableName,
    onSave: saveTableData, // Pass the save function
  });

  useEffect(() => {
    if (tableData) {
      multiCalculation();
      performCalculation();
      copyValuesFromMapping();
      performComplexCalculation(); // Call the complex calculation
    }
  }, [
    multiCalculation,
    performCalculation,
    copyValuesFromMapping,
    performComplexCalculation,
    tableData,
  ]);

  useEffect(() => {}, [relatedTables]);
  // Determine the active link based on the current location
  const isActiveLink = (path) => location.pathname === path;
  return (
    <div className="table-container" onClick={removeContextMenu}>
      {loading ? (
        <div className="loading">Loading...</div>
      ) : loadingError ? (
        <div className="error">{loadingError}</div>
      ) : (
        <>
          {/* Header Section */}
          <header className="headerMain">
            <div className="header">
              <div className="logo">MyApp</div>
              <nav className={`nav-links ${viewMode}`}>
                {filteredTables.map((t) => {
                  return (
                    <Link
                      key={t._id} // Use `t._id` instead of `t.id`
                      to={`/view-table/${t._id}`} // Use `t._id` here
                      className={`table-link ${
                        isActiveLink(`/view-table/${t._id}`) ? "active" : ""
                      }`}
                    >
                      {getDisplayName(t.name)}
                    </Link>
                  );
                })}
              </nav>

              <div className="userButton">
                <button className="open-calculator-btn" onClick={togglePopup}>
                  Open Calculator
                </button>

                <Popup show={showPopup} handleClose={togglePopup}>
                  <Calculator />
                </Popup>

                <button
                  className={`layout-toggle-button ${
                    viewMode === "vertical" ? "vertical" : "horizontal"
                  }`}
                  onClick={() =>
                    handleViewModeChange(
                      viewMode === "horizontal" ? "vertical" : "horizontal"
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      viewMode === "horizontal" ? faArrowsAltV : faArrowsAltH
                    }
                  />
                  {viewMode === "horizontal"
                    ? "Switch to Vertical"
                    : "Switch to Horizontal"}
                </button>
                <div className="user-profile" onClick={toggleDropdown}>
                  <FaUserCircle className="user-icon" />
                  {loadingUser ? (
                    <p>Loading...</p>
                  ) : (
                    <div
                      className={`user-dropdown ${showDropdown ? "show" : ""}`}
                      ref={dropdownRef}
                    >
                      {userDetails ? (
                        <>
                          <p>{userDetails.username}</p>
                          <p>{userDetails.email}</p>
                          <button onClick={handleLogout}>Logout</button>
                        </>
                      ) : (
                        <p>No user details</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* save */}

            <div className="headb">
              <h2>
                Editing Table Name: {tableData ? tableData.name : "Loading..."}
              </h2>
              <div className="d-flex justify-content-between mb-2 topbtn ">
                <button onClick={handleMoveToFolderList} className="Move-btn">
                  Move to Table Folder List
                </button>
                <div className="download-buttons">
                  <select
                    onChange={(e) => {
                      if (e.target.value === "excel") {
                        handleDownload();
                      } else if (e.target.value === "pdf") {
                        handleDownloadPDF();
                      }
                    }}
                    className="download-select"
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Download As
                    </option>
                    <option value="excel">Excel (.xlsx)</option>
                    <option value="pdf">PDF (.pdf)</option>
                  </select>
                </div>
                <button onClick={handleSave} className="btn btn-primary">
                  <FaSave />
                </button>
                <button onClick={handleUndo} className="btn btn-secondary">
                  <FaUndo />
                </button>
                <button
                  onClick={() => handleAddRow(selectedCell.rowIndex)}
                  className="btn btn-success"
                >
                  <FaPlus />
                </button>
                <button onClick={toggleRowNumbers} className="btn btn-info">
                  {showRowNumbers ? "Hide" : "Show"}
                </button>
              </div>
            </div>

            {showSuccess && (
              <div className="success-popup">Data saved successfully!</div>
            )}
          </header>

          <table
            className="table table-bordered"
            id={getTableId(tableData ? tableData.name : "")}
          >
            <tbody>
              {tableData &&
                tableData.rows.map((rowData, rowIndex) =>
                  createRow(rowData, rowIndex)
                )}
            </tbody>
          </table>
        </>
      )}
      {contextMenu && (
        <ul
          className="context-menu"
          style={{ top: contextMenu.top, left: contextMenu.left }}
        >
          <li onClick={() => handleContextMenuAction("addAbove")}>
            <FaPlus /> Add Row Above
          </li>
          <li onClick={() => handleContextMenuAction("addBelow")}>
            <FaPlus /> Add Row Below
          </li>
          <li onClick={() => handleContextMenuAction("removeAbove")}>
            <FaMinus /> Remove Row Above
          </li>
          <li onClick={() => handleContextMenuAction("removeBelow")}>
            <FaMinus /> Remove Row Below
          </li>
          <li onClick={() => handleRemoveRow(contextMenu.rowIndex)}>
            <FaTrashAlt /> Remove Selected Row
          </li>
        </ul>
      )}
    </div>
  );
};

export default MyTable;
