import React, { useState, useEffect } from "react";
import { evaluate } from "mathjs";
import "./Calculator.css";
import { FaSun, FaMoon, FaBackspace } from "react-icons/fa";

const Calculator = () => {
  const [input, setInput] = useState("");
  const [result, setResult] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [history, setHistory] = useState([]);

  const handleClick = (value) => {
    if (value === "(" && input[input.length - 1] !== "(") {
      setInput(input + value + ")");
    } else {
      setInput(input + value);
    }
  };

  const handleClear = () => {
    setInput("");
    setResult("");
  };

  const handleBackspace = () => {
    setInput(input.slice(0, -1));
  };

  const handleCalculate = () => {
    try {
      const evalResult = evaluate(input);
      setResult(evalResult.toString());
      setHistory([
        ...history,
        { expression: input, result: evalResult.toString() },
      ]);
    } catch (error) {
      setResult("Error");
    }
  };

  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleKeyDown = (event) => {
    if (!isNaN(event.key) || "+-*/%()".includes(event.key)) {
      handleClick(event.key);
    } else if (event.key === "Backspace") {
      handleBackspace();
    } else if (event.key === "Enter") {
      handleCalculate();
    } else if (event.key === "c" || event.key === "C") {
      handleClear();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [input]);

  return (
    <div className={`calculator ${darkMode ? "dark" : ""}`}>
      {/* <div className="toggle-mode">
        <button onClick={handleToggleDarkMode}>
          {darkMode ? <FaSun /> : <FaMoon />}
        </button>
      </div> */}

      <div className="display">
        <h1>Calculator</h1>
        <div className="input-wrapper">
          <input type="text" value={input} readOnly className="input-field" />
          <span className="blinking-cursor">|</span>
        </div>
        <div className="result">{result === "" ? "0" : result}</div>
      </div>
      <div className="buttons">
        <button onClick={() => handleClick("1")}>1</button>
        <button onClick={() => handleClick("2")}>2</button>
        <button onClick={() => handleClick("3")}>3</button>
        <button onClick={() => handleClick("+")}>+</button>

        <button onClick={() => handleClick("4")}>4</button>
        <button onClick={() => handleClick("5")}>5</button>
        <button onClick={() => handleClick("6")}>6</button>
        <button onClick={() => handleClick("-")}>-</button>

        <button onClick={() => handleClick("7")}>7</button>
        <button onClick={() => handleClick("8")}>8</button>
        <button onClick={() => handleClick("9")}>9</button>
        <button onClick={() => handleClick("*")}>*</button>

        <button onClick={handleClear}>C</button>
        <button onClick={() => handleClick("0")}>0</button>
        <button onClick={handleBackspace}>
          <FaBackspace />
        </button>
        <button onClick={() => handleClick("/")}>/</button>

        <button onClick={() => handleClick("%")}>%</button>
        <button onClick={() => handleClick("**")}>^</button>
        <button onClick={() => handleClick("sqrt(")}>√</button>

        <button onClick={handleCalculate} className="equal">
          =
        </button>
      </div>
      {/* <div className="history">
        <h3>Calculation History</h3>
        <ul>
          {history.map((item, index) => (
            <li key={index}>
              {item.expression} = {item.result}
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default Calculator;
