import React, { useState } from "react";
import axios from "axios";
import initialTableData1 from "../TableData/horizentalProfitLoss";
import initialTableData2 from "../TableData/hbs";
import initialTableData3 from "../TableData/Note1-3";
import initialTableData4 from "../TableData/NoteBs4-6";
import initialTableData5 from "../TableData/NoteBS7-10";
import initialTableData6 from "../TableData/NotesBs11";
import initialTableData7 from "../TableData/NoteBs12-18";
import initialTableData8 from "../TableData/NotePL1920";
import initialTableData9 from "../TableData/pl";
import initialTableData10 from "../TableData/bs";
import initialTableData11 from "../TableData/Annexures";
import initialTableData12 from "../TableData/AnnexuresAssets";
import { IoMdCloseCircleOutline } from "react-icons/io";

const CreateTable = ({ onClose, handleTableCreated }) => {
  const [tableName, setTableName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreateTable = () => {
    if (!tableName) {
      alert("Please enter a table name.");
      return;
    }

    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    const initialDataSets = [
      initialTableData1,
      initialTableData2,
      initialTableData3,
      initialTableData4,
      initialTableData5,
      initialTableData6,
      initialTableData7,
      initialTableData8,
      initialTableData9,
      initialTableData10,
      initialTableData11,
      initialTableData12,
    ];

    // Check if initialDataSets has the correct number of datasets
    if (initialDataSets.length !== 12) {
      setIsLoading(false);
      setErrorMessage("Invalid input data: Expected 12 initial data sets.");
      return;
    }

    axios
      .post("https://vertical.lblfm.com/api/tabledata", {
        baseTableName: tableName,
        initialDataSets,
      })
      .then((response) => {
        setIsLoading(false);
        setSuccessMessage("Table created successfully!");
        setTimeout(() => {
          if (handleTableCreated) {
            handleTableCreated(response.data);
          }
          onClose();
        }, 1000); // 1-second delay
      })
      .catch((error) => {
        setIsLoading(false);
        const errorMsg =
          error.response?.status === 400
            ? "Failed to load resource: the server responded with a status of 400 (Bad Request)"
            : error.response?.data?.error ||
              "An error occurred. Please try again.";
        setErrorMessage(errorMsg);
      });
  };

  return (
    <div className="create-table-container">
      <div className="close-button" onClick={onClose}>
        <IoMdCloseCircleOutline />
      </div>
      <h2>Create Table</h2>
      <label>Enter The Table Name:</label>
      <input
        type="text"
        placeholder="Enter table name"
        value={tableName}
        onChange={(e) => setTableName(e.target.value)}
        className="table-name-input"
      />
      <button
        className="create-table-button"
        onClick={handleCreateTable}
        disabled={isLoading}
      >
        {isLoading ? "Please wait, generating..." : "Create New Tables"}
      </button>
      {isLoading && <div className="loading-message">Generating...</div>}
      {successMessage && !errorMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default CreateTable;
