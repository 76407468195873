const NoteBSData4 = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "M/S:-",
          colspan: 1,
          class: "no-border-r free",
        },
        {
          content: "",
          colspan: 5,
          isUserProvided: false,
          class: "no-border-r free",
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        {
          content:
            "Notes forming part of the Financial Statements  for the year ended, 31st March, 2024",
          colspan: 6,
          class: "no-border-r free bgblue center",
        },
      ],
    },

    {
      id: "row-2",
      cells: [
        { content: "4", class: "bold" },
        { content: "Reserves and surplus", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },

    // new

    {
      id: "row-3",
      cells: [
        { content: "(a)", class: "bold" },
        { content: "Capital Reserve", class: "bold" },
        { content: "", isUserProvided: false },
        {
          content: "",
          isUserProvided: false,
        },
        {
          content: ".",
          colspan: 3,
          isUserProvided: false,
          class:
            "no-border-r free trans-text trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-4",
      cells: [
        { content: "(b)", class: "bold" },
        { content: "Revaluation Reserve", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 3,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-5",
      cells: [
        { content: "(c)", class: "bold" },
        { content: "Other Reserve (Please specify)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },

        {
          content: ".",
          colspan: 4,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-6",
      cells: [
        { content: "(d)", class: "bold" },
        {
          content:
            "Undistributed Surplus (Balance from statement of profit and loss)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-7",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: "Total", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-top-border ",
        },
      ],
    },
    // second stage stat hear

    {
      id: "row-8",
      cells: [
        { content: ".", class: "bgblue trans-text" },
        { content: ".", class: "bgblue trans-text" },
        {
          content: "Long Term",
          colspan: 2,
          isUserProvided: false,
          class: "bold center bgblue",
        },

        {
          content: "Short Term",
          colspan: 2,
          isUserProvided: false,
          class: "bold center bgblue",
        },
      ],
    },

    {
      id: "row-9",
      cells: [
        { content: "5", class: "bold" },
        { content: "Borrowings", class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },

    {
      id: "row-10",
      cells: [
        { content: "." },
        { content: "Secured" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    // new 7 stated hear

    {
      id: "row-11",
      cells: [
        { content: "(a)" },
        { content: "Term loans" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-12",
      cells: [
        { content: "(B)" },
        { content: "other" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-13",
      cells: [
        { content: "(b)" },
        { content: "Loans repayable on demand" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-14",
      cells: [
        { content: "." },
        { content: "(from banks (PNB))" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-15",
      cells: [
        { content: "(c)" },
        { content: "Deferred payment liabilities" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-16",
      cells: [
        { content: "(d)" },
        { content: "Loans and advances from related parties" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-17",
      cells: [
        { content: "(e)" },
        {
          content: "Long term/current maturitites of finance lease obligation",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-18",
      cells: [
        { content: "(f)" },
        {
          content: "Other loans advances (specify nature)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-19",
      cells: [
        { content: "." },
        {
          content: "Total (A)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-20",
      cells: [
        { content: "." },
        {
          content: "Unsecured",
          class: "bold",
        },
        { content: ".", isUserProvided: false, class: "trans-text" },
        { content: ".", isUserProvided: false, class: "trans-text" },
        { content: ".", isUserProvided: false, class: "trans-text" },
        { content: ".", isUserProvided: false, class: "trans-text" },
      ],
    },

    {
      id: "row-21",
      cells: [
        { content: "(a)" },
        {
          content: "Term loans",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-22",
      cells: [
        { content: "." },
        {
          content: "PNB GECL LOAN",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    //   b stated hear

    {
      id: "row-23",
      cells: [
        { content: "(b)" },
        {
          content: "Loans repayable on demand",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-24",
      cells: [
        { content: "." },
        {
          content: "( from banks )",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-25",
      cells: [
        { content: "." },
        {
          content: "(from other parties)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    // c
    {
      id: "row-26",
      cells: [
        { content: "(c)" },
        {
          content: "Deferred payment liabilities ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    // d
    {
      id: "row-27",
      cells: [
        { content: "(d)" },
        {
          content: "Loans and advances from related parties",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    // e
    {
      id: "row-28",
      cells: [
        { content: "." },
        {
          content: "Total (B)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-29",
      cells: [
        { content: "." },
        {
          content: "Total (A)+ (B)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-30",
      cells: [
        { content: "." },
        {
          content: "Foot Note:",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    // test

    {
      id: "row-31",
      cells: [
        {
          content: "(i)",
        },
        {
          content: "Nature of the Security to be specified separately.",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-btton-border ",
        },
      ],
    },

    {
      id: "row-32",
      cells: [
        {
          content: "(ii)",
        },
        {
          content:
            "Terms of repayment of terms loans and other loans may be stated.",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-33",
      cells: [
        {
          content: "(iii)",
        },
        {
          content:
            "Where loans guranteed by partners/proprietors/owners aggregate of such amount under each head may be disclosed.",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-top-border",
        },
      ],
    },

    {
      id: "row-34",
      cells: [
        { content: "6", class: "bold" },
        { content: "Deferred tax liabilities/(asset) (Net)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "Charge/ (benefit) for the year", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class:
            "no-border-r free trans-text hiddeen-btton-top-border  hiddeen-top-border ",
        },
      ],
    },

    {
      id: "row-35",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: "Deferred tax asset", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-36",
      cells: [
        { content: ".", class: "bold trans-text" },
        {
          content:
            "Expenses provided but allowable in Income Tax on payment basis",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-37",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: "Provision for doubtful debts", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    // new 8

    {
      id: "row-38",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: "Provision for doubtful debts", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-btton-top-border trans-text ",
        },
      ],
    },
    {
      id: "row-39",
      cells: [
        { content: ".", class: "bold trans-text" },
        {
          content: "Difference between book depreciation & tax depreciation",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-btton-top-border trans-text",
        },
      ],
    },
    {
      id: "row-40",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: "Gross deferred tax asset (A)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-btton-top-border trans-text ",
        },
      ],
    },
    {
      id: "row-41",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: "Deferred tax liability", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-btton-top-border trans-text ",
        },
      ],
    },
    {
      id: "row-42",
      cells: [
        { content: ".", class: "bold trans-text" },
        {
          content: "Difference between book depreciation & tax depreciation",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-btton-top-border trans-text ",
        },
      ],
    },
    {
      id: "row-43",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: "Others (please specify)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free hiddeen-btton-top-border trans-text ",
        },
      ],
    },
    {
      id: "row-44",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: "Gross deferred tax liability (B)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-45",
      cells: [
        { content: ".", class: "bold trans-text" },
        { content: "Net deferred tax liability/(asset) (B-A)", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 2,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-46",
      cells: [
        {
          content: ".",
          colspan: 6,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-border ",
        },
      ],
    },
    {
      id: "row-47",
      cells: [
        {
          content: ".",
          colspan: 6,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
  ],
};
export default NoteBSData4;
