import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import imgae from "../assets/login.png";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    if (username === "" || password === "") {
      setError("Please fill in both fields.");
      return false;
    }
    setError("");
    return true;
  };

  const handleLogin = () => {
    if (!validateForm()) return;

    axios
      .post("https://vertical.lblfm.com/api/login", { username, password })
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setSuccess(true);
        setTimeout(() => {
          navigate("/tables");
        }, 800); // Redirect after 2 seconds
      })
      .catch((error) => {
        console.error(
          "API Error:",
          error.response ? error.response.data : error.message
        );
        setError("Invalid username or password. Please try again.");
      });
  };

  return (
    <div className="login-page">
      <div className="login-image">
        <img src={imgae} alt="Login Illustration" />
      </div>
      <div className="login-container">
        <h2>Welcome Back!</h2>
        <p>Please login to your account</p>
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">Login successful!</div>}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="input-field"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
        />
        <button onClick={handleLogin} className="login-button">
          Login
        </button>
        <p className="register-text">
          Don't have an account?{" "}
          <button
            onClick={() => navigate("/register")}
            className="register-button"
          >
            Register here
          </button>
        </p>
      </div>
    </div>
  );
};

export default Login;
