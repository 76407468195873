const bsData12 = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "NAME OF ASSESSEE:",
          colspan: 2,
          class: "no-border-r free",
        },
        {
          content: "",
          colspan: 6,
          isUserProvided: false,
          class: "no-border-r free",
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        {
          content: "Notes forming part of the Financial Statements",
          colspan: 8,
          class: "no-border-r free bgblue center",
        },
      ],
    },
    {
      id: "row-2",
      cells: [
        {
          content: ".",
          colspan: 8,
          class: "no-border-r free center trans-text",
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        {
          content: ".",
          colspan: 7,
          class: "no-border-r free center trans-text",
        },
        {
          content: "(Amount in Rs.)",
          isUserProvided: false,
          class: "no-border",
        },
      ],
    },
    // from hear the new one will start
    {
      id: "row-4",
      cells: [
        {
          content: "12",
          class: "bold topBd",
        },
        {
          content: "Investments - Non Current and Current",
          class: "bold topBd",
        },
        {
          content: "As at 31 March 2024",
          colspan: 3,
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "As at 31 March 2023",
          colspan: 2,
          class: "bold topButtonBorder",
        },
      ],
    },

    {
      id: "row-5",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "(valued at historical cost unless stated otherwise)",
          class: "bold-underline",
        },
        {
          content: "Face Value",
          class: "bold topButtonBorder",
        },
        {
          content: "Numbers/ Units/ Shares",
          class: "bold topButtonBorder",
        },
        {
          content: "Book Value",
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "Numbers/ Units/ Shares",
          class: "bold topButtonBorder",
        },
        {
          content: "Book Value",
          class: "bold topButtonBorder",
        },
      ],
    },
    // new
    {
      id: "row-6",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Trade Investments -Quoted",
          class: "bold-underline",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    // new 22
    {
      id: "row-7",
      cells: [
        {
          content: "(a)",
          class: "bold",
        },
        {
          content: "Investments in Other Entities",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-8",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Less: Provision for diminution in value of investments",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-9",
      cells: [
        {
          content: "(b)",
          class: "bold",
        },
        {
          content: "Investments in partnership firm (Refer footnote 1)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-10",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Other Investments",
          class: "bold-underline",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-11",
      cells: [
        {
          content: "(c)",
          class: "bold",
        },
        {
          content: "Investments in preference shares",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-12",
      cells: [
        {
          content: "(d)",
          class: "bold",
        },
        {
          content: "Investments in equity instruments",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-13",
      cells: [
        {
          content: "(e)",
          class: "bold",
        },
        {
          content: "Investments in government or trust securities ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-14",
      cells: [
        {
          content: "(f)",
          class: "bold",
        },
        {
          content: "Investments in debentures or bonds ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-15",
      cells: [
        {
          content: "(g)",
          class: "bold",
        },
        {
          content: "Investments in mutual funds",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-16",
      cells: [
        {
          content: "(h)",
          class: "bold",
        },
        {
          content: "Investments in  property (land)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-17",
      cells: [
        {
          content: "(i)",
          class: "bold",
        },
        {
          content: "Other non-current investments (specify nature)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-18",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Total Investments",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-19",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Trade Investments - Unquoted",
          class: "bold-underline",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-20",
      cells: [
        {
          content: "(a)",
          class: "bold",
        },
        {
          content: "Investments in Other Entities",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-21",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Less: Provision for diminution in value of investments",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-22",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "Investments in partnership firm (Refer footnote 1)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-23",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Other Investments",
          class: "bold-underline",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-24",
      cells: [
        {
          content: "(c)",
          class: "bold",
        },
        {
          content: "Investments in preference shares ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-25",
      cells: [
        {
          content: "(d)",
          class: "bold",
        },
        {
          content: "Investments in equity instruments",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-26",
      cells: [
        {
          content: "(e)",
          class: "bold",
        },
        {
          content: "Investments in government or trust securities ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-27",
      cells: [
        {
          content: "(i)",
          class: "bold",
        },
        {
          content: "Investments property",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-28",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Total Investments",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    // new 10
    {
      id: "row-29",
      cells: [
        {
          content: ".",
          class: "trans-text topBd ",
        },
        {
          content: "Aggregate market value as at the end of the year:",
          class: "bold topBd ",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topBd ",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topBd ",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topBd ",
        },
        {
          content: ".",
          class: "trans-text topBd ",
        },

        {
          content: "",
          isUserProvided: false,
          class: "topBd ",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topBd ",
        },
      ],
    },
    {
      id: "row-30",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content:
            "Aggregate amount of quoted investments and market value thereof.",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-31",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Aggregate amount of Un-quoted investments.",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-32",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content:
            "Aggreagte Provision for diminution in value of investments.",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-33",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-34",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Footnote 1: Details of investment in partnership firm",
          class: "text-blue",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "topButtonBorder",
        },
      ],
    },
    {
      id: "row-35",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Name of partner with % share in profits of such firm",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-36",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-37",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-38",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-39",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Total capital of the firm (Amount in  Rs.)",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
      ],
    },

    // new stated
    {
      id: "row-40",
      cells: [
        {
          content: ".",
          class: "bold topBd",
        },
        {
          content: "Current Investments",
          class: "bold topBd",
        },
        {
          content: "As at 31 March 2024",
          colspan: 3,
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "As at 31 March 2023",
          colspan: 2,
          class: "bold topButtonBorder",
        },
      ],
    },

    {
      id: "row-41",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Face Value",
          class: "bold topButtonBorder",
        },
        {
          content: "Numbers/ Units/ Shares",
          class: "bold topButtonBorder",
        },
        {
          content: "Book Value",
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "Numbers/ Units/ Shares",
          class: "bold topButtonBorder",
        },
        {
          content: "Book Value",
          class: "bold topButtonBorder",
        },
      ],
    },
    //new 17
    {
      id: "row-42",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-43",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Current maturities of long-term investments",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-44",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "Investments in equity instruments ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-45",
      cells: [
        {
          content: "(c)",
          class: "",
        },
        {
          content: "Investments in preference shares ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-46",
      cells: [
        {
          content: "(d)",
          class: "",
        },
        {
          content: "Investments in government or trust securities ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-47",
      cells: [
        {
          content: "(e)",
          class: "",
        },
        {
          content: "Investments in debentures or bonds ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-48",
      cells: [
        {
          content: "(f)",
          class: "",
        },
        {
          content: "Investments in mutual funds ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-49",
      cells: [
        {
          content: "(g)",
          class: "",
        },
        {
          content: "Other Short-term investments (specify nature)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-50",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Net current investments",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold ",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-51",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Trade (valued at lower of cost or market value) - Unquoted",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-52",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Current maturities of long-term investments ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-53",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "Investments in equity instruments ",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-54",
      cells: [
        {
          content: "(f)",
          class: "",
        },
        {
          content: "Investments in PPF",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-55",
      cells: [
        {
          content: "(g)",
          class: "",
        },
        {
          content: "Other Short-term investments (specify nature)",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-56",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content: "Net current investments",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
      ],
    },
    {
      id: "row-57",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Grand Total",
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold",
        },
      ],
    },
    {
      id: "row-58",
      cells: [
        {
          content: ".",
          class: "",
        },
        {
          content:
            "Aggregate value of quoted investments and market value thereof.",
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    // from hear the differnt 13  will stat
    {
      id: "row-59",
      cells: [
        {
          content: ".",
          class: "trans-text topBd",
        },
        {
          content: ".",
          colspan: 2,
          class: "trans-text topBd",
        },
        {
          content: "Long Term",
          colspan: 2,
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "Short Term",
          colspan: 2,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-60",
      cells: [
        {
          content: "13",
          class: "bold",
        },
        {
          content: "Loans and advances",
          colspan: 2,
          class: "bold",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-61",
      cells: [
        {
          content: "A",
          class: "bold ",
        },
        {
          content: "(Secured)",
          colspan: 2,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    // new 19 stated hear
    {
      id: "row-62",
      cells: [
        {
          content: ".",
          class: " trans-text",
        },
        {
          content: ".",
          colspan: 2,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-63",
      cells: [
        {
          content: "(a)",
          class: " ",
        },
        {
          content: "Capital advances",
          colspan: 2,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-64",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Considered good",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-65",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Doubtful",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-66",
      cells: [
        {
          content: ".",
          class: " trans-text",
        },
        {
          content: "Less: Provision for doubtful advances",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-67",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "(a)",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-68",
      cells: [
        {
          content: "(b)",
          class: " ",
        },
        {
          content: "Loans advances to partners or relative of partners",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-69",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: ".",
          colspan: 2,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-70",
      cells: [
        {
          content: "(c)",
          class: " ",
        },
        {
          content: "Other loans and advances (specify nature)",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-71",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Prepaid expenses",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-72",
      cells: [
        {
          content: ".",
          class: " trans-text ",
        },
        {
          content:
            "Advance tax and tax deducted at source [Net of provision for income tax of Rs. ___  (previous year Rs. ___)]",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-73",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "CENVAT credit receivable",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-74",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "VAT credit receivable",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-75",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Service tax credit receivable",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-76",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "GST input credit receivable",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-77",
      cells: [
        {
          content: ".",
          class: " trans-text ",
        },
        {
          content: "Security Deposits",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-78",
      cells: [
        {
          content: ".",
          class: " trans-text",
        },
        {
          content: "Balance with government authorities",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-79",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "(b)",
          colspan: 2,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-80",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Total (a)+(b) (A)",
          colspan: 2,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    // from hear the 13 B
    {
      id: "row-81",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 2,
          class: "trans-text",
        },
        {
          content: "Long Term",
          colspan: 2,
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "Short Term",
          colspan: 2,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-82",
      cells: [
        {
          content: "B",
          class: "bold",
        },
        {
          content: "Loans and advances",
          colspan: 2,
          class: "bold",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-83",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "(Unsecured)",
          colspan: 2,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    // new 14
    {
      id: "row-84",
      cells: [
        {
          content: "(a)",
          class: "trans-text ",
        },
        {
          content: "Capital advances",
          colspan: 2,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-85",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Considered good ",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-86",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Doubtful",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-87",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Less: Provision for doubtful advances",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-88",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "(a)",
          colspan: 2,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-89",
      cells: [
        {
          content: "(c)",
          class: "trans-text ",
        },
        {
          content: "Other loans and advances ",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-90",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Advancefor TDS payment and its interest and fine",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-91",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content:
            "Advance tax and tax deducted at source [Net of provision for income tax of Rs. ___  (previous year Rs. ___)]",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-92",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "CENVAT credit receivable",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-93",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "VAT credit receivable",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-94",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Service tax credit receivable",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-95",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "GST input credit receivable",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-96",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Security Deposits",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-97",
      cells: [
        {
          content: ".",
          class: "trans-text ",
        },
        {
          content: "Balance with government authorities",
          colspan: 2,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: ".",
          class: "trans-text",
        },

        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    // differenr
    {
      id: "row-98",
      cells: [
        {
          content: ".",
          class: "trans-text topBd",
        },
        {
          content: "(b)",
          colspan: 2,
          class: "bold topBd",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-99",
      cells: [
        {
          content: ".",
          class: "trans-text btbd ",
        },
        {
          content: "Total (a)+(b) (B)",
          colspan: 2,
          class: "bold btbd",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-100",
      cells: [
        {
          content: ".",
          class: "trans-text topBd",
        },
        {
          content: ".",
          colspan: 2,
          class: "trans-text topBd",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-101",
      cells: [
        {
          content: ".",
          class: "trans-text btbd ",
        },
        {
          content: "Total (A + B)",
          colspan: 2,
          class: "bold btbd",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: ".",
          class: "",
        },

        {
          content: "",
          class: "bold topButtonBorder",
        },
        {
          content: "",
          class: "bold topButtonBorder",
        },
      ],
    },

    // form hear the new formet will start 14
    {
      id: "row-102",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    // new 57 stated hear
    {
      id: "row-103",
      cells: [
        {
          content: "14",
          class: "bold",
        },
        {
          content: "Other non-current assets",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-104",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Security Deposits ",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-105",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Electricity Security Deposits",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-106",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-107",
      cells: [
        {
          content: ".",
          class: "tran-text",
        },
        {
          content: "Total other non-current other assets",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-108",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-109",
      cells: [
        {
          content: "15",
          class: "bold",
        },
        {
          content: "Inventories",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-110",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Raw materials",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-111",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "Work-in-progress",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-112",
      cells: [
        {
          content: "(c)",
          class: "",
        },
        {
          content: "Finished goods",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-113",
      cells: [
        {
          content: "(d)",
          class: "",
        },
        {
          content: "Stock-in-trade",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-114",
      cells: [
        {
          content: "(e)",
          class: "",
        },
        {
          content: "Stores and spares",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-115",
      cells: [
        {
          content: "(f)",
          class: "",
        },
        {
          content: "Loose Tools",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-116",
      cells: [
        {
          content: "(g)",
          class: "",
        },
        {
          content: "Others (packaging Materials)",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-117",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "trans-text",
        },
        {
          content: ".",
          isUserProvided: false,
          class: "trans-text",
        },
      ],
    },
    {
      id: "row-118",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-119",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-120",
      cells: [
        {
          content: "16",
          class: "bold",
        },
        {
          content: "Trade receivables",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-121",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content:
            "Outstanding for a period less than 6 months from the date they are due for receipt",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-122",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Secured Considered good",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-123",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "Unsecured Considered good",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-124",
      cells: [
        {
          content: "(c)",
          class: "",
        },
        {
          content: "Doubtful",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-125",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-126",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content:
            "Outstanding for a period exceeding 6 months from the date they are due for receipt",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-127",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Secured Considered good",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-128",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Less: Provision for doubtful receivables",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-129",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-130",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-131",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-132",
      cells: [
        {
          content: "17",
          class: "bold",
        },
        {
          content: "Cash and Bank Balances",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-133",
      cells: [
        {
          content: "A",
          class: "bold",
        },
        {
          content: "Cash and cash equivalents",
          colspan: 5,
          class: "bold-underline",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-134",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: " On SB/Current Account",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-135",
      cells: [
        {
          content: "(b)",
          class: "",
        },
        {
          content: "PNB",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-136",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "PNB Subsidy A/c",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-137",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "ICICI Bank",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-138",
      cells: [
        {
          content: "(c)",
          class: "",
        },
        {
          content: "Fixed Deposits",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-139",
      cells: [
        {
          content: "(d)",
          class: "",
        },
        {
          content: "Cheques, drafts on hand",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-140",
      cells: [
        {
          content: "(e)",
          class: "",
        },
        {
          content: "Cash on hand",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-141",
      cells: [
        {
          content: "(f)",
          class: "",
        },
        {
          content: "Rounded off",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-142",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total ",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-143",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-144",
      cells: [
        {
          content: "B",
          class: "bold",
        },
        {
          content: "Other bank balances",
          colspan: 5,
          class: "bold-underline",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-145",
      cells: [
        {
          content: "(a)",
          class: "",
        },
        {
          content: "Bank Deposits",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-146",
      cells: [
        {
          content: "(i)",
          class: "",
        },
        {
          content: "Earmarked Bank Deposits",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-147",
      cells: [
        {
          content: "(ii)",
          class: "",
        },
        {
          content:
            "Deposits with original maturity for more than 3 months but less than 12 months from reporting date",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-148",
      cells: [
        {
          content: "(iii)",
          class: "",
        },
        {
          content: "Margin money or deposits under lien ",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-149",
      cells: [
        {
          content: "(iv)",
          class: "",
        },
        {
          content: "Others (specify nature)",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-150",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total other bank balances  (II)",
          colspan: 5,
          class: "bold ",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-151",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total Cash and bank balances (I+II)",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-152",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-153",
      cells: [
        {
          content: "18",
          class: "bold",
        },
        {
          content: "Other current assets",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
    {
      id: "row-154",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Amortised Preoperative Exp c/f:",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-155",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "GST ITC",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-156",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Rounded off",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-157",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Advances to Parties",
          colspan: 5,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-158",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: ".",
          colspan: 5,
          class: "trans-text",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
        {
          content: "",
          isUserProvided: false,
          class: "",
        },
      ],
    },
    {
      id: "row-159",
      cells: [
        {
          content: ".",
          class: "trans-text",
        },
        {
          content: "Total ",
          colspan: 5,
          class: "bold",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
        {
          content: "",
          isUserProvided: false,
          class: "bold topButtonBorder",
        },
      ],
    },
  ],
};
export default bsData12;
