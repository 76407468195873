const initialTableData1 = {
  rows: [
    {
      id: "row-0",
      cells: [
        { content: "NAME OF THE ASSESSEE", class: "bold " },
        {
          content: "",
          colspan: 9,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        { content: "PAN", class: "bold" },
        {
          content: "",
          colspan: 9,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-2",
      cells: [
        { content: "ADDRESS", class: "bold" },
        {
          content: "",
          colspan: 9,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-3",
      cells: [
        { content: "Previous Year", class: "bold" },
        {
          content: "",
          colspan: 9,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-4",
      cells: [
        { content: "Assessment Year", class: "bold" },
        {
          content: "",
          colspan: 9,
          isUserProvided: false,
        },
      ],
    },
    {
      id: "row-5",
      cells: [
        {
          content: "PROFIT AND LOSS ACCOUNT",
          colspan: 10,
          class: "bold centerYelow",
        },
      ],
    },
    {
      id: "row-6",
      cells: [
        { content: "", isUserProvided: false, class: "bold" },
        { content: "Current Year", class: "bold  " },
        { content: "For the current year ended", class: "bold" },
        { content: "Preceeding year", class: "bold" },
        { content: "For Preceeding year ended", class: "bold" },
        { content: "", isUserProvided: false },
        { content: "Current Year", class: "bold" },
        { content: "For the current year ended ", class: "bold" },
        { content: "Preceding year", class: "bold" },
        { content: "For the preceding year ended ", class: "bold" },
      ],
    },
    {
      id: "row-7",
      cells: [
        {
          content: "To Opening Inventory (Raw Material)",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Revenue from operations ", class: "bold-underline" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-8",
      cells: [
        {
          content: "Inventory at the beginning of year",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Sale of products" },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-9",
      cells: [
        {
          content: "Inventory of Packaging Material",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Sale of services" },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-10",
      cells: [
        {
          content: "To Purchase of Inventory (Raw Material)",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        { content: "Grants or donations received" },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-11",
      cells: [
        {
          content: "Purchases of Inventory during the year",
          isUserProvided: false,
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content:
            "Other operating revenue (Income u/s 44AE, Income from plying Truck) ",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-101",
      cells: [
        {
          content: "Purchases of Packaging during the year",
          isUserProvided: false,
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "Any other Receipts/Sales",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-12",
      cells: [
        {
          content: "To Opening Inventory (Finished Goods) ",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Any other Receipts/Sales", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-102",
      cells: [
        {
          content: "Inventory of Finished goods in Transit ",
          class: "",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Any other Receipts/Sales", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-103",
      cells: [
        {
          content: "Inventory of Store and spares ",
          class: "",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Any other Receipts/Sales", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-104",
      cells: [
        {
          content: "Inventory of Loose Tools ",
          class: "",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Any other Receipts/Sales", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-105",
      cells: [
        {
          content: "Inventory of Stock In Trade",
          class: "",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Any other Receipts/Sales", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-13",
      cells: [
        {
          content: "Inventory at the beginning of year(FG) ",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Total Revenue from operation" },
        { content: "0", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-14",
      cells: [
        {
          content: "",
          class: "",
        },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        { content: "Less: Any taxes,GST, duty etc" },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-15",
      cells: [
        {
          content: "To Purchase of Finished Goods ",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Net Total Revenue from Operation" },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-16",
      cells: [
        {
          content: "Purchases of finished goods during the year ",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-17",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        { content: "By Inventory of Raw Material", class: "bold-underline" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-18",
      cells: [
        {
          content: "",
          // class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Raw Material Inventory" },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-19",
      cells: [
        {
          content: "To Opening Inventory (WIP) ",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Packaging Material Inventory" },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-20",
      cells: [
        {
          content: " WIP Inventory at the beginning of year ",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Raw Material in Transit", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-21",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        {
          content: " 	By Inventory of Finished Goods ",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-22",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Inventory at the end of year(Stock-in-trade)" },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-106",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Inventory at the end of year (FG)" },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-23",
      cells: [
        {
          content: "To Direct Expenses",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Finished Goods in Transit", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-24",
      cells: [
        {
          content: "i. Freight",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Stores and spares", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-25",
      cells: [
        {
          content: "ii. Carriage Inwards",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "loose tools", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-26",
      cells: [
        {
          content: "iii. Transportation",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "By Inventory of WIP", class: "bold-underline" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-27",
      cells: [
        {
          content: "iV. other",
        },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Inventory at the end of year" },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-28",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false, class: "trans-text" },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false, class: "trans-text" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-29",
      cells: [
        {
          content: "To Gross Profit c/d",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-30",
      cells: [
        {
          content: "(Balancing Figure)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-31",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topButtonBorder" },
      ],
    },
    {
      id: "row-32",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-33",
      cells: [
        {
          content: "To Employees Expenses",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-34",
      cells: [
        {
          content: "Salaries, wages, bonus and other allowances ",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "By Gross Profit B/d",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-35",
      cells: [
        {
          content: "Bouns on Salary",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "By Income from other sources", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-36",
      cells: [
        {
          content: "Gratuity expenses",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "SB Interest", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-37",
      cells: [
        {
          content: "Staff welfare expenses",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "FD Interest", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-38",
      cells: [
        {
          content: "EPF/ESI expenses",
        },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "Commission recived", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-39",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Incentive recived", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-40",
      cells: [
        {
          content: "To Finance Cost",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Discount recived", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-41",
      cells: [
        {
          content: "On Bank's Loan",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "By Other Income (If any)", isUserProvided: false },
        { content: "", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-42",
      cells: [
        {
          content: "i. Tearm Loan",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-43",
      cells: [
        {
          content: "ii. Cash Credit/OD/WCL",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-44",
      cells: [
        {
          content: "On assets on finance lease",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-45",
      cells: [
        {
          content: "Other borrowing costs",
        },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-46",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-47",
      cells: [
        {
          content: "To Depreciation",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-48",
      cells: [
        {
          content: "On Tangible assets",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-49",
      cells: [
        {
          content: "On Intangible assets",
        },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-50",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-51",
      cells: [
        {
          content: "To Other Expenses",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-52",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-53",
      cells: [
        {
          content: "Incentives",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-54",
      cells: [
        {
          content: "Tour and Travel",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-55",
      cells: [
        {
          content: "Audit fees",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-56",
      cells: [
        {
          content: "Bank Charges",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-57",
      cells: [
        {
          content: "Gst Interest",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-58",
      cells: [
        {
          content: "Office Expenses",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-59",
      cells: [
        {
          content: "Store renovation Expenses",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-60",
      cells: [
        {
          content: "Bad debts",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-61",
      cells: [
        {
          content: "Rent",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-62",
      cells: [
        {
          content: "Electricity Exp",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-63",
      cells: [
        {
          content: "Carriage outward",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-64",
      cells: [
        {
          content: "Puja Expenses",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-65",
      cells: [
        {
          content: "Other indirect expenses",
        },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-66",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-67",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "trans-text" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-68",
      cells: [
        {
          content: "To Profit and loss before exceptional & ",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-69",
      cells: [
        {
          content: "Extraordinary items and tax",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-70",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-71",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topButtonBorder" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topButtonBorder" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topButtonBorder" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topButtonBorder" },
      ],
    },
    {
      id: "row-72",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-73",
      cells: [
        {
          content: "To Exceptional items",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: "By Profit /Loss before exceptional &",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "topBd" },
      ],
    },
    {
      id: "row-74",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "Extraordinary items b/d", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-75",
      cells: [
        {
          content: "Profit/loss before extraordinary items",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-76",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topBd" },
      ],
    },
    {
      id: "row-77",
      cells: [
        {
          content: "To Extraordinary items",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-78",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-79",
      cells: [
        {
          content: "To profit before tax",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-80",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-81",
      cells: [
        {
          content: "Tax expenses",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-82",
      cells: [
        {
          content: "Current Tax",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-83",
      cells: [
        {
          content: "Excess/short provisions of tax relating to ",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-84",
      cells: [
        {
          content: "Earlier years",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-85",
      cells: [
        {
          content: "Deferred tax charges/(benefits)",
        },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-86",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-87",
      cells: [
        {
          content: "Profit/(loss) for the period from continuing",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold topBd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-88",
      cells: [
        {
          content: "Operations",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-89",
      cells: [
        {
          content: "Profit/(loss) from discontiuing operations ",
        },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-90",
      cells: [
        {
          content: "Tax expenses of discontinuing operations",
        },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "btbd" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-91",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-92",
      cells: [
        {
          content: "Profit/(Loss) for the year",
        },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "topButtonBorder" },
        { content: "", isUserProvided: false },
        { content: "0", isUserProvided: false, class: "topButtonBorder" },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-93",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-94",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-95",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
  ],
};

export default initialTableData1;
