const AnnexuresAssets = {
  rows: [
    {
      id: "row-0",
      cells: [
        {
          content: "Annexure J: PPE",
          colspan: 11,
          class: "bold",
        },
      ],
    },
    {
      id: "row-1",
      cells: [
        { content: "Rate in %", class: "bold dropdown" },
        {
          content: "0%",
          type: "dropdown",
          options: ["0"],
          class: "bold dropdown",
        },
        {
          content: "5% or 10%",
          type: "dropdown",
          options: ["5", "10"],
          class: "bold dropdown",
        },
        {
          content: "10%",
          type: "dropdown",
          options: ["10", "15", "20"],
          class: "bold dropdown",
        },
        {
          content: "15% or 30%",
          type: "dropdown",
          options: ["15", "30"],
          class: "bold dropdown",
        },
        {
          content: "15% or 30%",
          type: "dropdown",
          options: ["15", "30"],
          class: "bold dropdown",
        },
        {
          content: "40%",
          type: "dropdown",
          options: ["15", "30", "40"],
          class: "bold dropdown",
        },
        {
          content: "20% or 25%",
          type: "dropdown",
          options: ["20", "25"],
          class: "bold dropdown",
        },
        { content: "", class: "bold dropdown" },
        {
          content: "25%",
          type: "dropdown",
          options: ["25", "30"],
          class: "bold dropdown",
        },
        { content: "", class: "bold dropdown" },
      ],
    },
    {
      id: "row-2",
      cells: [
        { content: "Selected Rate in %", class: "bold" },
        {
          content: "0",
          class: "bold",
          bindTo: { row: "row-1", cellIndex: 1 },
        },
        {
          content: "5",
          class: "bold",
          bindTo: { row: "row-1", cellIndex: 2 },
        },
        {
          content: "10",
          class: "bold",
          bindTo: { row: "row-1", cellIndex: 3 },
        },
        {
          content: "15",
          class: "bold",
          bindTo: { row: "row-1", cellIndex: 4 },
        },
        {
          content: "15",
          class: "bold",
          bindTo: { row: "row-1", cellIndex: 5 },
        },
        { content: "40", class: "bold" },
        {
          content: "20",
          class: "bold",
          bindTo: { row: "row-1", cellIndex: 7 },
        },
        { content: "", class: "bold" },
        {
          content: "25",
          class: "bold",
          bindTo: { row: "row-1", cellIndex: 9 },
        },
        { content: "", class: "bold" },
      ],
    },
    {
      id: "row-3",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    // end cells
    {
      id: "row-4",
      cells: [
        {
          content: "Particular Assets",
          class: "bold",
        },
        { content: "Freehold", class: "bold" },
        { content: "Building", class: "bold" },
        { content: "Furniture", class: "bold" },
        { content: "Plant & Machinery", class: "bold" },
        { content: "Vehicles", class: "bold" },
        { content: "Computers", class: "bold" },
        { content: "Others", class: "bold" },
        { content: "Total", class: "bold" },
        { content: "Software", class: "bold" },
        { content: "Total", class: "bold" },
      ],
    },
    {
      id: "row-5",
      cells: [
        {
          content: "Gross Block",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },

    {
      id: "row-6",
      cells: [
        {
          content: "Opening Assets of Preceding Year",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-7",
      cells: [
        {
          content: "Addition (More than 180 days)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-8",
      cells: [
        {
          content: "Addition (Less than 180 days)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-9",
      cells: [
        {
          content: "Total Addition",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-10",
      cells: [
        {
          content: "Deduction (More than 180 days)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-11",
      cells: [
        {
          content: "Deduction (Less than 180 days )",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-12",
      cells: [
        {
          content: "Total Deduction",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-13",
      cells: [
        {
          content: "Closing Assets of Preceding Year ",
          class: "bold",
        },
        { content: "", class: "bold", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-14",
      cells: [
        {
          content: "Opening Assets of Current Year",
          class: "bold",
          isUserProvided: false,
        },
        { content: "", class: "bold", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-15",
      cells: [
        {
          content: "Addition (More than 180 days)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-16",
      cells: [
        {
          content: "Addition (Less than 180 days)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-17",
      cells: [
        {
          content: " Total Addition",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-18",
      cells: [
        {
          content: "Deduction (More than 180 days)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-19",
      cells: [
        {
          content: "Deduction (Less than 180 days )",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-20",
      cells: [
        {
          content: "Total Deduction",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-21",
      cells: [
        {
          content: "Closing Assets of Current Year ",
          class: "bold",
        },
        { content: "", class: "bold", isUserProvided: false },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },

    {
      id: "row-22",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-23",
      cells: [
        {
          content: "DEPRECIATION",
          class: "bold",
        },
        { content: "Freehold", class: "bold" },
        { content: "Building", class: "bold" },
        { content: "Furniture", class: "bold" },
        { content: "Plant & Machinery", class: "bold" },
        { content: "Vechicles", class: "bold" },
        { content: "Computers", class: "bold" },
        { content: "Others", class: "bold" },
        { content: "Total", class: "bold" },
        { content: "Software", class: "bold" },
        { content: "Total", class: "bold" },
      ],
    },
    {
      id: "row-24",
      cells: [
        {
          content: "Opening Balance of the preceding year",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-25",
      cells: [
        {
          content: "Addition",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-26",
      cells: [
        {
          content: "Deduction/Adjustment",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-27",
      cells: [
        {
          content: "Closing Balance of Preceding Year",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },
    {
      id: "row-28",
      cells: [
        {
          content: "Addition",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-29",
      cells: [
        {
          content: "Deduction/Adjustment",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-30",
      cells: [
        {
          content: "Closing Balance current year",
          class: "bold",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
      ],
    },

    {
      id: "row-34",
      cells: [
        {
          content: "Net Block",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-35",
      cells: [
        {
          content: "At the End of Preceding Year ",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-36",
      cells: [
        {
          content: "At the End of Current Year ",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-37",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-38",
      cells: [
        {
          content: "Annexure K: Capital Work in Progress",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-39",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-40",
      cells: [
        {
          content: "Opening CWIP",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-41",
      cells: [
        {
          content: "Add: Addition during the year",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-42",
      cells: [
        {
          content: "Less: Capitalized during the year",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-43",
      cells: [
        {
          content: "Closing Balance",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-44",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-45",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-46",
      cells: [
        {
          content: "Notes:",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-47",
      cells: [
        {
          content: "of the above:",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-48",
      cells: [
        {
          content:
            "1. Restriction on Title of Land and Building/ Machinery etc valued  ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-49",
      cells: [
        {
          content: "2. Pledged Plant and Machinery",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-50",
      cells: [
        {
          content: "3. Any contractual commitments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-51",
      cells: [
        {
          content: "a…..............",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-52",
      cells: [
        {
          content: "b…..............",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-53",
      cells: [
        {
          content: "c…..............",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-54",
      cells: [
        {
          content: "4. Revaluation of Land/Building",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-55",
      cells: [
        {
          content: "5. Plant and Machinery held for Sale",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-56",
      cells: [
        {
          content: "a….................",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-57",
      cells: [
        {
          content: "b…..............",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-58",
      cells: [
        {
          content: "c…..............",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
      ],
    },
    {
      id: "row-59",
      cells: [
        {
          content: "Annexure L:  Intangible assets under development ",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-60",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-61",
      cells: [
        {
          content: "Particulars",
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-62",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-63",
      cells: [
        {
          content: "Trade Mark Applied ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-64",
      cells: [
        {
          content: "Patent Applied",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-65",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-66",
      cells: [
        {
          content: "Total of Int Assets under Dev",
          class: "bold-underline bgyellow ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-67",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-68",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-69",
      cells: [
        {
          content: "Annexure M: Non- Current Investments",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-70",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-71",
      cells: [
        {
          content: "Particulars",
        },
        { content: "Book Value" },
        { content: "Book Value" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-72",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-73",
      cells: [
        {
          content: "Long Term Investment",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-74",
      cells: [
        {
          content: "(Valued at historical cost unless stated otherwise)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-75",
      cells: [
        {
          content: "Trade Investment-Quoted ",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-76",
      cells: [
        {
          content: "a) Investment Property (Valued at cost less Accum Dep",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-77",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-78",
      cells: [
        {
          content: "Cost of Land & bulding given on operating lease",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-79",
      cells: [
        {
          content: "Less: Accumulated Depreciation",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-80",
      cells: [
        {
          content: "Total A",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-81",
      cells: [
        {
          content: "b) Investment in Other Entities",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-82",
      cells: [
        {
          content: "Less: Provision for diminution in value of investment",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-83",
      cells: [
        {
          content: "Total B",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-84",
      cells: [
        {
          content: "c)  Investments in Partnership Firm ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-85",
      cells: [
        {
          content: "Other Investment",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-86",
      cells: [
        {
          content: "d) Investments in preference shares",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-87",
      cells: [
        {
          content: "e) Investment in Government or trust securities",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-88",
      cells: [
        {
          content: "f) Investment in debentures or bonds",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-89",
      cells: [
        {
          content: "g) Other Long term investments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-90",
      cells: [
        {
          content: "Specify nature",
          class: "bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-91",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-92",
      cells: [
        {
          content: "TOTAL INVESTMENT A",
          class: "bold bold bgyellow",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-93",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-94",
      cells: [
        {
          content: "Trade Investment- Unquoted ",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-95",
      cells: [
        {
          content: "a) Investment Property (Valued at cost less Accum Dep)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-96",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-97",
      cells: [
        {
          content: "Cost of Land & bulding given on operating lease",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-98",
      cells: [
        {
          content: "Less: Accumulated Depreciation",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-99",
      cells: [
        {
          content: "Total A",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-100",
      cells: [
        {
          content: "b) Investment in Other Entities",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-101",
      cells: [
        {
          content: "Less: Provision for diminution in value of investment",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-102",
      cells: [
        {
          content: "Total B",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-103",
      cells: [
        {
          content: "c)  Investments in Partnership Firm ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-104",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-105",
      cells: [
        {
          content: "Other Investment",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-106",
      cells: [
        {
          content: "d) Investments in preference shares",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-107",
      cells: [
        {
          content: "e) Investment in Government or trust securities",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-108",
      cells: [
        {
          content: "f) Investment in debentures or bonds",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-109",
      cells: [
        {
          content: "g) Other Long term investments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-110",
      cells: [
        {
          content: "Specify nature",
          class: "bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-111",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-112",
      cells: [
        {
          content: "TOTAL INVESTMENT                                   B",
          class: "bold bgyellow",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-113",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-114",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-115",
      cells: [
        {
          content: "GRAND TOTAL                 A+B",
          class: "bold bgpencil",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-116",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-117",
      cells: [
        {
          content: "LONG TERM TRADE",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-118",
      cells: [
        {
          content: "Aggregate market value as at the end of the year",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-119",
      cells: [
        {
          content: "Market value of quoted investments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-120",
      cells: [
        {
          content: "Market Value of Un-quoted Investments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-121",
      cells: [
        {
          content: "Provision for diminution in value of investments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-122",
      cells: [
        {
          content: "Total Long Term Trade",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-123",
      cells: [
        {
          content: "SHORT TERM TRADE",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-124",
      cells: [
        {
          content: "Aggregate market value as at the end of the year",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-125",
      cells: [
        {
          content: "Market value of quoted investments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-126",
      cells: [
        {
          content: "Market Value of Un-quoted Investments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-127",
      cells: [
        {
          content: "Provision for diminution in value of investments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-128",
      cells: [
        {
          content: "Total Short Term Trade",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-129",
      cells: [
        {
          content: "TOTAL INVESTMENT IN TRADE (c)",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-130",
      cells: [
        {
          content: "Total A+B+C",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-131",
      cells: [
        {
          content: "Annexure N: LONG TERM LOANS AND ADVANCES",
          isUserProvided: false,
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-132",
      cells: [
        {
          content: "Particulars",
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-133",
      cells: [
        {
          content: "a)CAPITAL ADVANCES",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-134",
      cells: [
        {
          content: "Secured, considered good",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-135",
      cells: [
        {
          content: "Unsecured, considered good",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-136",
      cells: [
        {
          content: "Doubtful",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-137",
      cells: [
        {
          content: "Less: Provision for doubtful advances",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-138",
      cells: [
        {
          content:
            "TOTAL                                                                           A",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-139",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-140",
      cells: [
        {
          content: "b) Security Deposits",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-141",
      cells: [
        {
          content:
            "TOTAL                                                                           B",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-142",
      cells: [
        {
          content: "c) Other Loan and advances ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-143",
      cells: [
        {
          content: "(specify nature)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-144",
      cells: [
        {
          content: "Prepaid Expenses",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-145",
      cells: [
        {
          content: "Advance Tax and TDS (Net of Provision for Income Tax)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-146",
      cells: [
        {
          content: "Balance with Government Authorities",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-147",
      cells: [
        {
          content:
            "TOTAL                                                                                                              C",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-148",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-149",
      cells: [
        {
          content: "TOTAL LONG TERM LOANS AND ADVANCES       A+B+C",
          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-150",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-151",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-152",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-153",
      cells: [
        {
          content: "Annexure O: OTHER NON-CURRENT ASSETS",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-154",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-155",
      cells: [
        {
          content: "Particulars",
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-156",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-157",
      cells: [
        {
          content: "To Security Deposits (Non refundable)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-158",
      cells: [
        {
          content: "To Relatives",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-159",
      cells: [
        {
          content: "To Landlord as Security Deposit",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-160",
      cells: [
        {
          content: "To Deposit with Government Authority",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-161",
      cells: [
        {
          content: "To others (Specify the nature)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-162",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-163",
      cells: [
        {
          content: "TOTAL OTHER NON-CURRENT ASSSETS",
          class: "bold-underline bgyellow",
        },
        { content: "", isUserProvided: false, class: "bold" },
        { content: "", isUserProvided: false, class: "bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-164",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-165",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-166",
      cells: [
        {
          content: "Annexure P:  CURRENT INVESMENTS",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-167",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-168",
      cells: [
        {
          content: "Particulars",
          class: "bold",
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-169",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-170",
      cells: [
        {
          content: "Short Term Trade -Quoted",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-171",
      cells: [
        {
          content: "(valued at lower of cost or market value)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-172",
      cells: [
        {
          content: "a) Current Maturities of long term investment",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-173",
      cells: [
        {
          content: "b) Investments in equity instruments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-174",
      cells: [
        {
          content: "c) Investments in preference shares",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-175",
      cells: [
        {
          content: "d) Investments in Government or trust securities",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-176",
      cells: [
        {
          content: "e) Investment in debentures or bonds",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-177",
      cells: [
        {
          content: "f) Investment in mutual funds",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-178",
      cells: [
        {
          content: "g) Other short term investments ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-179",
      cells: [
        {
          content: "specify natures",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-180",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-181",
      cells: [
        {
          content: "NET CURRENT INVESTMENTS                   A",
          class: "bold bgyellow",
        },
        { content: "", isUserProvided: false, class: " bold" },
        { content: "", isUserProvided: false, class: " bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-182",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-183",
      cells: [
        {
          content: "Short Term Trade-Unqouted",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-184",
      cells: [
        {
          content: "(valued a t lower of cost or market value)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-185",
      cells: [
        {
          content: "a) Current Maturities of long term investment",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-186",
      cells: [
        {
          content: "b) Investments in equity instruments",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-187",
      cells: [
        {
          content: "c) Investments in preference shares",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-188",
      cells: [
        {
          content: "d) Investments in Government or trust securities",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-189",
      cells: [
        {
          content: "e) Investment in debentures or bonds",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-190",
      cells: [
        {
          content: "f) Investment in mutual funds",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-191",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-192",
      cells: [
        {
          content: "specify natures",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-193",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-194",
      cells: [
        {
          content: "NET CURRENT INVESTMENTS                   B",
          class: "bold bgyellow",
        },
        { content: "", class: "bold", isUserProvided: false },
        { content: "", class: "bold", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-195",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-196",
      cells: [
        {
          content: "GRAND TOTAL                                      A+B",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-197",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-198",
      cells: [
        {
          content: "Annexure Q:  INVENTORIES",
          class: "bold",
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-199",
      cells: [
        {
          content:
            "(Valued at lower of cost and net realizable value, unless stated other wise)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-200",
      cells: [
        {
          content:
            "(a) Raw materials and components [Includes Goods-in transit Rs.__(previous year Rs)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-201",
      cells: [
        {
          content: "(b) Work-in-progress (Valued at cost)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-202",
      cells: [
        {
          content:
            "(c) Finished goods [Include Goods-in transit of Rs. (previous year Rs.)]",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-203",
      cells: [
        {
          content:
            "(d) Stock-in-trade [Include Goods-in transit of Rs.  (previous year Rs.)]",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-204",
      cells: [
        {
          content:
            "(e) Stores and spares (Include Goods-in transit of Rs. (previous year Rs. (previous year Rs.)) (Valued at cost)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-205",
      cells: [
        {
          content:
            "(f) Loose Tools [Include Goods-in transit of Rs.  (previous year Rs.)) (Valued at cost)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-206",
      cells: [
        {
          content: "(g) Others (Specify nature)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-207",
      cells: [
        {
          content: "Total",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-208",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-209",
      cells: [
        {
          content: "Annexure R:  TRADE RECEIVABLE",
          class: "bold",
        },
        { content: "	For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-210",
      cells: [
        {
          content: "Secured, considered good",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-211",
      cells: [
        {
          content: "Unsecured, considered good",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-212",
      cells: [
        {
          content: "Unsecured, considered doubtful",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-213",
      cells: [
        {
          content: "Less: Provision for doubtful receivables",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-214",
      cells: [
        {
          content: "TOTAL TRADE RECEIVABLE",
          class: "bold bgyellow",
        },
        { content: "", isUserProvided: false, class: " bold" },
        { content: "", isUserProvided: false, class: " bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-215",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-216",
      cells: [
        {
          content: "Annexure S: SHORT TERM LOANS AND ADVANCES",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-217",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-218",
      cells: [
        {
          content: "Particulars",
        },
        { content: "	For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-219",
      cells: [
        {
          content: "a)CAPITAL ADVANCES",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-220",
      cells: [
        {
          content: "Secured, considered good",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-221",
      cells: [
        {
          content: "Unsecured, considered good",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-222",
      cells: [
        {
          content: "Doubtful",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-223",
      cells: [
        {
          content: "Less: Provision for doubtful advances",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-224",
      cells: [
        {
          content:
            "TOTAL                                                                                                              A",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-225",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-226",
      cells: [
        {
          content: "b) Security Deposits",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-227",
      cells: [
        {
          content:
            "TOTAL                                                                                                             B",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-228",
      cells: [
        {
          content: "c) Other Loan and advances ",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-229",
      cells: [
        {
          content: "(specify nature)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-230",
      cells: [
        {
          content: "Prepaid Expenses",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-231",
      cells: [
        {
          content: "Advance Tax and TDS (Net of Provision for Income Tax)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-232",
      cells: [
        {
          content: "Balance with Government Authorities",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-233",
      cells: [
        {
          content: "TOTAL                      C",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-234",
      cells: [
        {
          content: "TOTAL SHORT TERM LOANS AND ADVANCES",
          class: "bold bgyellow",
        },
        { content: "", isUserProvided: false, class: " bold" },
        { content: "", isUserProvided: false, class: " bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-235",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-236",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-237",
      cells: [
        {
          content: "Annexure T: CASH AND BANK BALANCES",
          class: "bold",
        },
        { content: "For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-238",
      cells: [
        {
          content: "[A] Cash and cash equivalents",
          class: "bold-underline",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-239",
      cells: [
        {
          content: "(a) Balances with Banks in",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-240",
      cells: [
        {
          content: "Current Account",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-241",
      cells: [
        {
          content: "Cash credit account",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-242",
      cells: [
        {
          content: "Fixed Deposits",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-243",
      cells: [
        {
          content: "(b) Cheques, drafts on hand",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-244",
      cells: [
        {
          content: "(c) Cash on hand",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-245",
      cells: [
        {
          content: "Total A",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-246",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-247",
      cells: [
        {
          content: "[B] Other Bank Balances",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-248",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-249",
      cells: [
        {
          content: "Bank Deposits",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-250",
      cells: [
        {
          content: "Earmarked Bank Deposits",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-251",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-252",
      cells: [
        {
          content: "Total [B]",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-253",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-254",
      cells: [
        {
          content: "TOTAL CASH AND BANK BALANCES",
          class: "bold bgyellow",
        },
        { content: "", isUserProvided: false, class: " bold" },
        { content: "", isUserProvided: false, class: " bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-255",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-256",
      cells: [
        {
          content: "Note: Any deposit under lien to be disclosed separately",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-257",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-258",
      cells: [
        {
          content: "Annexure U: OTHER CURRENT ASSETS",
          class: "bold",
        },
        { content: "	For current year period" },
        { content: "For Preceeding year period" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-259",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-260",
      cells: [
        {
          content: "(Unsecured, considered good, unless stated otherwise)",
          class: "bold",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-261",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-262",
      cells: [
        {
          content: "(a) Interest accrued but not due on deposits",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-263",
      cells: [
        {
          content: "(b) Interest accrued and due on deposits",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-264",
      cells: [
        {
          content: "(c) Unbilled receivables",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-265",
      cells: [
        {
          content: "(d) Others(SPECIFY)",
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-266",
      cells: [
        {
          content: "",
          isUserProvided: false,
        },
        { content: "", isUserProvided: false },
        { content: "", isUserProvided: false },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-267",
      cells: [
        {
          content: "TOTAL OTHER CURRENT ASSETS",
          class: "bold bgyellow",
        },
        { content: "", isUserProvided: false, class: " bold" },
        { content: "", isUserProvided: false, class: " bold" },
        {
          content: ".",
          colspan: 8,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    {
      id: "row-268",
      cells: [
        {
          content: ".",
          colspan: 11,
          isUserProvided: false,
          class: "no-border-r free trans-text hiddeen-btton-top-border ",
        },
      ],
    },
    // end cells
  ],
};
export default AnnexuresAssets;
